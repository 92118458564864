<template>
  <div id="app">
    <nav-bar/>
    <aside-menu
      :menu="menu"
      :class="{'has-secondary':!!menuSecondary}"
      @menu-click="menuClick"
    />
    <transition>
      <router-view ref="activeView" :key="$route.fullPath"/>
    </transition>

    <aside-right v-if="isAsideRightVisible"/>
    <footer-bar/>
    <overlay/>

    <b-modal
      :active.sync="setNewPasswordModalActive"
      has-modal-card trap-focus aria-role="dialog" aria-modal
      :can-cancel="isTimeoutPassword ? ['escape', 'x'] : false"
      custom-class="modal-background-blur"
      @cancel="resetTimeoutPassword"
    >
      <set-new-password-modal
        :title="isTimeoutPassword ? $t('timeout_password') : null"
      />
    </b-modal>

    <email-verification-notification/>

    <b-modal
      :active.sync="setTwoFactorFormModal"
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
      class="two-factor"
    >
      <two-factor-form
        v-if="authUser.hasOwnProperty('two_factor')"
        v-model="authUser.two_factor.enable" :status="authUser.two_factor.enable"
        @close="setTwoFactorFormModal = !setTwoFactorFormModal"
      />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from 'vuex'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import Overlay from '@/components/Overlay.vue'
import AsideRight from '@/components/AsideRight.vue'
import SetNewPasswordModal from '@/components/Login/SetNewPasswordModal.vue'
import EmailVerificationNotification from '@/components/EmailVerificationNotification.vue'
import menu from '@/menu'
import useRegisterSW from '@/helpers/useRegisterSW'
import TwoFactorForm from '@/components/TwoFactorForm.vue'

export default {
  name: 'App',
  components: {
    EmailVerificationNotification,
    SetNewPasswordModal,
    TwoFactorForm,
    AsideRight,
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar
  },
  mixins: [useRegisterSW],
  data() {
    return {
      menuSecondary: null,
      menuSecondaryLabel: null,
      menuSecondaryIcon: null,
      refreshing: false,
      registration: null,
      setNewPasswordModalActive: false,
      isTimeoutPassword: false,
      setTwoFactorFormModal: false,
      menu: menu,
    }
  },
  computed: {
    ...mapState([
      'isOverlayVisible',
      'isAsideRightVisible'
    ]),
    ...mapGetters([
      'authUser',
      'isLoggedIn',
    ])
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.userControls()
      }
    },
  },
  methods: {
    userControls() {
      this.checkOneSignalId()
      this.checkChangePassword()
      this.checkTimeoutPassword()
      this.checkTwoFactorForced()

      document.documentElement.classList.remove('is-hidden')
    },
    menuClick(item) {
      if (item.action && item.action === 'logout') {
        this.$store.dispatch('logout')
      }
    },
    checkChangePassword() {
      return this.setNewPasswordModalActive = this.authUser.info.change_password || this.authUser.password_timeout
    },
    checkTimeoutPassword() {
      return this.isTimeoutPassword = ! this.authUser.info.change_password && this.authUser.password_timeout
    },
    checkTwoFactorForced() {
      return this.setTwoFactorFormModal = this.authUser.two_factor.force && ! this.authUser.two_factor.enable
    },
    resetTimeoutPassword() {
      this.$http.get('panel/user/reset-timeout-password')
        .then(() => {
          this.setNewPasswordModalActive = false
          this.isTimeoutPassword = false
        })
    },
    async checkOneSignalId() {
      if (! import.meta.env.VITE_APP_ONESIGNAL_ID) {
        return
      }

      // this.$OneSignal.Debug.setLogLevel('warn')

      this.$OneSignal.Notifications.addEventListener('click', (event) => {
        console.log('Notification Clicked => ', event)
      })

      this.$OneSignal.User.PushSubscription.addEventListener('change', (event) => {
        this.pushOneSignalId(event?.current)
      })

      // await this.$OneSignal.Notifications.requestPermission()
      await this.$OneSignal.User.PushSubscription.optIn()
      // await this.$OneSignal.Slidedown.promptPush({
      //   slidedownPromptOptions: {
      //     text: {
      //       actionMessage: this.$tc('onesignal_prompt_text'),
      //       acceptButton: this.$tc('allow'),
      //       cancelButton: this.$tc('cancel'),
      //     }
      //   }
      // })

      this.pushOneSignalId(this.$OneSignal.User.PushSubscription)
    },
    pushOneSignalId(data) {
      if (data?.optedIn !== true || ! data.id) {
        return
      }

      this.$http.put('user/update/notification_token', {
        token: data.id,
      })
    },
  },
}
</script>
