<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <hero-bar class="mb-10">
        {{ $t('company_filter') }}

        <template #right>
          <b-button
            v-if="selectedCompany !== null"
            size="is-medium" type="is-info is-light" outlined
            class="mr-10"
            :class="{ 'is-loading': isRemoveLoading }"
            icon-left="domain-remove"
            @click="remove"
          >
            {{ $t('remove') }}
          </b-button>

          <b-button
            icon-left="content-save" type="is-link" size="is-medium"
            :class="{ 'is-loading': isSaveLoading }"
            @click="select"
          >
            {{ $t('save') }}
          </b-button>
        </template>
      </hero-bar>

      <card-component class="mb-10">
        <b-field :label="$tc('company')">
          <select-company
            :id="company?.id"
            :required="true"
            @getCompany="getCompany"
          />
        </b-field>
      </card-component>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeroBar from '@/components/HeroBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import SelectCompany from '@/components/Company/SelectCompany.vue'

export default {
  name: 'CompanySelect',
  components: { SelectCompany, CardComponent, HeroBar },
  data() {
    return {
      company: null,
      isSaveLoading: false,
      isRemoveLoading: false,
    }
  },
  computed: {
    ...mapState([
      'selectedCompany',
    ])
  },
  mounted() {
    this.company = this.selectedCompany
  },
  methods: {
    select() {
      if (this.company === null) {
        return
      }

      this.isSaveLoading = true

      this.$http.post('panel/user/select-company', {
        company_id: this.company.id,
      })
        .then(() => {
          this.$store.commit('selectedCompany', this.company)
          this.$store.commit('selectedOrOwnCompany', this.company)
          this.success()
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    remove() {
      this.isRemoveLoading = true
      this.$store.commit('removeSelectedCompany')
      this.$store.commit('selectedOrOwnCompany')

      this.$http.put('panel/user/select-company')
        .then(() => {
          this.company = null
          this.success()
        })
        .finally(() => {
          this.isRemoveLoading = false
        })
    },
    success() {
      this.notifySuccess()

      if (this.$route.name === 'dashboard') {
        this.$parent.$parent.$parent.$refs.activeView?.getStats()
      }

      this.$parent.$parent.$parent.$refs.activeView.smartRefresh()

      this.$parent.close()
    },
    getCompany(company) {
      return this.company = company
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-card .modal-card-body {
  background-color: #f8f8f8;
  min-height: 450px;
}
</style>
