export default {
  PANEL_LOGIN: 'panel.login',

  ALL_COMPANIES: 'full_grant',

  EXCEL_EXPORT: 'excel_export',

  DASHBOARD_STATS: 'dashboard.stats',

  COMPANY_REPORT: 'company_report',

  ARGE_PANEL_LOGIN: 'device-ops.panel.login',
  ARGE_DASHBOARD_STATS: 'device-ops.dashboard.stats',
  ARGE_DEVICE_LIST: 'device-ops.device.list',
  ARGE_DEVICE_DETAILS: 'device-ops.device.details',
  ARGE_DEVICE_MORE_DETAILS: 'device-ops.device.more.details',
  ARGE_DEVICE_CREATE: 'device-ops.device.create',
  ARGE_DEVICE_UPDATE: 'device-ops.device.update',
  ARGE_DEVICE_SET_DEFAULTS: 'device-ops.device.set-defaults',
  ARGE_DEVICE_ACTIVITY: 'device-ops.device.activity',
  ARGE_DEVICE_UPTIME: 'device-ops.device.uptime',
  ARGE_DEVICE_TAGQR: 'device-ops.device.tagqr',
  ARGE_DEVICE_CONFIG: 'device-ops.device.config',
  ARGE_DEVICE_CONFIG_UPDATE: 'device-ops.device.config.update',
  ARGE_CONFIG_MINOVA: 'device-ops.config.minova',
  ARGE_CONFIG_MINOVA_REBOOT: 'device-ops.config.minova.reboot',
  ARGE_CONFIG_TELTONIKA: 'device-ops.config.teltonika',
  ARGE_INTEGRATION: 'device-ops.integration',
  ARGE_INTEGRATION_TAG_QR_DETAILS: 'device-ops.integration.tag_qr.details',

  ORG_LIST: 'org.list',
  ORG_CREATE: 'org.create',
  ORG_EDIT: 'org.edit',
  ORG_USER_CREATE: 'org.user.create',

  USER_LIST: 'user.list',
  USER_CREATE: 'user.create',
  USER_EDIT: 'user.edit',

  USER_GROUP_LIST: 'user.group.list',
  USER_GROUP_CREATE: 'user.group.create',
  USER_GROUP_EDIT: 'user.group.edit',

  PERSONAL_USER_LIST: 'personal.user.list',
  PERSONAL_USER_DETAILS: 'personal.user.details',
  PERSONAL_USER_EDIT: 'personal.user.status_edit',
  PERSONAL_USER_LOG_LIST: 'personal.user.log.list',
  PERSONAL_USER_DOCUMENT_LIST: 'personal.user.document.list',

  POTENTIAL_USER_LIST: 'potential.user.list',

  USER_COUPON_LIST: 'user_coupon.list',
  USER_COUPON_DETAILS: 'user_coupon.details',

  INVITE_FRIEND_LIST: 'invite_friend.list',
  INVITE_FRIEND_DETAILS: 'invite_friend.details',
  INVITE_FRIEND_SETTINGS: 'invite_friend.settings',

  TICKET_LIST: 'ticket.list',

  WALLET_LIST: 'wallet.list',

  COMPANY_LIST: 'company.list',
  COMPANY_CREATE: 'company.create',
  COMPANY_EDIT: 'company.edit',
  COMPANY_SORT: 'company.sort',
  COMPANY_MODULES: 'company.modules',
  COMPANY_AGREEMENT_LIST: 'company.agreement.list',
  COMPANY_AGREEMENT_CREATE: 'company.agreement.create',
  COMPANY_AGREEMENT_EDIT: 'company.agreement.edit',
  COMPANY_SETTINGS_EDIT: 'company.settings.edit',
  COMPANY_CATEGORY_LIST: 'company.category.list',
  COMPANY_CATEGORY_CREATE: 'company.category.create',
  COMPANY_CATEGORY_EDIT: 'company.category.edit',
  COMPANY_CATEGORY_ORDER: 'company.category.order',
  COMPANY_DOCUMENT_LIST: 'company.document.list',
  COMPANY_FACILITY_FEATURE_LIST: 'company.facility_feature.list',
  COMPANY_FACILITY_FEATURE_CREATE: 'company.facility_feature.create',
  COMPANY_FACILITY_FEATURE_EDIT: 'company.facility_feature.edit',

  CORPORATION_CREATE: 'corporation.create',
  CORPORATION_UPDATE: 'corporation.update',
  CORPORATION_DELETE: 'corporation.delete',
  CORPORATION_LIST: 'corporation.list',
  CORPORATION_DETAILS: 'corporation.details',

  POS_LIST: 'pos.list',
  POS_CREATE: 'pos.create',
  POS_EDIT: 'pos.edit',
  POS_TEST: 'pos.test',

  BRANCH_LIST: 'branch.list',
  BRANCH_CREATE: 'branch.create',
  BRANCH_EDIT: 'branch.edit',

  BRANCH_AREA_BOUNDARY_LIST : 'branch.area_boundary.list',
  BRANCH_AREA_BOUNDARY_CREATE : 'branch.area_boundary.create',
  BRANCH_AREA_BOUNDARY_EDIT : 'branch.area_boundary.edit',

  AMUSEMENT_EVENT_CREATE: 'amusement_event.create',
  AMUSEMENT_EVENT_UPDATE: 'amusement_event.update',
  AMUSEMENT_EVENT_DELETE: 'amusement_event.delete',
  AMUSEMENT_EVENT_LIST: 'amusement_event.list',
  AMUSEMENT_EVENT_DETAILS: 'amusement_event.details',

  DEPOSIT_LIST: 'deposit.list',
  DEPOSIT_DETAILS: 'deposit.details',

  DEPOSIT_FAIL_LIST: 'deposit.fail.list',
  DEPOSIT_FAIL_DETAILS: 'deposit.fail.details',

  DEPOSIT_REFUND: 'deposit.refund',
  DEPOSIT_REFUND_LIST: 'deposit.refund.list',
  DEPOSIT_REFUND_DETAILS: 'deposit.refund.details',

  BOX_OFFICE: 'box_office',
  BOX_OFFICE_PAYMENT_REFUND: 'box_office.payment.refund',
  BOX_OFFICE_PREDEFINED_DEPOSIT_LIST: 'box_office_predefined_deposit.list',
  BOX_OFFICE_PREDEFINED_DEPOSIT_CREATE: 'box_office_predefined_deposit.create',
  BOX_OFFICE_PREDEFINED_DEPOSIT_UPDATE: 'box_office_predefined_deposit.update',
  BOX_OFFICE_PREDEFINED_DEPOSIT_DELETE: 'box_office_predefined_deposit.delete',

  TAP_CARD_DEPOSIT_REPORT: 'tap_card_deposit.report',

  CASH_DEPOSIT_LIST: 'cash.deposit.list',
  CASH_DEPOSIT_DETAILS: 'cash.deposit.details',

  CASH_DEPOSIT_REFUND_LIST: 'cash.deposit.refund.list',
  CASH_DEPOSIT_REFUND_DETAILS: 'cash.deposit.refund.details',
  CASH_DEPOSIT_REFUND_REQUEST: 'cash.deposit.refund.request',
  CASH_DEPOSIT_REFUND: 'cash.deposit.refund',
  CASH_BALANCE_TRANSFER: 'cash.balance.transfer',

  DEPOSIT_PANEL_REFUND: 'deposit.panel.refund',

  PAYMENT_LIST: 'payment.list',
  PAYMENT_DETAILS: 'payment.details',

  ORDER_LIST: 'order.list',
  ORDER_DETAILS: 'order.details',
  ORDER_STATUS_UPDATE: 'order.status_update',
  ORDER_CANCEL: 'order.cancel',
  ORDER_FORCE_CANCEL: 'order.force_cancel',

  PAYMENT_REFUND_REQUEST_LIST: 'payment.refund.request.list',
  PAYMENT_REFUND_REQUEST_DETAILS: 'payment.refund.request.details',
  PAYMENT_PANEL_REFUND: 'payment.refund.panel_make',

  PAYMENT_TRANSFER_LIST: 'payment.transfer.list',

  STATIC_QR_LIST: 'static_qr.list',
  STATIC_QR_CREATE: 'static_qr.create',
  STATIC_QR_EDIT: 'static_qr.edit',

  TAG_QR_LIST: 'tag_qr.list',
  TAG_QR_CREATE: 'tag_qr.create',
  TAG_QR_CREATE_MANY: 'tag_qr.create_many',
  TAG_QR_EDIT: 'tag_qr.edit',
  TAG_QR_SIMPLE_EDIT: 'tag_qr.edit.simple',
  TAG_QR_DETAILS: 'tag_qr.details',

  POINT_HISTORY_LIST: 'point.history.list',
  BALANCE_HISTORY_LIST: 'balance_history.list',

  TOKEN_LIST: 'token.list',
  TOKEN_CREATE: 'token.create',
  TOKEN_EDIT: 'token.edit',
  TOKEN_REVOKE: 'token.revoke',

  EFTPOS_LIST: 'eftpos.list',
  EFTPOS_CREATE: 'eftpos.create',
  EFTPOS_EDIT: 'eftpos.edit',

  COUPON_LIST: 'coupon.list',
  COUPON_CREATE: 'coupon.create',
  COUPON_EDIT: 'coupon.edit',
  COUPON_CREATE_MANY_COMPANIES: 'coupon.create_many_companies',
  COUPON_EXCLUDE_REPORTS: 'coupon.exclude_reports',

  CAMPAIGN_LIST: 'campaign.list',
  CAMPAIGN_CREATE: 'campaign.create',
  CAMPAIGN_EDIT: 'campaign.edit',

  MODERATOR_WALLET_LIST: 'moderator.wallet.list',
  MODERATOR_SUBSCRIBE_WALLET: 'moderator.subscribe.wallet',
  MODERATOR_WALLET_SHARE: 'moderator.wallet.share',
  MODERATOR_DOCUMENTS: 'moderator.documents',
  MODERATOR_BALANCE_HISTORY: 'moderator.balance.history',

  MODERATOR_APPLICATION_LIST: 'moderator.application.list',
  MODERATOR_APPLICATION_REVIEW: 'moderator.application.review',

  CAMPAIGN_NEWS_LIST: 'campaign.news.list',
  CAMPAIGN_NEWS_CREATE: 'campaign.news.create',
  CAMPAIGN_NEWS_EDIT: 'campaign.news.edit',
  CAMPAIGN_NEWS_SORT: 'campaign.news.sort',

  BANK_TRANSFER_LIST: 'bank_transfer.list',
  BANK_TRANSFER_APPROVE: 'bank_transfer.approve',
  BANK_TRANSFER_REJECT: 'bank_transfer.reject',
  BANK_TRANSFER_CANCEL: 'bank_transfer.cancel',
  BANK_TRANSFER_DETAILS: 'bank_transfer.details',

  QR_MENU_CREATE: 'qr.menu.create',
  QR_MENU_EDIT: 'qr.menu.edit',
  QR_MENU_LIST: 'qr.menu.list',
  QR_MENU_PANEL_LOGIN: 'qr.menu.panel.login',
  QR_MENU_CATEGORY_CREATE: 'qr_menu.category.create',
  QR_MENU_CATEGORY_UPDATE: 'qr_menu.category.update',
  QR_MENU_CATEGORY_DELETE: 'qr_menu.category.delete',
  QR_MENU_CATEGORY_DETAIL: 'qr_menu.category.detail',
  QR_MENU_CATEGORY_LIST: 'qr_menu.category.list',
  QR_MENU_PRODUCT_CREATE: 'qr_menu.product.create',
  QR_MENU_PRODUCT_UPDATE: 'qr_menu.product.update',
  QR_MENU_PRODUCT_DELETE: 'qr_menu.product.delete',
  QR_MENU_PRODUCT_DETAIL: 'qr_menu.product.detail',
  QR_MENU_PRODUCT_LIST: 'qr_menu.product.list',
  QR_MENU_PRODUCT_VARIATION_CREATE: 'qr_menu.product_variation.create',
  QR_MENU_PRODUCT_VARIATION_UPDATE: 'qr_menu.product_variation.update',
  QR_MENU_PRODUCT_VARIATION_DELETE: 'qr_menu.product_variation.delete',
  QR_MENU_PRODUCT_VARIATION_DETAIL: 'qr_menu.product_variation.detail',
  QR_MENU_PRODUCT_VARIATION_LIST: 'qr_menu.product_variation.list',
  QR_MENU_ALLERGY_CREATE: 'qr_menu.allergy.create',
  QR_MENU_ALLERGY_UPDATE: 'qr_menu.allergy.update',
  QR_MENU_ALLERGY_DELETE: 'qr_menu.allergy.delete',
  QR_MENU_ALLERGY_DETAIL: 'qr_menu.allergy.detail',
  QR_MENU_ALLERGY_LIST: 'qr_menu.allergy.list',
  QR_MENU_MENU_CREATE: 'qr_menu.menu.create',
  QR_MENU_MENU_CLONE: 'qr_menu.menu.clone',
  QR_MENU_MENU_UPDATE: 'qr_menu.menu.update',
  QR_MENU_MENU_DELETE: 'qr_menu.menu.delete',
  QR_MENU_MENU_DETAIL: 'qr_menu.menu.detail',
  QR_MENU_MENU_LIST: 'qr_menu.menu.list',
  QR_MENU_QR_CODE_CREATE: 'qr_menu.qr_code.create',
  QR_MENU_QR_CODE_UPDATE: 'qr_menu.qr_code.update',
  QR_MENU_QR_CODE_DELETE: 'qr_menu.qr_code.delete',
  QR_MENU_QR_CODE_DETAIL: 'qr_menu.qr_code.detail',
  QR_MENU_QR_CODE_LIST: 'qr_menu.qr_code.list',
  QR_MENU_QR_CODE_ALL: 'qr_menu.qr_code.all',
  QR_MENU_COMPANY_LANGUAGE_ADD: 'qr_menu.company_language.add',
  QR_MENU_COMPANY_LANGUAGE_ALL: 'qr_menu.company_language.all',
  QR_MENU_COMPANY_LANGUAGE_GET: 'qr_menu.company_language.get',
  QR_MENU_SELECT_COMPANY: 'qr_menu.select_company',
  QR_MENU_DELETE_COMPANY: 'qr_menu.delete_company',
  QR_MENU_COMPANY_SHOWCASE_ADD: 'qr_menu.company_showcase.add',
  QR_MENU_COMPANY_SHOWCASE_LIST: 'qr_menu.company_showcase.list',
  QR_MENU_COMPANY_SHOWCASE_DELETE_ALL: 'qr_menu.company_showcase.delete_all',
  QR_MENU_ACTIVITY_LOG: 'qr_menu.activity_log',

  BLOG_CATEGORY_LIST: 'blog_category.list',
  BLOG_CATEGORY_CREATE: 'blog_category.create',
  BLOG_CATEGORY_UPDATE: 'blog_category.update',
  BLOG_CATEGORY_DELETE: 'blog_category.delete',
  BLOG_CATEGORY_ORDER: 'blog_category.order',

  BLOG_POST_LIST: 'blog_post.list',
  BLOG_POST_CREATE: 'blog_post.create',
  BLOG_POST_UPDATE: 'blog_post.update',
  BLOG_POST_DELETE: 'blog_post.delete',
  BLOG_POST_ORDER: 'blog_post.order',

  SCHEDULE_REPORT_LIST: 'schedule_report.list',
  SCHEDULE_REPORT_CREATE: 'schedule_report.create',
  SCHEDULE_REPORT_UPDATE: 'schedule_report.update',
  SCHEDULE_REPORT_DETAILS: 'schedule_report.details',
  SCHEDULE_REPORT_DELETE: 'schedule_report.delete',

  SHARE_PERCENT_ENTER: 'share_percent_enter',

  COUPON_SHARE_PERCENT_ENTER: 'coupon.share_percent_enter',
  COUPON_SHARE_PERCENT_SHOW: 'coupon.share_percent_show',

  COMMISSION_LIST: 'commission.list',
  COMMISSION_CREATE: 'commission.create',
  COMMISSION_EDIT: 'commission.edit',

  ACCESS_USER_INFO: 'access_user_info',
  SHOW_USER_INFO: 'show_user_info',
  USER_PRIVACY_LOGS: 'user_privacy_logs',

  IYS_LOG: 'iys.log',
  IYS_LOG_DETAILS: 'iys.log.details',

  PUSH_NOTIFICATION_NEWS_LIST: 'push_notification_news.list',
  PUSH_NOTIFICATION_NEWS_DETAILS: 'push_notification_news.details',
  PUSH_NOTIFICATION_NEWS_CREATE: 'push_notification_news.create',
  PUSH_NOTIFICATION_NEWS_UPDATE: 'push_notification_news.update',
  PUSH_NOTIFICATION_NEWS_COUNT: 'push_notification_news.count',

  COMMISSION_REPORT: 'commission_report',
  MARKETING_REPORT: 'marketing_report',
  EFTPOS_SETTLEMENT_REPORT: 'eftpos_settlement_report',

  SMS_PROVIDER_SETTING_LIST: 'sms_provider_setting.list',
  SMS_PROVIDER_SETTING_UPDATE: 'sms_provider_setting.update',

  MULTIPLE_TAP_CARD_CREATE: 'multiple_tap_card.create',

  PERMISSION_LIST: 'permission.list',

  PERSONAL_USER_PHONE_UPDATE: 'personal.user.phone.update',
  PERSONAL_USER_PHONE_UPDATE_NO_OTP: 'personal.user.phone.update.no.otp',

  MANUAL_PAYMENT: 'manual_payment',
  MANUAL_PAYMENT_WITH_OTP: 'manual_payment_with_otp',

  PAYMENT_DOCUMENT_LIST: 'payment.document.list',
  PAYMENT_DOCUMENT_CREATE: 'payment.document.create',
  PAYMENT_DOCUMENT_DELETE: 'payment.document.delete',

  USER_CREATE_AUDIENCE: 'user.create.audience',
  HIDE_TRANSACTION: 'hide_transaction',

  // Soft Play
  SOFT_PLAY_QR_LIST: 'soft_play.qr.list',
  SOFT_PLAY_QR_CREATE: 'soft_play.qr.create',
  SOFT_PLAY_QR_UPDATE: 'soft_play.qr.update',

  SOFT_PLAY_INFO: 'soft_play.info',
  SOFT_PLAY_LISt: 'soft_play.list',
  SOFT_PLAY_HISTORY_REPORT: 'soft_play.history_report',

  // User Review
  USER_REVIEW_LIST: 'user.review.list',

  // Company News
  COMPANY_NEWS_LIST: 'company.news.list',
  COMPANY_NEWS_CREATE: 'company.news.create',
  COMPANY_NEWS_UPDATE: 'company.news.update',

  SHOW_AMOUNTS: 'show_amounts',
}
