<template>
  <aside
    v-show="isAsideVisible"
    class="aside is-placed-left"
    :class="{'is-expanded':isAsideExpanded || isSecondary, 'is-secondary':isSecondary}"
  >
    <aside-tools :has-close="isSecondary" :icon="icon" @close="close">
      <span v-if="!isSecondary">
        <template v-if="isAsideExpanded || isAsideMobileExpanded">
          <b>{{ panelTitleFirstWord }}</b> {{ panelTitleSecondWord }}
          <a v-if="appEnv" class="app-env mr-10 is-pulled-left" title="Ortam">
            <span class="tag" :data-label="`env-${appEnv}`">{{ appEnv }}</span>
          </a>
        </template>

        <template v-else>
          <b>MENU</b>
        </template>
      </span>
      <span v-else-if="label">{{ label }}</span>
    </aside-tools>

    <div ref="menuContainer" class="menu-container" @mouseenter="psUpdate">
      <div class="menu is-menu-main">
        <b-input
          ref="searchInput"
          v-model="searchValue"
          :placeholder="`${$tc('search')}...`"
          class="menu-search" size="is-small" type="input"
          autofocus
          icon="magnify"
          autocomplete="off"
          :icon-right="searchIconRight"
          icon-right-clickable
          @icon-right-click="searchValue = ''"
        />

        <template v-for="(menuGroup, index) in filteredMenu">
          <aside-menu-list
            v-if="menuGroup.menu.find( item => item.show === true)"
            :key="index"
            :is-secondary="isSecondary"
            :menu="menuGroup"
            @menu-click="menuClick"
          />
        </template>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar'
import eventBus from '@/helpers/eventBus'

export default {
  name: 'AsideMenu',
  components: {
    AsideTools: () => import(/* webpackPrefetch: true */ '@/components/AsideTools.vue'),
    AsideMenuList: () => import(/* webpackPrefetch: true */ '@/components/AsideMenuList.vue')
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      allowedMenu: [],
      searchValue: '',
    }
  },
  computed: {
    ...mapState([
      'isAsideVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'permissions',
      'panelTitle',
    ]),
    panelTitleFirstWord() {
      const title = this.panelTitle.split(' ')

      return title[0]
    },
    panelTitleSecondWord() {
      const title = this.panelTitle.split(' ')

      return title[1]
    },
    filteredMenu() {
      const searchValue = this.searchValue
      let filtMenu = this.allowedMenu

      if (searchValue.length < 1) {
        return filtMenu
      }

      return filtMenu.map((item) => {
        return {
          ...item,
          menu: item.menu.map((menu) => {
            return {
              ...menu,
              show: this.equalize(menu.label).indexOf(this.equalize(searchValue)) > -1
            }
          }),
        }
      })
    },
    searchIconRight() {
      if (this.searchValue.length > 1) {
        return 'close-circle'
      }

      return null
    }
  },
  mounted() {
    this.ps = new PerfectScrollbar(this.$refs.menuContainer)
    this.generateMenu()

    eventBus.$on('user-changed', () => {
      this.searchValue = ''
      this.generateMenu()
    })

    window.addEventListener('keydown', this.searchInputListener)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.searchInputListener)
  },
  methods: {
    menuClick(item) {
      this.$emit('menu-click', item)
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update()
      }
    },
    close() {
      this.$emit('close')
    },
    generateMenu() {
      if (this.permissions.length < 1) {
        this.allowedMenu = []
        return
      }

      const orijinalMenu = this.menu.map((item) => {
        return {
          ...item,
          menu: item.menu.map((menu) => {
            return {
              ...menu,
              show: true,
            }
          })
        }
      })

      this.allowedMenu = orijinalMenu.filter((item) => {
        item.menu = item.menu.filter((menu) => {
          if (menu?.to?.meta?.permission) {
            if (menu.to.meta.permission instanceof Array) {
              return menu.to.meta.permission.every(perm => this.permissions.includes(perm))
            }
            return this.permissions.includes(menu.to.meta.permission)
          } else {
            return true
          }
        })

        return item.menu.length > 0
      })
    },
    searchInputListener(e) {
      const isInInput = e.target.parentNode.classList.contains('menu-search')

      if (isInInput && e.key === 'Escape') {
        this.searchValue = ''
      }

      if (isInInput && e.key === 'Enter') {
        e.preventDefault()
        this.$el.querySelector('.menu-list li a')?.click()
      }

      if (e.target.tagName.toUpperCase() === 'BODY' && e.key === 's') {
        e.preventDefault()
        this.$refs.searchInput.focus()
      }
    }
  }
}
</script>
