<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <hero-bar class="mb-10">
        {{ $tc('audience_role_set_amount') }}

        <template #right>
          <b-button
            icon-left="content-save" type="is-link" size="is-medium"
            @click="submitForm"
          >
            {{ $tc('save') }}
          </b-button>
        </template>
      </hero-bar>

      <form ref="form" @submit.prevent="submitForm">
        <card-component class="mb-10">
          <b-field v-if="! selectedOrOwnCompany?.id" :label="$tc('company')">
            <select-company
              :required="true"
              :disabled="companyId"
              @getCompany="getCompany"
            />
          </b-field>

          <b-field :label="$tc('branch')">
            <select-branch :company-id="companyId" required :disabled="false" @getBranch="getBranch"/>
          </b-field>

          <b-field :label="$tc('start_date')">
            <b-datepicker
              v-model="form.start_date"
              locale="tr-TR"
              position="is-bottom-left"
              icon="calendar-today" :placeholder="$tc('click_to_select')" required
            />
          </b-field>

          <b-field :label="$tc('end_date')">
            <b-datepicker
              v-model="form.end_date"
              :min-date="form.start_date"
              :max-date="yesterday"
              :disabled="! form.start_date"
              locale="tr-TR"
              icon="calendar-today" :placeholder="$tc('click_to_select')" required
              position="is-bottom-left"
              @input="getMaxHideAmount"
            />
          </b-field>

          <b-field :label="$tc('deposit_amount')" :message="depositAmountMessage" :type="{'is-danger': isDepositAmountHigherThanMaxAmount}">
            <b-input
              v-model="shadowDepositAmount" v-cleave="masks.money" icon="cash" :placeholder="`${$t('amount')}...`"
              :disabled="! maxDepositHideAmount" required
            />
          </b-field>

          <b-field :label="$tc('payment_amount')" :message="paymentAmountMessage" :type="{'is-danger': isPaymentAmountHigherThanMaxAmount}">
            <b-input
              v-model="shadowPaymentAmount" v-cleave="masks.money" icon="cash" :placeholder="`${$t('amount')}...`"
              :disabled="! maxPaymentHideAmount" required
            />
          </b-field>

          <b-field :label="$tc('cash_deposit_amount')" :message="cashDepositAmountMessage" :type="{'is-danger': isCashDepositAmountHigherThanMaxAmount}">
            <b-input
              v-model="shadowCashDepositAmount" v-cleave="masks.money" icon="cash" :placeholder="`${$t('amount')}...`"
              :disabled="! maxCashDepositHideAmount" required
            />
          </b-field>
        </card-component>
      </form>
    </section>
  </div>
</template>

<script>

import CardComponent from '@/components/CardComponent.vue'
import HeroBar from '@/components/HeroBar.vue'
import SelectBranch from '@/components/Branch/SelectBranch.vue'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import SelectCompany from '@/components/Company/SelectCompany.vue'

export default {
  name: 'HideTransactionForm',
  components: {
    SelectCompany,
    SelectBranch,
    HeroBar,
    CardComponent,
  },
  data() {
    return {
      maxDepositHideAmount: null,
      maxPaymentHideAmount: null,
      maxCashDepositHideAmount: null,
      depositAmountMessage: null,
      paymentAmountMessage: null,
      cashDepositAmountMessage: null,
      isAmountHigherThanMaxAmount: false,
      isDepositAmountHigherThanMaxAmount: false,
      isPaymentAmountHigherThanMaxAmount: false,
      isCashDepositAmountHigherThanMaxAmount: false,
      shadowDepositAmount: null,
      shadowPaymentAmount: null,
      shadowCashDepositAmount: null,
      yesterday: dayjs().subtract(1, 'day').toDate(),
      shadowCompanyId: null,
      form: {
        transaction_type: this.transactionType,
        start_date: null,
        end_date: null,
        deposit_amount: null,
        payment_amount: null,
        cash_deposit_amount:null,
        branch_id: null,
      }
    }
  },
  computed: {
    'companyId'() {
      return this.shadowCompanyId ? this.shadowCompanyId : this.selectedOrOwnCompany?.id
    },
    ...mapGetters([
      'selectedOrOwnCompany',
    ])
  },
  watch: {
    'shadowDepositAmount'(v) {
      if (this.trMoneyToInt(v) > this.trMoneyToInt(this.maxDepositHideAmount)) {
        this.depositAmountMessage = this.$t(
          'validation.entered_price_must_be_lower_than_wallet_price',
          [this.maxDepositHideAmount]
        )

        this.isDepositAmountHigherThanMaxAmount = true

        return
      }
      this.depositAmountMessage = this.$t('show_max_hide_amount', [this.maxDepositHideAmount])
      this.isDepositAmountHigherThanMaxAmount = false
    },
    'shadowPaymentAmount'(v) {
      if (this.trMoneyToInt(v) > this.trMoneyToInt(this.maxPaymentHideAmount)) {
        this.paymentAmountMessage = this.$t(
          'validation.entered_price_must_be_lower_than_wallet_price',
          [this.maxPaymentHideAmount]
        )

        this.isPaymentAmountHigherThanMaxAmount = true

        return
      }
      this.paymentAmountMessage = this.$t('show_max_hide_amount', [this.maxPaymentHideAmount])
      this.isPaymentAmountHigherThanMaxAmount = false
    },
    'shadowCashDepositAmount'(v) {
      if (this.trMoneyToInt(v) > this.trMoneyToInt(this.maxCashDepositHideAmount)) {
        this.cashDepositAmountMessage = this.$t(
          'validation.entered_price_must_be_lower_than_wallet_price',
          [this.maxPaymentHideAmount]
        )

        this.isCashDepositAmountHigherThanMaxAmount = true

        return
      }
      this.cashDepositAmountMessage = this.$t('show_max_hide_amount', [this.maxCashDepositHideAmount])
      this.isCashDepositAmountHigherThanMaxAmount = false
    }
  },
  methods: {
    beforeSendRequest() {
      this.form.deposit_amount = this.trMoneyToInt(this.shadowDepositAmount)
      this.form.payment_amount = this.trMoneyToInt(this.shadowPaymentAmount)
      this.form.cash_deposit_amount = this.trMoneyToInt(this.shadowCashDepositAmount)
      this.form.start_date = dayjs(this.form.start_date).format()
      this.form.end_date = dayjs(this.form.end_date).format()
    },
    getMaxHideAmount() {
      this.beforeSendRequest()

      this.$http.post('panel/hide/transaction/max-amount', this.form)
        .then(({ data: { data } }) => {
          const maxDepositAmount = data.deposit
          const maxPaymentAmount = data.payment
          const maxCashDepositAmount = data.cash_deposit

          if (
            this.trMoneyToInt(maxDepositAmount) === 0 &&
            this.trMoneyToInt(maxPaymentAmount) === 0 &&
            this.trMoneyToInt(maxCashDepositAmount) === 0
          ) {
            this.notifyWarning(this.$tc('hide_transaction_not_found'))
            this.form.start_date = null
            this.form.end_date = null
            return
          }

          this.maxDepositHideAmount = maxDepositAmount
          this.depositAmountMessage = this.$t('show_max_hide_amount', [maxDepositAmount])

          this.maxPaymentHideAmount = maxPaymentAmount
          this.paymentAmountMessage = this.$t('show_max_hide_amount', [maxPaymentAmount])

          this.maxCashDepositHideAmount = maxCashDepositAmount
          this.cashDepositAmountMessage = this.$t('show_max_hide_amount', [maxCashDepositAmount])
        })
    },
    submit() {
      this.beforeSendRequest()

      this.$http.post('panel/hide/transaction/get-hide-amount', this.form)
        .then(({ data: { data } }) => {
          this.$buefy.dialog.confirm({
            title: this.$t('audience_role_set_amount'),
            message: this.$t('hide_transaction_confirm_message', [data.deposit, data.payment, data.cash_deposit]),
            type: 'is-warning',
            hasIcon: true,
            confirmText: this.$t('approve'),
            onConfirm: this.confirmHide
          })
        })
    },
    confirmHide() {
      this.beforeSendRequest()

      this.$http.post('panel/hide/transaction/complete', this.form)
        .then(() => {
          this.notifySuccess()
          this.$parent.close()
        })
    },
    getBranch(branch) {
      this.form.branch_id = branch.id
    },
    getCompany(company) {
      this.shadowCompanyId = company.id
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-card {
  width: 750px;

  .modal-card-body {
    background-color: #f8f8f8;
  }
}
</style>
