import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { each } from 'lodash'
import router from '@/router'
import createLogger from 'vuex/dist/logger'
import { ToastProgrammatic as Toast } from 'buefy'
import Perm from '@/helpers/perm'
import eventBus from '@/helpers/eventBus'
import * as Socket from '@/helpers/socket'
import JsCookie from 'js-cookie'
import dayjs from 'dayjs'
import { i18n } from '@/helpers/i18n'
import * as Sentry from '@sentry/vue'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: import.meta.env.MODE === 'production' ? [] : [createLogger()],
  state: {
    panelTitle: 'Macellan SuperApp',
    pageTitle: null,
    /* User */
    status: null,
    access_token: JsCookie.get('access_token') || '',
    device_id: JsCookie.get('device_id') || '',
    user: {},
    userFirstName: '',
    userLastName: '',
    permissions: [],

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    isModeratorPanel: false,

    /* Aside */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,
    asideActiveForcedKey: null,
    asideActiveMenuKey: '',
    isAsideRightVisible: false,

    /* Updates */
    hasUpdates: false,
    notificationCount: 0,

    /* Overlay */
    isOverlayVisible: false,

    langs: [],
    regions: [],
    currencies: [],
    organizations: [],
    company_modules: [],
    accountancies: [],
    orderTransitionsList: [],
    app_web_url: null,
    selectedCompany: null,
    selectedOrOwnCompany: null,
    smartExcelLoading: false,
    isExcelWaiting: false,
    socketEventRegistered: false,

    tapCardDepositAmount: 0,

    excelExports: [],

    verifyEmailModal: true,
    loginReturnUrl: null,

    socket: {
      host: null,
      port: null,
      key: null,
    },
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user(state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    },

    /* Full Page mode */
    fullPage(state, payload) {
      state.isNavBarVisible = ! payload
      state.isAsideVisible = ! payload
      state.isFooterBarVisible = ! payload

      each(['has-aside-left', 'has-navbar-fixed-top'], htmlClass => {
        if (payload) {
          document.documentElement.classList.remove(htmlClass)
        } else {
          document.documentElement.classList.add(htmlClass)
        }
      })
    },

    /* Aside Desktop */
    asideStateToggle(state, payload = null) {
      const htmlAsideClassName = 'has-aside-expanded'

      let isExpand

      if (payload !== null) {
        isExpand = payload
      } else {
        isExpand = ! state.isAsideExpanded
      }

      if (isExpand) {
        document.documentElement.classList.add(htmlAsideClassName)
      } else {
        document.documentElement.classList.remove(htmlAsideClassName)
      }

      state.isAsideExpanded = isExpand
    },

    /* Detect moderator panel */
    moderatorPanel(state, payload = null) {
      state.isModeratorPanel = payload
    },

    /* Aside Mobile */
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = ! state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      setTimeout(() => {
        state.isAsideMobileExpanded = isShow
      }, 50)
    },

    /* Aside Forced Active Key (when secondary submenu is open) */
    asideActiveForcedKeyToggle(state, payload) {
      state.asideActiveForcedKey = payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null
    },

    setAsideActiveMenuKey(state, payload) {
      state.asideActiveMenuKey = payload
    },

    /* Aside Right */
    asideRightToggle(state, payload = null) {
      const htmlClassName = 'has-aside-right'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = ! state.isAsideRightVisible
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideRightVisible = isShow
      state.hasUpdates = false
    },
    /* Overlay */
    overlayToggle(state, payload = null) {
      if (payload === null) {
        payload = ! state.isOverlayVisible
      }

      state.isOverlayVisible = !! payload
    },
    authRequest(state) {
      state.status = 'loading'
    },
    authError(state) {
      state.status = 'error'
    },
    tokenSuccess(state, { access_token, expires_in, refresh_token }) {
      state.status = 'success'
      state.access_token = access_token

      const expiresDate = dayjs().add(expires_in, 's').toDate()
      const currentDomain = window.location.hostname

      const cookieProperties = {
        expires: expiresDate,
        sameSite: 'Strict',
        secure: currentDomain !== 'localhost',
        domain: currentDomain.split('.').slice(-2).join('.'),
      }

      JsCookie.set('access_token', access_token, cookieProperties)

      const expiresDateTimeStamp = dayjs().add(expires_in, 's').unix()
      cookieProperties.expires = dayjs().add(expires_in, 's').add(1, 'day').toDate()

      JsCookie.set('token_expire_at', expiresDateTimeStamp, cookieProperties)
      JsCookie.set('refresh_token', refresh_token, cookieProperties)
    },
    setDeviceId(state) {
      let device_id = JsCookie.get('device_id')
      if (device_id) {
        state.device_id = device_id
        return
      }

      device_id = crypto.randomUUID()

      const cookieProperties = {
        expires: dayjs('9999-12-31T23:59:59').toDate(),
        sameSite: 'Strict',
        secure: window.location.hostname !== 'localhost',
      }

      JsCookie.set('device_id', device_id, cookieProperties)
      state.device_id = device_id
    },
    setUser(state, user) {
      state.user = user
      state.permissions = user.permissions
      state.userFirstName = user.first_name
      state.userLastName = user.last_name
      state.notificationCount = user.unread_notification_count

      eventBus.$emit('user-changed')

      Sentry.setUser({
        id: user.id,
        email: user.email,
      })

      this.dispatch('setSelectedCompanyAndOwnCompany')
      this.dispatch('getRequirements')
    },
    getUser() {
      this.dispatch('getUserInfo')
    },
    logout(state, payload) {
      state.user = {}
      state.userFirstName = ''
      state.userLastName = ''
      state.status = null
      state.access_token = ''
      state.permissions = []
      state.organizations = []
      state.accountancies = []
      state.socketEventRegistered = false
      state.isExcelWaiting = false
      state.verifyEmailModal = true

      Socket.disconnectSocket()

      const currentDomain = window.location.hostname

      const cookieProperties = {
        secure: currentDomain !== 'localhost',
        domain: currentDomain.split('.').slice(-2).join('.'),
      }

      JsCookie.remove('access_token', cookieProperties)
      JsCookie.remove('token_expire_at', cookieProperties)
      JsCookie.remove('refresh_token', cookieProperties)

      Sentry.setUser(null)

      if (payload?.toLogin) {
        if (payload.withMessage !== false) {
          Toast.open({
            message: i18n.t('session_terminated'),
            type: 'is-warning',
          })
        }

        if (router.currentRoute?.name !== 'login') {
          router.push({ name: 'login' })
        }
      }
    },
    setOrgs(state, payload = null) {
      state.organizations = payload
    },
    setAccountancies(state, payload = null) {
      state.accountancies = payload
    },
    setOrderTransitionsList(state, payload = null) {
      state.orderTransitionsList = payload
    },
    setLangs(state, payload = null) {
      state.langs = payload
    },
    setRegions(state, payload = null) {
      state.regions = payload
    },
    setCurrencies(state, payload = null) {
      state.currencies = payload
    },
    setAppWebUrl(state, payload = null) {
      state.app_web_url = payload
    },
    setSocketEnv(state, payload = null) {
      state.socket.host = payload.host
      state.socket.key = payload.key
      state.socket.port = payload.port
      state.socket.auth_endpoint = payload.auth_endpoint
    },
    setCompanyModules(state, payload = null) {
      state.company_modules = payload
    },
    setSmartExcelLoading(state, payload = null) {
      state.smartExcelLoading = payload
    },
    setExcelWaiting(state, payload = null) {
      state.isExcelWaiting = payload
    },
    setSocketEventRegistered(state, payload = null) {
      state.socketEventRegistered = payload
    },
    setNotificationCount(state, payload = null) {
      state.notificationCount = payload
    },
    getRequirements(state) {
      this.dispatch('getInfo')
        .then(() => {
          Socket.socketConnect()
        })

      if (state.permissions.includes(Perm.MODERATOR_WALLET_LIST)) {
        return
      }

      this.dispatch('getAccountancies')

      if (state.permissions.includes(Perm.ORG_LIST)) {
        this.dispatch('getOrgs')
      }

      if (state.permissions.includes(Perm.COMPANY_MODULES)) {
        this.dispatch('getCompanyModules')
      }

      if (state.permissions.includes(Perm.ORDER_STATUS_UPDATE)) {
        this.dispatch('getOrderTransitionsList')
      }
    },
    removeSelectedCompany(state) {
      state.selectedCompany = null
    },
    selectedCompany(state, payload) {
      state.selectedCompany = payload
    },
    selectedOrOwnCompany(state, payload) {
      state.selectedOrOwnCompany = payload
    },
    pageTitle(state, payload = null) {
      state.pageTitle = payload
    },
    addExcelExport(state, payload) {
      Toast.open({
        queue: false,
        hasIcon: true,
        message: `${payload.title} -  ${i18n.t('excel_export_started')}`,
        type: 'is-success',
      })

      state.excelExports.unshift({
        request_id: payload.requestId,
        percent: 0,
        title: payload.title,
        link: null,
        file_name: null,
        complete: false,
        error: false,
      })

      if (state.isAsideRightVisible === false) {
        this.commit('asideRightToggle')
      }
    },
    updateExcelExportPercent(state, payload) {
      const foundExcelExport = state.excelExports.find(
        excelExport => excelExport.request_id === payload.request_id
      )

      if (foundExcelExport) {
        foundExcelExport.percent = payload.percent
      }
    },
    excelExportResult(state, payload) {
      const foundExcelExport = state.excelExports.find(
        excelExport => excelExport.request_id === payload.request_id
      )

      if (! foundExcelExport) {
        return
      }

      if (payload.status === false) {
        foundExcelExport.percent = 100
        foundExcelExport.error = true

        Toast.open({
          duration: 3000,
          message: `${foundExcelExport.title} - ${i18n.t('excel_export_failed')}`,
          type: 'is-warning',
          queue: false,
          hasIcon: true,
        })

        return
      }

      foundExcelExport.percent = 100
      foundExcelExport.complete = true
      foundExcelExport.link = payload.file_url
      foundExcelExport.file_name = payload.file_name

      Toast.open({
        duration: 3000,
        message: `${foundExcelExport.title} - ${i18n.t('excel_export_ready')}`,
        type: 'is-success',
        queue: false,
        hasIcon: true,
      })

      return foundExcelExport
    },
    setVerifyEmailModal(state, payload) {
      state.verifyEmailModal = payload
    },
    setLoginReturnUrl(state, payload) {
      state.loginReturnUrl = payload
    },
    setTapCardDepositAmount(state, payload) {
      state.tapCardDepositAmount = payload
    }
  },
  actions: {
    login({ commit, state }, formData) {
      return new Promise((resolve, reject) => {
        commit('authRequest')

        const loginData = {
          'email': formData.email,
          'password': formData.password,
        }

        const loginUrl = state.isModeratorPanel ? 'moderator-login' : 'login'

        const uninterceptedAxiosInstance = axios.create()

        uninterceptedAxiosInstance.post(`panel/oauth/${loginUrl}`, loginData, {
          headers: {
            'x-device-id': state.device_id
          }
        })
          .then((result) => {
            if (result.data.data.two_factor === true) {
              resolve(result)

              return
            }

            const { data: { access_token, expires_in, refresh_token } } = result.data
            commit('tokenSuccess', { access_token, expires_in, refresh_token })

            resolve(result)
          })
          .catch((error) => {
            commit('authError')
            reject(error)
          })
      })
    },
    loginTwoFactor({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('authRequest')

        axios
          .create()
          .post('panel/oauth/login-two-factor', {
            'token': payload.token,
            'code': payload.code
          }, {
            headers: {
              'x-device-id': state.device_id
            }
          })
          .then((result) => {
            const { data: { access_token, expires_in, refresh_token } } = result.data
            commit('tokenSuccess', { access_token, expires_in, refresh_token })

            resolve(result)
          })
          .catch((error) => {
            commit('authError')
            reject(error)
          })
      })
    },
    async refreshToken({ commit }) {
      return new Promise((resolve, reject) => {

        if (! JsCookie.get('refresh_token')) {
          reject()
        }

        const refreshTokenData = {
          'client_id': import.meta.env.VITE_APP_CLIENT_ID,
          'refresh_token': JsCookie.get('refresh_token'),
        }

        const uninterceptedAxiosInstance = axios.create()

        uninterceptedAxiosInstance.post('oauth/refresh_token', refreshTokenData)
          .then((result) => {
            const { access_token, expires_in, refresh_token } = result.data
            commit('tokenSuccess', { access_token, expires_in, refresh_token })

            resolve(access_token)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async getUserInfo({ commit, state }) {
      if (! JsCookie.get('refresh_token')) {
        throw 'Refresh token not set'
      }

      commit('setDeviceId')

      try {
        const { data: { data } } = await axios.get('panel/user/me', {
          headers: {
            'x-device-id': state.device_id
          }
        })
        commit('setUser', data)
      } catch (e) {
        if (e.message && e.message.indexOf('401') > 0) {
          this.dispatch('revokeToken')
        }

        Sentry.setUser(null)

        throw 'User did not come'
      }
    },
    revokeToken({ commit }) {
      const accessToken = JsCookie.get('access_token')

      if (! accessToken) {
        return
      }

      axios.create().get('user/logout', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })

      commit('logout', { toLogin: true })
    },
    getOrgs({ commit }) {
      axios.get('panel/organization/list_authorize')
        .then(({ data: { data } }) => {
          return commit('setOrgs', data)
        })
    },
    getAccountancies({ commit }) {
      axios.get('panel/company/accountancy-list')
        .then(({ data: { data } }) => {
          commit('setAccountancies', data)
        })
    },
    getOrderTransitionsList({ commit }) {
      axios.get('panel/order/status/transitions-list')
        .then(({ data: { data } }) => {
          commit('setOrderTransitionsList', data)
        })
    },
    async getInfo({ commit }) {
      const { data: { data } } = await axios.get('panel/app-info')

      commit('setLangs', data.langs)
      commit('setRegions', data.regions)
      commit('setCurrencies', data.currencies)
      commit('setAppWebUrl', data.app_web_url)
      commit('setSocketEnv', {
        host: data.pusher.host,
        port: data.pusher.port,
        key: data.pusher.key,
        auth_endpoint: data.pusher.auth_endpoint
      })
    },
    getCompanyModules({ commit }) {
      axios.get('panel/company/modules-list')
        .then(({ data: { data } }) => {
          return commit('setCompanyModules', data)
        })
    },
    getRequirements({ commit }) {
      commit('getRequirements')
    },
    async setSelectedCompanyAndOwnCompany({ commit, state }) {
      let companyId = null

      if (state.user?.selected_company) {
        companyId = state.user.selected_company
      } else if (state.user?.company_ids.length === 1) {
        companyId = state.user.company_ids[0]
      }

      if (companyId) {
        const { data: { data } } = await axios.get(`panel/company/list_basic?filter[id]=${companyId}`)

        if (data[0]) {
          if (state.user?.selected_company) {
            commit('selectedCompany', data[0])
          }

          if (state.user?.company_ids.length === 1) {
            commit('selectedOrOwnCompany', data[0])
          }

          return
        }
      }

      commit('selectedCompany', null)
      commit('selectedOrOwnCompany', null)
    },
    getTapCardDepositAmount({ commit }) {
      axios.get('panel/box-office/tap-card-deposit-amount-setting')
        .then(({ data: { tap_card_deposit_amount } }) => {
          commit('setTapCardDepositAmount', tap_card_deposit_amount)
        })
    },
  },
  getters: {
    isLoggedIn: state => !! state.user?.id,
    accessToken: state => state.access_token,
    authUser: state => state.user,
    authPermissions: state => state.permissions,
    panelTitle: state => state.panelTitle,
    userFullName: state => `${state.userFirstName} ${state.userLastName}`,
    isApprovedModerator: state => state.user.moderator?.approved,
    isModerator: state => state.user.moderator?.completed,
    isSocketEventListening: state => state.socketEventRegistered,
    selectedCompany: state => state.selectedCompany,
    selectedOrOwnCompany: state => state.selectedOrOwnCompany,
    smartExcelLoading: state => state.smartExcelLoading,
    isExcelWaiting: state => state.isExcelWaiting,
    pageTitle: state => state.pageTitle,
    verifyEmailModal: state => state.verifyEmailModal,
    loginReturnUrl: state => state.loginReturnUrl,
    socketInfo: state => state.socket,
  }
})
