import axios from 'axios'
import { handleRequest, handleResponse, handleErrorResponse } from '@/helpers/http_utils.js'
import { socketConnect } from '@/helpers/socket.js'

axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL

axios.interceptors.request.use(async (config) => {
  const modifiedConfig = await handleRequest(config)
  return modifiedConfig
})

axios.interceptors.response.use(
  (response) => {
    return handleResponse(response)
  },
  (error) => {
    socketConnect()
    return handleErrorResponse(error)
  }
)

