/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import Axios from 'axios'
import OneSignalVue from 'onesignal-vue'

/* Router & Store */
import router from '@/router'
import store from '@/store'
import { checkPermission, initApp } from '@/helpers/functions'
import Perm from '@/helpers/perm'

/* Vue. Main component */
import App from '@/App.vue'
import { i18n } from '@/helpers/i18n'

import '@/helpers/interceptors'
import '@/helpers/device_ops_interceptors'
import '@/helpers/mixins'
import '@/helpers/filters'
import JsCookie from 'js-cookie'
import * as Sentry from '@sentry/vue'
import { deviceOpsHttp } from '@/helpers/device_ops_interceptors'

/* Default title tag */
let defaultDocumentTitle = ''

if (import.meta.env.VITE_APP_ENV && import.meta.env.VITE_APP_ENV !== 'production') {
  defaultDocumentTitle += `${import.meta.env.VITE_APP_ENV.toUpperCase()} - `
}

defaultDocumentTitle = store.getters.panelTitle

initApp()

Vue.config.productionTip = false
Vue.prototype.$http = Axios
Vue.prototype.$deviceOpsHttp = deviceOpsHttp
Vue.prototype.$perm = Perm

Vue.use(Buefy, {
  defaultDialogCancelText: i18n.tc('cancel'),
  defaultDialogConfirmText: i18n.tc('okay'),
})

Vue.use(OneSignalVue)

router.beforeEach(async (to, from, next) => {
  if (to.meta) {
    if (to.meta.redirect) {
      document.location.href = to.meta.redirect

      return
    }

    if (! to.meta.guest && to.name !== 'login' && ! JsCookie.get('refresh_token') && to.name !== 'soft_play_dashboard') {
      store.commit('setLoginReturnUrl', to.fullPath)

      store.commit('logout', { toLogin: false })

      next('/login')
      return
    }

    if (to.meta.guest || to.name === 'soft_play_dashboard') {
      next()
      return
    } else if (to.meta.permission) {
      await checkPermission(to.meta.permission, next)
    } else {
      if (store.getters.isLoggedIn) {
        next()
      } else {
        next('/login')
      }
    }
  }

  next()
})

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)
  store.commit('overlayToggle', false)
  store.commit('asideActiveForcedKeyToggle', null)

  if (to.meta && to.meta.title) {
    const title = i18n.t(`titles.${to.meta.title}`)
    store.commit('pageTitle', title)
    document.title = `${title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

const vueApp = new Vue({
  i18n,
  router,
  store,
  created() {
    this.$store.commit('asideStateToggle', true)
    
    let location = document.location
    this.$store.commit('moderatorPanel', location.hostname.startsWith('moderator') || location.search.indexOf('moderator') > 0)
  },
  beforeMount() {
    if (import.meta.env.VITE_APP_ONESIGNAL_ID && import.meta.env.VITE_APP_ENV !== 'local') {
      this.$OneSignal.init({
        appId: import.meta.env.VITE_APP_ONESIGNAL_ID,
        allowLocalhostAsSecureOrigin: import.meta.env.VITE_APP_ENV !== 'production',
        notifyButton: {
          enable: false,
        },
        serviceWorkerParam: { scope: '/push/' },
        serviceWorkerPath: 'push/OneSignalSDKWorker.js',
        promptOptions: {
          slidedown: {
            prompts: [{
              type: 'push',
              autoPrompt: true,
              text: {
                actionMessage: this.$tc('onesignal_prompt_text'),
                acceptButton: this.$tc('allow'),
                cancelButton: this.$tc('cancel'),
              },
              delay: {
                pageViews: 0,
                timeDelay: 3,
              },
            }]
          },
        }
      })
    }
  },
  mounted() {
    const html = document.documentElement
    html.setAttribute('lang', this.$i18n.locale)
    document.documentElement.classList.remove('has-spinner-active')
  },
  render: h => h(App)
})

if (import.meta.env.VITE_APP_SENTRY_DNS) {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_APP_SENTRY_DNS,
    environment: import.meta.env.VITE_APP_ENV,
    ignoreErrors: [
      'is not a valid JavaScript MIME type',
      'User did not come',
      'Navigation cancelled from',
      'Avoided redundant navigation to current',
      'Request failed with status code 401',
      'Right side of assignment cannot be destructured',
      'Load failed',
      'Importing a module script failed.',
    ],
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

vueApp.$mount('#app')

Vue.config.devtools = import.meta.env?.VITE_DEV_TOOLS === '1'
