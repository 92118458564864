import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import Perm from '@/helpers/perm'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      guest: true,
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'dashboard',
      permission: Perm.DASHBOARD_STATS,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      title: 'reports',
      permission: [Perm.SHOW_AMOUNTS, Perm.COMPANY_REPORT],
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue'),
  },
  {
    path: '/box-office-report',
    name: 'box_office_report',
    meta: {
      title: 'box_office_report',
      permission: [Perm.SHOW_AMOUNTS, Perm.COMPANY_REPORT],
    },
    component: () => import(/* webpackChunkName: "box_office_report" */ '@/views/BoxOfficeReport.vue'),
  },
  {
    path: '/virtual-pos',
    name: 'virtual_pos',
    component: () => import(/* webpackChunkName: "virual_pos" */ '@/views/VirtualPos.vue'),
    meta: {
      title: 'virtual_pos',
      permission: Perm.POS_LIST,
    },
  },
  {
    path: '/branches',
    name: 'branches',
    component: () => import(/* webpackChunkName: "branches" */ '@/views/Branches.vue'),
    meta: {
      title: 'branches',
      permission: Perm.BRANCH_LIST,
    },
  },
  {
    path: '/branches/area-boundary',
    name: 'branches.area_boundary',
    component: () => import(/* webpackChunkName: "branches_area_boundary" */ '@/views/BranchesAreaBoundary.vue'),
    meta: {
      title: 'branches_area_boundary',
      permission: Perm.BRANCH_AREA_BOUNDARY_LIST,
    },
  },
  {
    path: '/agreements',
    name: 'agreements',
    component: () => import(/* webpackChunkName: "agreements" */ '@/views/Agreements.vue'),
    meta: {
      title: 'agreements',
      permission: Perm.COMPANY_AGREEMENT_LIST,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    meta: {
      title: 'users',
      permission: Perm.USER_LIST,
    },
  },
  {
    path: '/user-groups',
    name: 'user_groups',
    component: () => import(/* webpackChunkName: "user_groups" */ '@/views/UserGroups.vue'),
    meta: {
      title: 'personal_user_groups',
      permission: Perm.USER_GROUP_LIST,
    },
  },
  {
    path: '/permission-list',
    name: 'permission_list',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/PermissionList.vue'),
    meta: {
      title: 'permission_matrix',
      permission: Perm.PERMISSION_LIST,
    },
  },
  {
    path: '/personal-users',
    name: 'personal_users',
    component: () => import(/* webpackChunkName: "personal_users" */ '@/views/PersonalUsers.vue'),
    meta: {
      title: 'personal_users',
      permission: Perm.PERSONAL_USER_LIST,
    },
  },
  {
    path: '/personal-users/:userId/details',
    name: 'personal_user_details',
    component: () => import(/* webpackChunkName: "personal_user_details" */ '@/views/PersonalUserDetails.vue'),
    meta: {
      title: 'personal_user_details',
      permission: Perm.PERSONAL_USER_DETAILS,
    },
  },
  {
    path: '/personal-users/reviews',
    name: 'personal_users.reviews',
    component: () => import(/* webpackChunkName: "personal_user_reviews" */ '@/views/UserReviews.vue'),
    meta: {
      title: 'user_reviews',
      permission: Perm.USER_REVIEW_LIST,
    },
  },
  {
    path: '/potential-users',
    name: 'potential_users',
    component: () => import(/* webpackChunkName: "potential_users" */ '@/views/PotentialUsers.vue'),
    meta: {
      title: 'potential_users',
      permission: Perm.POTENTIAL_USER_LIST,
    },
  },
  {
    path: '/invite-friend',
    name: 'invite_friend',
    component: () => import(/* webpackChunkName: "invite_friend" */ '@/views/InviteFriend.vue'),
    meta: {
      title: 'invite_friend',
      permission: Perm.INVITE_FRIEND_LIST,
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '@/views/Tickets.vue'),
    meta: {
      title: 'tickets',
      permission: Perm.TICKET_LIST,
    },
  },
  {
    path: '/personal-users/iys-logs',
    name: 'personal_user_iys_logs',
    component: () => import(/* webpackChunkName: "personal_user_iys_logs" */ '@/views/IysLogs.vue'),
    meta: {
      title: 'iys_logs',
      permission: Perm.REQUEST_LOG,
    },
  },
  {
    path: '/moderator-applications',
    name: 'moderator_applications',
    component: () => import(/* webpackChunkName: "moderator_applications" */ '@/views/ModeratorApplications.vue'),
    meta: {
      title: 'moderator_wallet_applications',
      permission: Perm.MODERATOR_APPLICATION_LIST
    },
  },
  {
    path: '/deposits',
    name: 'deposits',
    component: () => import(/* webpackChunkName: "deposits" */ '@/views/Deposits.vue'),
    meta: {
      title: 'deposits',
      permission: Perm.DEPOSIT_LIST,
    },
    children: [
      {
        path: ':depositId',
        name: 'depositDetails',
        component: () => import(/* webpackChunkName: "deposit_details" */ '@/components/Deposit/DepositDetails.vue'),
        props: true,
        meta: {
          permission: Perm.DEPOSIT_DETAILS,
        }
      }
    ]
  },
  {
    path: '/card-deposit-dashboard',
    name: 'card_deposits_dashboard',
    component: () => import(/* webpackChunkName: "card_deposits_dashboard" */ '@/views/DepositCards.vue'),
    meta: {
      title: 'card_deposits_dashboard',
      permission: Perm.TAP_CARD_DEPOSIT_REPORT
    },
  },
  {
    path: '/cash-deposits',
    name: 'cash_deposits',
    component: () => import(/* webpackChunkName: "cash_deposits" */ '@/views/CashDeposits.vue'),
    meta: {
      title: 'cash_deposits',
      permission: Perm.CASH_DEPOSIT_LIST
    },
  },
  {
    path: '/cash-deposit-refunds',
    name: 'cash_deposit_refunds',
    component: () => import(/* webpackChunkName: "cash_deposit_refunds" */ '@/views/CashDepositRefunds.vue'),
    meta: {
      title: 'cash_deposit_refunds',
      permission: Perm.CASH_DEPOSIT_REFUND_LIST,
    },
    children: [
      {
        path: ':refundId',
        name: 'cashDepositRefundDetails',
        component: () => import(/* webpackChunkName: "cash_deposit_refund_details" */ '@/components/Deposit/Cash/CashDepositRefundDetails.vue'),
        props: true,
        meta: {
          permission: Perm.CASH_DEPOSIT_REFUND_DETAILS,
        }
      }
    ]
  },
  {
    path: '/box-office',
    name: 'box_office',
    component: () => import(/* webpackChunkName: "box_office" */ '@/views/BoxOffice.vue'),
    meta: {
      title: 'box_office',
      permission: Perm.BOX_OFFICE
    },
  },
  {
    path: '/upcoming-amusement-events',
    name: 'upcoming_amusement_events',
    component: () => import(/* webpackChunkName: "box_office" */ '@/views/UpcomingAmusementEvents.vue'),
    meta: {
      title: 'upcoming_amusement_events',
      permission: Perm.MULTIPLE_TAP_CARD_CREATE
    },
  },
  {
    path: '/deposit-refunds',
    name: 'deposit_refunds',
    component: () => import(/* webpackChunkName: "deposit_refunds" */ '@/views/DepositRefunds.vue'),
    meta: {
      title: 'deposit_refunds',
      permission: Perm.DEPOSIT_REFUND_LIST,
    },
    children: [
      {
        path: ':refundId',
        name: 'depositRefundDetails',
        component: () => import(/* webpackChunkName: "deposit_refund_details" */ '@/components/Deposit/DepositRefundDetails.vue'),
        props: true,
        meta: {
          permission: Perm.DEPOSIT_REFUND_DETAILS,
        }
      }
    ]
  },
  {
    path: '/deposit-fails',
    name: 'deposit_fails',
    component: () => import(/* webpackChunkName: "deposit_fails" */ '@/views/DepositFails.vue'),
    meta: {
      title: 'deposit_fails',
      permission: Perm.DEPOSIT_FAIL_LIST
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import(/* webpackChunkName: "payments" */ '@/views/Payments.vue'),
    meta: {
      title: 'payments',
      permission: Perm.PAYMENT_LIST,
    },
    children: [
      {
        path: ':paymentId',
        name: 'paymentDetails',
        component: () => import(/* webpackChunkName: "payment_details" */ '@/components/Payments/PaymentDetails.vue'),
        props: true,
        meta: {
          permission: Perm.PAYMENT_DETAILS,
        }
      }
    ]
  },
  {
    path: '/payment-refund-requests',
    name: 'payment_refund_requests',
    component: () => import(/* webpackChunkName: "payment_refund_requests" */ '@/views/PaymentRefundRequests.vue'),
    meta: {
      title: 'payment_refund_requests',
      permission: Perm.PAYMENT_REFUND_REQUEST_LIST,
    },
  },
  {
    path: '/point-history',
    name: 'point_history',
    component: () => import (/* webpackChunkName: "point_history" */ '@/views/PointHistory.vue'),
    meta: {
      title: 'points',
      permission: Perm.POINT_HISTORY_LIST,
    }
  },
  {
    path: '/wallets',
    name: 'wallets',
    component: () => import (/* webpackChunkName: "wallets" */ '@/views/Wallets.vue'),
    meta: {
      title: 'wallets',
      permission: Perm.WALLET_LIST,
    }
  },
  {
    path: '/gift-cards-list',
    name: 'gift_cards_list',
    component: () => import(/* webpackChunkName: "gift_cards_list" */ '@/views/GiftCardsList.vue'),
    meta: {
      title: 'gift_cards',
      permission: Perm.PERSONAL_USER_DETAILS,
    },
  },
  {
    path: '/wallet-shares-list',
    name: 'wallet_shares_list',
    component: () => import(/* webpackChunkName: "wallet_shares_list" */ '@/views/WalletSharesList.vue'),
    meta: {
      title: 'wallet_sharing',
      permission: Perm.PERSONAL_USER_DETAILS,
    }
  },
  {
    path: '/wallet-shares-list/history',
    name: 'wallet_shares_list.history',
    component: () => import(/* webpackChunkName: "wallet_shares_list_history" */ '@/views/WalletSharesListHistory.vue'),
    meta: {
      title: 'wallet_share_history',
      permission: Perm.PERSONAL_USER_DETAILS,
    }
  },
  {
    path: '/balance-history',
    name: 'balance_history',
    component: () => import (/* webpackChunkName: "balance_history" */ '@/views/BalanceHistory.vue'),
    meta: {
      title: 'balance_history',
      permission: Perm.BALANCE_HISTORY_LIST,
    }
  },
  {
    path: '/static-qr',
    name: 'static_qr',
    component: () => import (/* webpackChunkName: "static_qr" */ '@/views/StaticQrList.vue'),
    meta: {
      title: 'static_qr',
      permission: Perm.STATIC_QR_LIST,
    }
  },
  {
    path: '/tag-qr',
    name: 'tag_qr',
    component: () => import (/* webpackChunkName: "tag_qr" */ '@/views/TagQrList.vue'),
    meta: {
      title: 'tag_qr',
      permission: Perm.TAG_QR_LIST,
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import (/* webpackChunkName: "orders" */ '@/views/OrderList.vue'),
    meta: {
      title: 'orders',
      permission: Perm.ORDER_LIST,
    },
    children: [
      {
        path: ':orderId',
        name: 'orderDetails',
        component: () => import(/* webpackChunkName: "order_details" */ '@/components/Order/OrderDetails.vue'),
        props: true,
        meta: {
          permission: Perm.ORDER_DETAILS,
        }
      }
    ]
  },
  {
    path: '/soft-play-qrs',
    name: 'soft_play_qrs',
    component: () => import (/* webpackChunkName: "soft_play_qrs" */ '@/views/SoftPlay/SoftPlayQrs.vue'),
    meta: {
      title: 'soft_play_qrs',
      permission: Perm.SOFT_PLAY_QR_LIST,
    }
  },
  {
    path: '/predefined-deposits',
    name: 'predefined_deposits',
    component: () => import(/* webpackChunkName: "predefined_deposits" */ '@/views/PredefinedDeposits.vue'),
    meta: {
      title: 'predefined_deposits',
      permission: Perm.BOX_OFFICE_PREDEFINED_DEPOSIT_LIST,
    }
  },
  {
    path: '/moderator-wallets',
    name: 'moderator_wallets',
    component: () => import(/* webpackChunkName: "moderator_wallets" */ '@/views/Moderator/ModeratorWallets.vue'),
    meta: {
      title: 'wallets',
      permission: Perm.MODERATOR_WALLET_LIST,
    },
  },
  {
    path: '/wallet/:id',
    name: 'wallet.summary',
    component: () => import(/* webpackChunkName: "wallets" */ '@/views/Moderator/WalletSummary.vue'),
    meta: {
      title: 'wallet_summary',
      permission: Perm.MODERATOR_WALLET_LIST,
    },
  },
  {
    path: '/wallet-shares/:walletId',
    name: 'wallet.shares',
    component: () => import(/* webpackChunkName: "wallet_shares" */ '@/views/WalletShares.vue'),
    meta: {
      title: 'wallet_shares',
      permission: Perm.MODERATOR_WALLET_SHARE,
    },
  },
  {
    path: '/moderator-documents',
    name: 'moderator_documents',
    component: () => import(/* webpackChunkName: "moderator_documents" */ '@/views/ModeratorDocuments.vue'),
    meta: {
      title: 'moderator_documents',
      permission: Perm.MODERATOR_DOCUMENTS,
    },
  },
  {
    path: '/bank-transfers',
    name: 'bank_transfers',
    component: () => import(/* webpackChunkName: "bank_transfers" */ '@/views/BankTransfer.vue'),
    meta: {
      title: 'bank_transfers',
      permission: Perm.BANK_TRANSFER_LIST,
    }
  },
  {
    path: '/moderator-balance-history',
    name: 'moderator_balance_history',
    component: () => import(/* webpackChunkName: "moderator_balance_history" */ '@/views/ModeratorBalanceHistory.vue'),
    meta: {
      title: 'balance_history',
      permission: Perm.MODERATOR_BALANCE_HISTORY,
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import(/* webpackChunkName: "organizations" */ '@/views/Organizations.vue'),
    meta: {
      title: 'organizations',
      permission: Perm.ORG_LIST,
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import(/* webpackChunkName: "companies" */ '@/views/Companies.vue'),
    meta: {
      title: 'companies',
      permission: Perm.COMPANY_LIST,
    },
  },
  {
    path: '/companies/sorting',
    name: 'companies.sorting',
    component: () => import(/* webpackChunkName: "company_sorting" */ '@/views/CompanySorting.vue'),
    meta: {
      title: 'companies',
      permission: Perm.COMPANY_SORT,
    },
  },
  {
    path: '/companies/catagories',
    name: 'companies.categories',
    component: () => import(/* webpackChunkName: "companies_categories" */ '@/views/CompanyCategories.vue'),
    meta: {
      title: 'company_categories',
      permission: Perm.COMPANY_CATEGORY_LIST,
    },
  },
  {
    path: '/companies/facility-features',
    name: 'companies.facility_features',
    component: () => import(/* webpackChunkName: "company_facility_features" */ '@/views/CompanyFacilityFeatures.vue'),
    meta: {
      title: 'facility_features',
      permission: Perm.COMPANY_FACILITY_FEATURE_LIST,
    },
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: () => import(/* webpackChunkName: "tokens" */ '@/views/Tokens.vue'),
    meta: {
      title: 'tokens',
      permission: Perm.TOKEN_LIST,
    },
  },
  {
    path: '/eftpos-accounts',
    name: 'eftpos',
    component: () => import(/* webpackChunkName: "eftpos" */ '@/views/Eftpos.vue'),
    meta: {
      title: 'eftpos',
      permission: Perm.EFTPOS_LIST,
    },
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import(/* webpackChunkName: "coupons" */ '@/views/CouponGroups.vue'),
    meta: {
      title: 'coupons',
      permission: Perm.COUPON_LIST,
    },
  },
  {
    path: '/user-coupons',
    name: 'user_coupons',
    component: () => import(/* webpackChunkName: "user_coupons" */ '@/views/UserCoupons.vue'),
    meta: {
      title: 'user_coupons',
      permission: Perm.USER_COUPON_LIST,
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import(/* webpackChunkName: "campaigns" */ '@/views/Campaigns.vue'),
    meta: {
      title: 'campaigns',
      permission: Perm.CAMPAIGN_LIST,
    },
  },
  {
    path: '/campaign-news',
    name: 'campaign_news',
    component: () => import(/* webpackChunkName: "campaign_news" */ '@/views/CampaignNews.vue'),
    meta: {
      title: 'campaign_news',
      permission: Perm.CAMPAIGN_NEWS_LIST,
    },
  },
  {
    path: '/company-news',
    name: 'company_news',
    component: () => import(/* webpackChunkName: "company_news" */ '@/views/CompanyNews.vue'),
    meta: {
      title: 'company_news',
      permission: Perm.COMPANY_NEWS_LIST,
    },
  },
  {
    path: '/privacy-logs',
    name: 'privacy_logs',
    component: () => import(/* webpackChunkName: "privacy_logs" */ '@/views/Log/PrivacyLogs.vue'),
    meta: {
      title: 'privacy_logs',
      permission: Perm.USER_PRIVACY_LOGS,
    },
  },
  {
    path: '/sms-provider-settings',
    name: 'sms_provider_settings',
    component: () => import(/* webpackChunkName: "sms_provider_settings" */ '@/views/SmsProviderSettings.vue'),
    meta: {
      title: 'sms_provider',
      permission: Perm.SMS_PROVIDER_SETTING_LIST,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: {
      title: 'profile',
      permission: Perm.PANEL_LOGIN,
    },
  },
  {
    path: '/blog/categories',
    name: 'blog_categories',
    component: () => import(/* webpackChunkName: "blog_categories" */ '@/views/BlogCategories.vue'),
    meta: {
      title: 'blog_category_list',
      permission: Perm.BLOG_CATEGORY_LIST,
    },
  },
  {
    path: '/blog/post-list',
    name: 'blog_post_list',
    component: () => import(/* webpackChunkName: "blog_posts" */ '@/views/BlogPostList.vue'),
    meta: {
      title: 'blog_post_list',
      permission: Perm.BLOG_POST_LIST,
    },
  },
  {
    path: '/push-notification-news',
    name: 'push_notification_news',
    component: () => import(/* webpackChunkName: "push_notification_news" */ '@/views/PushNotificationNews.vue'),
    meta: {
      title: 'push_notification_news',
      permission: Perm.PUSH_NOTIFICATION_NEWS_LIST,
    },
  },
  {
    path: '/schedule-reports',
    name: 'schedule_reports',
    component: () => import(/* webpackChunkName: "schedule_reports" */ '@/views/ScheduleReports.vue'),
    meta: {
      title: 'schedule_reports',
      permission: Perm.SCHEDULE_REPORT_LIST,
    },
  },
  {
    path: '/marketing-report',
    name: 'marketing_report',
    component: () => import(/* webpackChunkName: "marketing_report" */ '@/views/Report/MarketingReport.vue'),
    meta: {
      title: 'marketing_report',
      permission: Perm.MARKETING_REPORT,
    },
  },
  {
    path: '/commission-report',
    name: 'commission_report',
    component: () => import(/* webpackChunkName: "commission_report" */ '@/views/Report/CommissionReport.vue'),
    meta: {
      title: 'commission_report',
      permission: Perm.COMMISSION_REPORT,
    },
  },
  {
    path: '/soft-play-report',
    name: 'soft_play_report',
    component: () => import(/* webpackChunkName: "soft_play_report" */ '@/views/Report/SoftPlayReport.vue'),
    meta: {
      title: 'soft_play_report',
      permission: Perm.SOFT_PLAY_HISTORY_REPORT,
    },
  },
  {
    path: '/payment-transfers',
    name: 'payment_transfers',
    component: () => import(/* webpackChunkName: "payment_transfers" */ '@/views/PaymentTransfers.vue'),
    meta: {
      title: 'payment_transfers',
      permission: Perm.PAYMENT_TRANSFER_LIST,
    },
  },
  {
    path: '/eftpos-settlement-report',
    name: 'eftpos-settlement-report',
    component: () => import(/* webpackChunkName: "eftpos_settlement_report" */ '@/views/Report/EftposSettlementReport.vue'),
    meta: {
      title: 'eftpos_settlement_report',
      permission: Perm.EFTPOS_SETTLEMENT_REPORT,
    },
  },
  {
    path: '/device-ops/dashboard',
    name: 'device-ops-dashboard',
    meta: {
      title: 'device_ops_dashboard',
      permission: Perm.ARGE_DASHBOARD_STATS,
    },
    component: () => import(/* webpackChunkName: "device_ops_dashboard" */ '@/views/DeviceOps/Dashboard.vue'),
  },
  {
    path: '/device-ops/devices',
    name: 'device-ops-devices',
    meta: {
      title: 'devices',
      permission: Perm.ARGE_DEVICE_LIST,
    },
    component: () => import(/* webpackChunkName: "device_ops_devices" */ '@/views/DeviceOps/Devices.vue'),
  },
  {
    path: '/qr-menu',
    name: 'qr_menu',
    component: () => import(/* webpackChunkName: "qr_menu_classic" */ '@/views/QrMenu.vue'),
    meta: {
      title: 'qr_menu_classic',
      permission: Perm.QR_MENU_LIST,
    }
  },
  {
    path: '/corporations',
    component: () => import(/* webpackChunkName: "corporations" */ '@/views/Corporations.vue'),
    name: 'corporations',
    meta: {
      title: 'corporations',
      permission: Perm.CORPORATION_LIST,
    },
  },
  {
    path: '/amusement-events',
    component: () => import(/* webpackChunkName: "amusement_events" */ '@/views/AmusementEvents.vue'),
    name: 'amusement_events',
    meta: {
      title: 'amusement_events',
      permission: Perm.AMUSEMENT_EVENT_LIST,
    },
  },
  {
    path: '/soft-play-dashboard',
    name: 'soft_play_dashboard',
    component: () => import(/* webpackChunkName: "soft_play_dashboard" */ '@/views/SoftPlay/SoftPlayDashboard.vue'),
    meta: {
      title: 'soft_play_dashboard',
    }
  },
  {
    path: '/soft-play',
    name: 'soft_play',
    component: () => import(/* webpackChunkName: "soft_play" */ '@/views/SoftPlay/SoftPlay.vue'),
    meta: {
      title: 'soft_play',
      permission: Perm.SOFT_PLAY_INFO,
    }
  },
  {
    path: '/soft-play/list',
    name: 'soft_play_list',
    component: () => import(/* webpackChunkName: "soft_play_list" */ '@/views/SoftPlay/SoftPlayList.vue'),
    meta: {
      title: 'soft_play_list',
      permission: Perm.SOFT_PLAY_INFO,
    }
  },
  {
    path: '/soft-play/anonymous-child/:id',
    name: 'soft_play_anonymous_child',
    component: () => import(/* webpackChunkName: "soft_play" */ '@/views/SoftPlay/SoftPlayAnonymousChild.vue'),
    meta: {
      title: 'soft_play_anonymous_child_list',
      permission: Perm.SOFT_PLAY_INFO,
    }
  },
  {
    path: '/soft-play/:parent_id/payment/:id',
    name: 'soft_play_payment',
    component: () => import(/* webpackChunkName: "soft_play" */ '@/views/SoftPlay/SoftPlayPayment.vue'),
    meta: {
      title: 'soft_play_payment',
      permission: Perm.SOFT_PLAY_INFO,
    }
  },
  {
    path: '/soft-play/take-in/:id',
    name: 'soft_play_take_in',
    component: () => import(/* webpackChunkName: "soft_play" */ '@/views/SoftPlay/SoftPlayTakeIn.vue'),
    meta: {
      title: 'soft_play_take_in',
      permission: Perm.SOFT_PLAY_INFO,
    }
  },
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full_page" */ '@/views/FullPage.vue'),
    children: [
      {
        meta: {
          title: 'login',
          guest: true,
        },
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "full_page_login" */ '@/views/full-page/Login.vue')
      },
      {
        meta: {
          title: 'password_reset',
          guest: true,
        },
        path: '/password-reset/:token/:email',
        name: 'password_reset',
        component: () => import(/* webpackChunkName: "password_reset" */ '@/views/full-page/PasswordReset.vue')
      },
      {
        meta: {
          title: 'create_password',
          guest: true,
        },
        path: '/create-password/:token',
        name: 'create_password',
        component: () => import(/* webpackChunkName: "create_password" */ '@/views/full-page/CreatePassword.vue')
      },
      {
        meta: {
          title: 'permission_denied',
          guest: true,
        },
        path: '/permission-denied',
        name: 'permission_denied',
        component: () => import(/* webpackChunkName: "full_page_permission_denied" */ '@/views/full-page/PermissionDenied.vue'),
      },
    ]
  },
  {
    path: '*',
    name: 'not_found',
    meta: {
      title: 'not_found',
      guest: true,
    },
    component: () => import(/* webpackChunkName: "full_page_not_found" */ '@/views/full-page/NotFound.vue'),
  },
]

const router = new VueRouter({
  base: import.meta.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  parseQuery(query) {
    return qs.parse(query)
  },
  stringifyQuery(query) {
    const result = qs.stringify(query)
    return result ? ('?' + decodeURIComponent(result)) : ''
  },
})

export default router
