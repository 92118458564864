/* eslint-disable no-unused-vars */
import Vue from 'vue'
import { capitalize, isEmpty, isNumber, startsWith } from 'lodash'
import dayjs from 'dayjs'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import tr from 'dayjs/locale/tr'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const locale = window.localStorage.getItem('lang') ?? 'tr'

Vue.filter('firstWord', (value) => {
  if (value) {
    return capitalize(value.replace(/ .*/, ''))
  }
})

Vue.filter('formatDate', (value) => {
  return value ? dayjs(value).locale(locale).format('DD MMMM HH:mm:ss') : null
})

Vue.filter('formatDateYear', (value) => {
  return value ? dayjs(value).locale(locale).format('DD MMMM YYYY') : null
})

Vue.filter('formatUnixDateTimeYear', (value) => {
  return value ? dayjs.unix(value).locale(locale).format('DD MMMM YYYY HH:mm:ss') : null
})

Vue.filter('formatDateDayName', (value) => {
  return value ? dayjs(value).locale(locale).format('DD MMMM YYYY ddd') : null
})

Vue.filter('formatDateTimeYear', (value) => {
  return value ? dayjs(value).locale(locale).format('DD MMMM YYYY HH:mm') : null
})

Vue.filter('formatFromNow', (value) => {
  return value ? dayjs(value).locale(locale).fromNow(true) : null
})

Vue.filter('formatTime', (value) => {
  const [hours, minutes, seconds] = value.split(':')

  const date = new Date(null, null, null, hours, minutes, seconds)
  return dayjs(date).locale(locale).format('HH:mm')
})

Vue.filter('showOnlyTime', (value) => {
  return value ? dayjs(value).format('HH:mm') : null
})

Vue.filter('fromNow', (value) => {
  return value ? dayjs(value).locale(locale).fromNow(true) : null
})

Vue.filter('phone', (number) => {
  if (isEmpty(number)) {
    return null
  }

  number = `+${ number }`

  if (number.includes('*')) {
    return number
  }

  try {
    const asYouType = new AsYouType()
    asYouType.input(number)

    if (asYouType.getNumber()) {
      const formated = parsePhoneNumberFromString(asYouType.getNumber().number, asYouType.getNumber().country)
      return formated.formatInternational()
    } else {
      if (startsWith(number, '0')) {
        number = number.substring(1)
      }
    }
  } catch (e) {
    //
  }

  return number.replace(/[^0-9|+]/g, '')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3 $4')
})

Vue.filter('number', (value) => {
  return isNumber(value) ? Number(value).toLocaleString(locale) : value
})
