export default {
  event_logs: {
    date: 'Tarih',
    source: 'Kaynak',
    transaction_id: 'İşlem ID',
    event: 'Eylem',
    message: 'Mesaj',
  },

  login: 'Giriş Yap',
  login_two_factor: 'Güvenli Giriş',
  profile: 'Profilim',
  dashboard: 'Kontrol Paneli',
  virtual_pos: 'Sanal Poslar',
  not_found: 'Sayfa Bulunamadı',
  users: 'Kullanıcılar',
  device_ops: 'Cihaz İşlemleri',
  devices_info: 'Cihaz Verileri',
  devices: 'Cihazlar',
  branches: 'Şubeler',
  agreements: 'Sözleşmeler',
  corporations: 'Kurumlar',
  amusement_events: 'Etkinlikler',
  deposits: 'Yüklemeler',
  deposit_fails: 'Hatalı Yüklemeler',
  refunds: 'İadeler',
  deposit_refunds: 'Yükleme İadeleri',
  cash_deposit_refunds: 'Nakit Yükleme İadeleri',
  payments: 'Harcamalar',
  organizations: 'Organizasyonlar',
  companies: 'Şirketler',
  company_categories: 'Şirket Kategorileri',
  facility_features: 'Tesis Özellikleri',
  tokens: 'Tokenlar',
  coupons: 'Kuponlar',
  personal_users: 'Müşteriler',
  user_reviews: 'Kullanıcı Değerlendirmeleri',
  wallets: 'Cüzdanlar',
  shared_wallets: 'Paylaştığı Cüzdanlar',
  sharer_wallets: 'Paylaşılan Cüzdanlar',
  wallet_summary: 'Cüzdan Özeti',
  activities: 'Aktiviteler',
  refund_requests: 'İade Talepleri',
  payment_refund_requests: 'Harcama İade Talepleri',
  refund_request: 'İade Talebi',
  campaigns: 'Kampanyalar',
  moderator_documents: 'Belgeler',
  moderator_wallet_applications: 'Kurumsal Cüzdan Başvuruları ',
  moderator_applications: 'Kurumsal Başvurular',
  applications: 'Başvurular',
  reject_document: 'Belgeyi Reddet',
  balance_history: 'Bakiye Geçmişi',
  bank_transfers: 'Yükleme Talimatları',
  load_balance: 'Bakiye Yükle',
  wallet_shares: 'Bakiye Paylaş',
  wallet_sharing: 'Cüzdan Paylaşımları',
  wallet_share_history: 'Cüzdan Paylaşım Geçmişi',
  campaign_news: 'Kampanya Duyuruları',
  company_news: 'Şirket Duyuruları',
  password_reset: 'Şifre Sıfırlama',
  points: 'Puanlar',
  qr_menu_panel: 'QR Menu Panel',
  point_history: 'Puan Geçmişi',
  schedule_reports: 'Zamanlanmış Raporlar',
  application_documents: 'Başvuru Belgeleri',
  general: 'Genel',
  logout: 'Çıkış Yap',
  my_profile: 'Profilim',
  settings: 'Ayarlar',
  marketing: 'Marketing',
  integration: 'Entegrasyon',
  eftpos: 'ÖKC',
  moderator: 'Moderatör',
  corporate_wallet: 'Kurumsal Cüzdan',
  catalog: 'Katalog',
  reports: 'Raporlar',
  wallet_transactions: 'Cüzdan Hareketleri',
  user_management: 'Kullanıcı Yönetimi',
  personal_user_groups: 'Müşteri Grupları',
  box_office: 'Gişe / Kart İşlemleri',
  company_descriptions: 'Firma Tanımları',
  personal_user_details: 'Müşteri Detayları',
  cash_deposits: 'Nakit Yüklemeler',
  push_notification_news: 'Push Notifications',
  blog: 'Blog',
  blog_category_list: 'Kategori Listesi',
  blog_post_list: 'Post Listesi',
  static_qr: 'Static QR',
  tag_qr: 'Tag QR',
  potential_users: 'Potansiyel Müşteriler',
  privacy_logs: 'Gizlilik Logları',
  sms_provider: 'Sms Sağlayıcı',
  iys_logs: 'Iys Logları',
  tickets: 'Biletler',
  commission_report: 'Komisyon Raporu',
  soft_play_report: 'Soft Play Raporu',
  marketing_report: 'Marketing Raporu',
  permission_denied: 'İzin Reddedildi',
  old_data: 'Eski Veriler',
  new_data: 'Yeni Veriler',
  create_password: 'Şifre Oluşturma',
  gift_cards: 'Hediye Kartları',
  gift_card: 'Hediye Kartı',
  permission_matrix: 'Yetki Matrisi',
  qr_menu_classic: 'QR Menu Klasik',
  device_operations: 'Cihaz İşlemleri',
  device_operations_panel: 'Cihaz İşlemleri Panel',
  user_coupons: 'Müşteri Kuponları',
  soft_play_qrs: 'Soft Play QR',
  zone_management: 'Alan Yönetimi',
  soft_play_list: 'Alandaki Çocuklar',
  soft_play: 'Alan Yönetimi',
  soft_play_anonymous_child_list: 'Çocuk Listesi',
  soft_play_payment: 'Ödeme',
  soft_play_take_in: 'İçeri Al',
  payment_transfers: 'Harcama Transferleri',
  eftpos_settlement_report: 'ÖKC Mutabakat Raporu',
  device_ops_dashboard: 'Cihaz İşlemleri Kontrol Paneli',
  upcoming_amusement_events: 'Yaklaşan Etkinlikler',
  warning: 'Uyarı',
  documents: 'Belgeler',
  box_office_report: 'Gişe Raporu',
  remainder: 'Kalan',
  branch_payments: 'Şube Harcamaları',
  tag_qr_payments: 'Tag QR Harcamaları',
  box_office_payments: 'Gişe Harcamaları',
  device_details: 'Cihaz Detayları',
  device_configs: 'Cihaz Configleri',
  soft_play_dashboard: 'Softplay Süre Takip',
  card_deposits_dashboard: 'Depozito Paneli',
  cashier_report: 'Gişe Raporu',
  resarch_and_development: 'Arge',
  resarch_and_development_panel: 'Arge Panel',
  invite_friend: 'Arkadaşını Davet Et',
  branches_area_boundary: 'Şube Sorumluluk Alanı',
  orders: 'Siparişler',
  predefined_deposits: 'Ön Tanımlı Yüklemeler',

}
