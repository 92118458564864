import smart_table from '@/lang/tr/smart_table'
import titles from '@/lang/tr/titles'
import permission from '@/lang/tr/permission'

export default {
  login: 'Giriş Yap',
  moderator_login: 'Kurumsal Cüzdan',
  forgot_password: 'Şifremi unuttum',
  email: 'E-posta',
  enter_email: 'E-posta giriniz',
  email_address: 'E-posta Adresi',
  password: 'Şifre',
  login_failed: 'Giriş Başarısız',
  login_wrong_email_password: 'Giriş Bilgileri Hatalı',
  login_two_factor_title: 'Güvenli Giriş',
  login_two_factor_message: 'İki faktörlü kimlik doğrulayıcı uygulamanızdan ürettiğiniz kodu girin',
  login_two_factor_failed: 'Geçersiz Güvenlik Kodu',
  login_two_factor_forgot_message: 'Kimlik doğrulayıcı uygulamanızı kaybetmeniz durumunda şifrenizi <br> sıfırlamak için destek@macellan.app adresine e-posta gönderin. <br> Müşteri temsilcilerimiz sizinle en kısa zamanda iletişime geçecektir.',
  two_factor_input_label: 'Güvenlik yada Kurtarma Kodu',
  two_factor_form_title: 'İki Faktörlü Kimlik Doğrulama',
  two_factor_recovery: 'Kurtarma Kodları',
  two_factor_recovery_message: 'Her kurtarma kodunu sadece 1 defa kullanabilirsiniz. Kimlik doğrulama uygulamasını kaybetmeniz durumunda kullanın ve bu kodları saklayın.',
  two_factor_activate_message: 'İki faktörlü kimlik doğrulamayı etkinleştirmek için QR kodunu {ga}, {ma}, {ta} Authenticator uygulamalarından birini kullanarak tarayın ve Güvenlik kodunu girin.',
  two_factor_deactivate_message: 'Kimlik doğrulama etkinleştirilmiştir, kaldırmak için güvenlik kodunu girin.',
  modal_close: 'Kapat',
  two_factor_activate: 'Etkinleştir',
  two_factor_deactivate: 'Kaldır',
  two_factor: '2FA',
  notifications: 'Bildirimler',
  close_notification: 'Bildirimi Kapat',
  go_back: 'Geri Dön',
  add_virtual_pos: 'Sanal Pos Ekle',
  edit_virtual_pos: 'Sanal Pos Düzenle',
  virtual_pos: 'Sanal Pos',
  add_pos: 'Pos Ekle',
  add: 'Ekle',
  remove_user_text: 'Kullanıcıyı listeden kaldırmak istediğinize emin misiniz?',
  remove_all_user_text: '<b>Tüm kullanıcıları</b> listeden kaldırmak istediğinize emin misiniz? Bu işlem geri alınamaz.',
  user_remove_from_list: 'Listeden Çıkar',
  create_company: 'Şirket Oluştur',
  edit_company: 'Şirket Düzenle',
  company_category: 'Şirket Kategorisi',
  create_company_category: 'Şirket Kategorisi Oluştur',
  edit_company_category: 'Şirket Kategorisi Düzenle',
  company_name: 'Şirket Adı',
  company_status: 'Şirket Durumu',
  company_short_description: 'Şirket Mottosu',
  company_description: 'Şirket Açıklama',
  all_companies: 'Tüm Şirketler',
  create_organization: 'Organizasyon Oluştur',
  edit_organization: 'Organizasyon Düzenle',
  full_name: 'İsim Soyisim',
  name: 'İsim',
  enter: 'Giriniz',
  creator: 'Oluşturan',
  save: 'Kaydet',
  client_id: 'Müşteri No',
  merchant_id: 'Mağaza No',
  pos_name: 'Pos Adı',
  user: 'Kullanıcı',
  username: 'Kullanıcı Adı',
  storekey: 'Store Key',
  status: 'Durum',
  wallet_share: 'Cüzdan Paylaşımı',
  wallet_share_history: 'Cüzdan Paylaşım Geçmişi',
  gift_card: 'Hediye Kartı',
  deposit_status: 'Yükleme Durumu',
  bank_status: 'Banka Durumu',
  default: 'Varsayılan',
  bank: 'Banka',
  gateway: 'Gateway',
  wallet: 'Cüzdan',
  organization: 'Organizasyon',
  invoice_title: 'Fatura Ünvanı',
  currency: 'Para Birimi',
  select_currency: 'Para Birimi seçiniz',
  company: 'Şirket',
  target_company: 'Hedef Şirket',
  companies: 'Şirketler',
  enter_company_name: 'Şirket Adı giriniz',
  company_info: 'Şirket Bilgileri',
  branch: 'Şube',
  role: 'Yetki',
  select_role: 'Yetki Seçiniz',
  select_organization: 'Organizasyon Seçiniz',
  select_bank: 'Banka Seçin',
  select_company: 'Şirket Seçin',
  add_company: 'Şirket Ekleyin',
  select_wallet: 'Cüzdan Seçin',
  select_branch: 'Şube Seçin',
  add_branch: 'Şube Ekle',
  add_multiple_branch: 'Çoklu Şube Ekle',
  progress_success: 'İşlem Başarılı',
  progress_error: 'İşlem sırasında hata oluştu',
  not_allowed: 'İzin Verilmedi',
  allow: 'İzin Ver',
  progress_unauthorized: 'Bu işlemi yapmanıza izin verilmiyor',
  progress_internal_error: 'Sunucu Taraflı Bir Hata Oluştu. <br>Lütfen Tekrar Deneyiniz.',
  check_internet_connection: 'İnternet Bağlantınızı Kontrol Ediniz.',
  nodata: 'Veri bulunamadı...',
  no_records: 'Kayıt bulunamadı.',
  fetching: 'Yükleniyor',
  edit: 'Düzenle',
  details: 'Detaylar',
  search: 'Ara',
  refresh: 'Yenile',
  clear_filters: 'Filtreyi Temizle',
  submit: 'Gönder',
  cancel: 'İptal',
  features: 'Özellikler',
  color: 'Renk',
  logo: 'Logo',
  select_image: 'Görsel Seçin',
  image_upload: 'Resim Yükleme',
  change_view: 'Görünümü Değiştir',
  options: 'Seçenekler',
  sort_companies: 'Şirket Sırasını Değiştir',
  create_user: 'Kullanıcı Oluştur',
  create_tag_qr_batch: 'Toplu TagQR Oluştur',
  download_tag_qrs: 'Listedeki Tüm QR Kodları İndir',
  preparing_please_wait: 'Hazırlanıyor lütfen bekleyin...',
  filter_first: 'Lütfen önce filtreleme yapın',
  quantity: 'Adet',
  edit_user: 'Kullanıcı Düzenle',
  export_excel: 'Excel\'e Aktar',
  exporting_excel: 'Excel Dışarı Aktarma',
  exporting_excel_settings: 'Excel Dışarı Aktarma Ayarları',
  export_excel_with_template: 'Dışa Aktar',
  import_excel: 'Excel İçeri Aktar',
  upload_excel: 'Excel Dosyası Yükle',
  validate_and_import_excel: 'Excel Dosyasını Doğrula & İçeri Aktar',
  excel_validation_fail: 'Excel doğrulama başarısız oldu. Lütfen aşağıda belirtilen satır ve sütünlardaki hataları düzeltip tekrar deneyiniz.',
  excel_export_started: 'Excel Çıktısı Hazırlanıyor',
  excel_export_ready: 'Excel Çıktınız Hazır',
  excel_export: 'Excel Aktarım',
  excel_export_failed: 'Excel Çıktısı Hazırlanırken Bir Hata Oluştu',
  qr_with_logo: 'QR Kodun ortasında macellan logosu',
  row: 'Satır',
  column: 'Sutün',
  message: 'Mesaj',
  create_branch: 'Şube Oluştur',
  edit_branch: 'Şube Düzenle',
  branch_name: 'Şube Adı',
  branch_code: 'Şube Kodu',
  cashier: 'Kasiyer',
  created_at: 'Oluşturma Tarihi',
  updated_at: 'Güncelleme Tarihi',
  last_used_at: 'Son Kullanma',
  payment_time: 'Ödeme Tarihi',
  deposit_time: 'Yükleme Tarihi',
  phone: 'Telefon',
  enter_phone: 'Telefon giriniz',
  with_check: 'İle Sorgula',
  control: 'Kontrol Et',
  please_enter_phone: 'Lütfen geçerli bir telefon numarası giriniz.',
  short_description: 'Kısa Açıklama',
  description: 'Açıklama',
  enter_description: 'Açıklama Giriniz...',
  first_name: 'İsim',
  last_name: 'Soyisim',
  request_new_password: 'Yeni Şifre İste',
  email_verify: 'E-posta Doğrulaması',
  verify: 'Doğrula',
  must_verify_email: 'E-posta Doğrulaması Zorunlu',
  check_phone_number: 'Lütfen telefon numarasını kontrol ediniz.',
  check_form_inputs: 'Eksik alanlar var. Lütfen formu kontrol ediniz.',
  order_id: 'Sipariş No',
  id: 'ID',
  amount: 'Tutar',
  report_amount: 'Rapor Tutarı',
  bank_amount: 'Banka Tutar',
  type: 'Tip',
  win_type: 'Kazanım Tipi',
  win_name: 'Kazanım Adı',
  campaign_type: 'Kampanya Tipi',
  card_no: 'Kart No',
  create_card: 'Kart Oluştur',
  reference_code: 'Referans Kodu',
  transaction_code: 'İşlem Kodu',
  publication_status: 'Yayın Durumu',
  is_published: 'Yayın Durumu',
  bank_transaction_id: 'Banka İşlem Kodu',
  total_count_text: 'Toplam {0} kayıttan {1} - {2} arası gösteriliyor.',
  company_total_count_text: '{active} Aktif, {passive} Pasif, Toplam {total} şirket mevcut.',
  value_required: '{0} alanı gereklidir.',
  ask_create_agreement: 'Şirket Sözleşmesi Oluşturmak İster misiniz?',
  agreements: 'Sözleşmeler',
  agreement_accept_required_warring: 'Sözleşme eklediğinizde, tüm cüzdan kullanıcılarından yeni sözleşmeyi onaylamaları istenecektir. <br>Yeni sözleşme oluşturmak istediğinizden emin misiniz?',
  create_agreement: 'Sözleşme Oluştur',
  edit_agreement: 'Sözleşme Düzenle',
  accepted_count: 'Kabul Sayısı',
  revoke: 'İptal Et',
  create_token: 'Token Oluştur',
  edit_token: 'Token Düzenle',
  token_name: 'Token Adı',
  write_token_name: 'Token Adı Yazınız',
  select_user: 'Kullanıcı Seçiniz',
  write_user_name: 'Kullanıcının Adını Yazınız',
  write_wallet_name: 'Cüzdan Adını Yazınız',
  success: 'Başarılı',
  bank_fail: 'Banka Hatası',
  address: 'Adres',
  token_created_message: 'Token Başarıyla Oluşturuldu.<br><br><pre>{0}</pre><br>Lütfen not alın.',
  delete_alert: '<b>Silmek</b> İstediğinizden emin misiniz? Bu işlem geri alınamaz.',
  delete_token: 'Tokeni Sil',
  okay: 'Tamam',
  copy: 'Kopyala',
  make_duplicate: 'Kopyasını Oluştur',
  copied: 'Kopyalandı',
  enter_your_content: 'İçeriği buraya giriniz...',
  content_is_short: 'Lütfen daha uzun içerik giriniz',
  create: 'Oluştur',
  coupon_group_name: 'Kupon Grup Adı',
  coupon_group: 'Kupon Grubu',
  group: 'Group',
  start_date: 'Başlangıç Tarihi',
  end_date: 'Bitiş Tarihi',
  publish_start_date: 'Başlangıç Tarihi',
  publish_end_date: 'Bitiş Tarihi',
  deposit_date: 'Yükleme Tarihi',
  bank_date: 'Banka Tarihi',
  point_expire_date: 'Puan Son Kullanma Tarihi',
  point_expire_date_info: 'Bu grupdaki kuponlardan kazanılan puanın, harcanmadığı durumda geri alınacağı son tarih',
  click_to_select: 'Seçmek için tıklayın...',
  click: 'tıklayın',
  total_coupons: 'Toplam Kupon',
  total_usage: 'Toplam Kullanım',
  total: 'Toplam',
  shared: 'Paylaşılan',
  spent: 'Harcanan',
  shared_user: 'Paylaşılan Kullanıcı',
  shared_phone: 'Paylaşılan Numara',
  shared_full_name: 'Paylaşılan Kişi',
  load_balance: 'Bakiye Yükle',
  share_wallet: 'Cüzdan Paylaş',
  moderator_unapproved: 'Henüz Hesabınız Onaylanmamış.',
  edit_coupon_group: 'Kupon Grubunu Düzenle',
  coupons: 'Kuponlar',
  coupon: 'Kupon',
  coupon_code: 'Kupon Kodu',
  coupon_codes: 'Kupon Kodları',
  create_coupon: 'Kupon Oluştur',
  edit_coupon: 'Kupon Düzenle',
  multiple_usage: 'Çoklu Kullanım',
  include_multiples: 'Katlarını Dahil Et',
  coupon_amount: 'Kupon Tutarı',
  usage_limit: 'Kullanım Limiti',
  create_campaign: 'Kampanya Oluştur',
  edit_campaign: 'Kampanyayı Düzenle',
  campaign_name: 'Kampanya Adı',
  point_amount: 'Puan Tutarı',
  minimum_amount: 'Alt Limit',
  combined_usage: 'Diğer Kampanyalarla Kullanım',
  campaign_amount_info: 'Harcama yapılacak tutar',
  campaign_minimum_amount_info: 'Yazılacak tutar ve üzerindeki işlemler geçerli kabul edilir.',
  campaign_point_amount_info: 'Hediye edilecek puan',
  campaign_process_count_info: 'Yazılacak harcama işlemi adetine göre puan verilir.',
  campaign_usage_limit_info: 'Kampanyanın toplam kullanılma sayısı',
  campaign_combined_usage_info: 'Şirket\'e ait diğer kampanyalarla birlikte kullanılsın mı ?',
  campaign_multiple_usage_info: 'Kullanıcılar kampanyayı birden fazla kez kullanabilsin mi ?',
  create_campaign_news: 'Duyuru Oluştur',
  edit_campaign_news: 'Duyuru Düzenle',
  campaign_news_title: 'Duyuru Başlığı',
  select_campaign: 'Kampanya İsmini Yazmaya Başlayın',
  show_homepage_info: 'Duyuruyu anasayfada gösterilsin mi ?',
  sort_campaign_news: 'Duyuru Sıralamasını Değiştir',
  title: 'Başlık',
  slug: 'Slug ( Adres Kısa Adı )',
  campaign: 'Kampanya',
  associated_campaign: 'Bağlı Kampanya',
  select_associated_campaign: 'Bağlı Kampanya Seçin',
  associated_campaign_not_found: 'Bağlı kampanya bulunamadı.',
  usage: 'Kullanım',
  limit: 'Limit',
  last_usage_date: 'Son Kullanım',
  logout_event_message: 'Başka bir tarayıcıdan oturum açıldı. Güvenliğiniz için oturumunuz sonlandırılmıştır.',
  company_categories: 'Şirket Kategorileri',
  company_search: 'Şirket Ara',
  facility_features: 'Tesis Özellikleri',
  facility_feature_not_found: 'Kategoriye ait tesis özelliği bulunmamakta.',
  create_feature: 'Özellik Oluştur',
  feature_name: 'Özellik İsmi',
  feature_description: 'Özellik Açıklaması',
  create_facility_feature: 'Tesis Özelliği Oluştur',
  edit_facility_feature: 'Tesis Özelliği Düzenle',
  register_date: 'Üyelik Tarihi',
  subscribe_sms: 'Ticari SMS İzni',
  subscribe_email: 'Ticari E-mail İzni',
  delete: 'Sil',
  delete_all: 'Tümünü Sil',
  method: 'Yöntem',
  path: 'Path',
  status_code: 'Status',
  duration: 'Süre',
  query: 'Query',
  body: 'Body',
  headers: 'Headers',
  show: 'Görüntüle',
  approve: 'Onayla',
  reject: 'Reddet',
  min: 'Min',
  max: 'Max',
  refund_time: 'İade Tarihi',
  refunded_date: 'İade Tarihi',
  refund_reason: 'İade Nedeni',
  refund_status: 'İade Durumu',
  refund_info: 'İade Bilgileri',
  refund_data: 'İade Verisi',
  refund_type: 'İade Tipi',
  full_refund: 'Tam Geri Ödeme',
  partial_refund: 'Parçalı Geri Ödeme',
  refunded_user: 'İade Eden',
  bank_info: 'Banka Bilgileri',
  waiting: 'Bekliyor',
  user_comment: 'Müşteri Notu',
  company_comment: 'Şirket Notu',
  request_time: 'İstek Tarihi',
  process_count: 'İşlem Adeti',
  filter_button: 'Filtrele',
  active: {
    true: 'Aktif',
    false: 'Pasif',
  },
  balance: 'Bakiye',
  init_balance: 'Başlangıç Bakiye',
  shared_balance: 'Paylaşılan Bakiye',
  total_balance: 'Toplam Bakiye',
  point: 'Puan',
  sent_point: 'Gönderilen Puan',
  instant_wallet_balance: 'Anlık Cüzdan Bakiyesi',
  instant_wallet_point: 'Anlık Cüzdan Puanı',
  ip: 'IP Adresi',
  log_name: 'Log Türü',
  create_wallet: 'Cüzdan Oluştur',
  define_new_card: 'Yeni Kart Tanımla',
  new_card: 'Yeni Kart',
  card_user: 'Kart/Kullanıcı',
  total_cards_to_be_defined: 'Tanımlanacak Toplam Kart Sayısı',
  remaining_cards_to_be_defined: 'Tanımlanacak Kalan Kart Sayısı',
  number_of_cards_defined: 'Tanımlanmış Kart Sayısı',
  next: 'Sonraki',
  card_already_defined: 'Kart daha önceden farklı bir kullanıcıya tanımlanmış.<br>Lütfen başka bir kart deneyin.',
  card_already_defined_self: 'Kart zaten kullanıcıya tanımlı ve aktif',
  card_defined: 'Kart kullanıcıya başarıyla tanımlandı.',
  card_reset: 'Kart Sıfırla',
  cart_i: 'Kartı',
  deactivate: 'Pasife Al',
  deactivate_card: 'Kartı Pasife Al',
  card_deactivate_success: 'Kart başarıyla pasife alındı.',
  card_already_been_deactivate: 'Kart zaten pasif.',
  tap_card_list: 'Temassız Kart Listesi',
  tap_cards: 'Temassız Kartlar',
  cards: 'Kartlar',
  tap_cards_not_assing_user: 'Karta tanımlı kullanıcı bulunamadı',
  subscribe: 'Abone Ol',
  wallet_agreement: 'Cüzdan Sözleşmesi',
  has_no_wallet: 'Henüz kurumsal cüzdan oluşturmadınız.',
  lat_long: 'Enlem-Boylam',
  bank_name: 'Banka',
  receipt: 'Dekont',
  receipt_upload: 'Dekont Yükle',
  rejection_reason: 'Reddedilme Sebebi',
  promotion_amount: 'Hediye Tutarı',
  make_wallet_share: 'Bakiye Paylaş',
  available_balance: 'Kullanılabilir Bakiye',
  user_not_found: 'Kullanıcı Bulunamadı',
  registered_user_not_found: 'Kullanıcı Henüz Kayıt Olmamış',
  increment_to_balance: 'Bakiyeyi Üzerine Ekle',
  increment_to_balance_info: 'Varolan paylaşım tutarının üzerine eklemek için kullanılır.',
  find_user: 'Kullanıcıyı Getir',
  user_not_found_want_to_create: 'Kullanıcı Bulunamadı. Oluşturmak ister misiniz?',
  box_office_phone_already_exist: 'Telefon numarası zaten kayıt edilmiş. Lütfen telefon numarasıyla veya kart numarasıyla sorgulama yapınız.',
  do_not_forget_to_take_deposit_for_tap_card: 'depozito bedelini almayı unutmayınız!',
  do_not_forget_to_give_deposit_for_tap_card: 'depozito bedelini vermeyi unutmayınız!',
  select_first_user: 'Önce Kullanıcı Seçiniz.',
  user_created: 'Kullanıcı oluşturuldu.',
  verify_code_invalid: 'Doğrulama kodu yanlış. Doğrulama başarısız.',
  otp_verify_success: 'Doğrulama başarılı.',
  request_entity_too_large: 'Yüklediğiniz dosya boyutu çok büyük. Max: 10MB',
  expire_date: 'Son Kullanma Tarihi',
  unlimited_balance: 'Limitsiz Bakiye',
  share_cancelled: 'Paylaşım Durduruldu',
  share_cancel_confirm: 'Paylaşımı sonlandırmak istediğinizden emin misiniz?',
  shared_cancel: 'Paylaşımı Sonlandır',
  use_of_limitless: 'Limitsiz Kullanım',
  settings: 'Ayarlar',
  company_settings: 'Şirket Ayarları',
  iban: 'IBAN',
  iban_message: 'Iban 26 Haneli Olmalıdır.',
  add_bank: 'Banka Ekle',
  file_name: 'Dosya Adı',
  file_upload_text: 'Yüklemek için dosyalarınızı buraya bırakın veya tıklayın',
  excel_file_upload_text: 'Excel dosyanızı buraya bırakın veya tıklayın.',
  excel_import_success: 'Excel içeri aktarım talebiniz başarıyla alındı. İşlem gerçekleştiğinde eposta adresinize bildirim gönderilecektir.',
  file_type: 'Dosya Türü',
  file: 'Dosya',
  download_file: 'Dosyayı İndir',
  upload_file: 'Dosya Yükleyin',
  choose_file_type: 'Dosya Türü Seçin',
  choose_file: 'Dosya Seçin',
  save_logo: 'Logo Kaydet',
  review_description: 'Değerlendirme Açıklaması',
  reason_for_rejection: 'Reddetme Sebebi',
  enter_reason_for_rejection: 'Reddetme sebebi giriniz',
  pending_documents_count: 'Bekleyen Belge',
  send_for_review: 'İncelemeye Gönder',
  application_details: 'Başvuru Detayları',
  wallet_type: 'Cüzdan Türü',
  wallet_id: 'Cüzdan ID',
  static_qr_name: 'Kart Adı',
  capture: 'Bakiye Harcama',
  reason: 'Sebep',
  credit_card: 'Kredi Kartı',
  eftpos: 'ÖKC',
  terminal: 'Terminal',
  static_qr: 'Static QR',
  confirm: 'Onayla',
  update: 'Güncelle',
  update_device_config_details: 'Cihaz configlerini güncellediğinizde cihazın update alacağını unutmayın!',
  device_config_use_default: 'Varsayılan config ayarlarını kullan',
  update_device_config: 'Cihaz Config Güncelle',
  update_available: 'Panel güncelleniyor...',
  bank_transfer_accept_confirm: '<b>{1} {0}</b> tutarı <b>{2}</b> kişisinin cüzdanına aktarılacaktır. <br>İşlemi Onaylıyor musunuz?',
  bank_transfer_with_promotion_accept_confirm: '<b>{1} {0}</b> tutarı ve <b>{3} {0}</b> hediye tutarı <b>{2}</b> kişisinin cüzdanına aktarılacaktır. <br>İşlemi Onaylıyor musunuz?',
  default_deposit_amounts: 'Varsayılan Yükleme Tutarları',
  default_payment_amounts: 'Varsayılan Harcama Tutarları',
  tap_card_deposit_amount: 'Kart Depozito Bedeli',
  tap_card_deposit_amount_description: 'Müşteriye kart verilirken alınan depozito bedelidir.',
  bank_accounts: 'Banka Hesapları',
  balance_settings: 'Bakiye Yükleme - Harcama Tutarları',
  balance_settings_description: 'Tutar kısıtlamaları nakit yükleme için de geçerlidir.',
  deposit_settings: 'Yükleme Ayarları',
  minimum_deposit_amount: 'Minimum Yükleme Tutarı',
  maximum_deposit_amount: 'Maximum Yükleme Tutarı',
  password_reset: 'Şifre Sıfırlama',
  update_password: 'Şifre Güncelleme',
  update_password_to_be_continued: 'Devam Etmek İçin Lütfen Şifrenizi Değiştirin.',
  send_reset_link: 'Sıfırlama Bağlantısı Gönder',
  continue: 'Devam Et',
  password_confirmation: 'Şifre Tekrar',
  change_password: 'Şifremi Değiştir',
  send_password_reset_link_success: 'Kullanıcı varsa, şifre sıfırlama bağlantısı içeren bir e-posta alacaksınız.',
  password_reset_complete: 'Şifreniz başarıyla değiştirilmiştir.',
  force_3d: 'Tüm Yüklemelerde 3D zorunlu',
  minimum_3d_amount: '3D Minimum Tutar',
  minimum_3d_amount_description: 'Yüklemelerde bu değerden daha yüksek bir tutar girildiğinde, ödeme 3D zorunlu hale gelir.',
  version: 'Version',
  platform: 'Platform',
  last_login: 'Son Giriş',
  bank_transfer: 'Yükleme Talimatı',
  cash: 'Nakit',
  topup_success: 'Yükleme Başarılı',
  three_d: '3D',
  card_info: 'Kart Bilgileri',
  card_number: 'Kart Numarası',
  card_scheme: 'Kart Cinsi',
  card_bank: 'Kart Bankası',
  card_type: 'Kart Tipi',
  country: 'Ülke',
  is_business_card: 'Business Kart',
  personal: 'Kişisel',
  corporate: 'Kurumsal',
  result_code: 'Sonuç Kodu',
  result_message: 'Sonuç Mesajı',
  user_message: 'Kullanıcı Mesajı',
  users_count: 'Kullanıcı Sayısı',
  users: 'Kullanıcılar',
  user_groups: 'Kullanıcı Grubu',
  customer_groups: 'Müşteri Grubu',
  personal_info: 'Kişisel Bilgiler',
  wallet_info: 'Cüzdan Bilgileri',
  new_transaction: 'Yeni İşlem',
  deposit_info: 'Yükleme Bilgileri',
  bank_transfer_info: 'Yükleme Talimatı Bilgileri',
  point_earn: 'Kazanılan Puan',
  deposit_gift: 'Yüklemeye Puan',
  payment_count_gift: 'Harcama Sayısına Puan',
  cross_marketing_deposit_gift: 'Çapraz Satış Yüklemeye Puan',
  start: 'Başlatıldı',
  '3d_init': '3D Auth',
  '3d_closed': '3D Closed',
  fail: 'Başarısız',
  error: 'Hata',
  fraud: 'Fraud',
  complete: 'Tamamlandı',
  approval: 'Onayda',
  approved: 'Onaylandı',
  device_offline: 'Cihaz Çevrimdışı',
  timeout: 'Zaman Aşımı',
  result_data: 'Sonuç Yanıtı',
  request_data: 'İstek',
  transaction_data: 'İşlem Verisi',
  payment_info: 'Harcama Bilgileri',
  sharer: 'Paylaşan Kişi',
  sharer_full_name: 'Paylaşan',
  sharer_phone: 'Paylaşanın Telefonu',
  limitless: 'Limitsiz',
  paid_price: 'Tutar',
  cashier_phone: 'Kasiyer Telefon',
  cashier_email: 'Kasiyer Mail',
  receipt_total: 'Toplam Fiş Tutarı',
  branch_info: 'Şube Bilgileri',
  direct_payment_api: 'Direct Payment API',
  corporate_wallet: 'Kurumsal Cüzdan',
  bkm: 'BKM',
  masterpass: 'Masterpass',
  masterpass_dp: 'Masterpass DP',
  paycell: 'Paycell',
  turkcell_dcb: 'Turkcell DCB',
  rejected: 'Reddedildi',
  'top-up': 'Bakiye Yükleme',
  refund: 'İade Edildi',
  make_refund: 'İade Et',
  make_selected_refund: 'Seçilileri İade Et',
  make_all_refund: 'Tümünü İade Et',
  manual: 'Manuel',
  manual_refund: 'Manüel İade',
  mark_manual_refunded: 'Manüel İade Edildi Olarak İşaretle',
  enter_refund_amount: 'İade Etmek İstediğiniz Tutarı Girin.',
  enter_refunded_amount: 'İade Edilen Tutarı Girin.',
  enter_refunded_transaction_id: 'İade İşlem numarasını girin.',
  refund_transaction_id: 'İade İşlem No',
  transaction_id: 'İşlem Numarası',
  balance_detail: 'İşlem Detayı',
  daily: 'Günlük',
  weekly: 'Haftalık',
  monthly: 'Aylık',
  yearly: 'Senelik',
  current_password: 'Mevcut Şifre',
  confirm_doesnt_match: 'Şifreleriniz eşleşmiyor.',
  contains_number: 'En az 1 rakam kullanılmalıdır.',
  contains_uppercase: 'En az 1 büyük harf kullanılmalıdır.',
  contains_lowercase: 'En az 1 küçük harf kullanılmalıdır.',
  contains_length: 'Şifreniz en az {0} karakter olmalıdır.',
  refund_request: 'İade Talebi',
  close: 'Reddedildi',
  open: 'Onay Bekliyor',
  payment_amount: 'Harcama Tutarı',
  commissions: 'Komisyonlar',
  remaining_day: 'Kalan Gün',
  remaining_limit: 'Kalan Limit',
  create_blog_category: 'Kategori Oluştur',
  edit_blog_category: 'Kategori Düzenle',
  post_association: 'Post İlişkilendirme',
  post_status: 'Post Durumu',
  select_category: 'Kategori Seçiniz...',
  cover_image: 'Kapak Görseli',
  content_images: 'İçerik Görselleri',
  content_image: 'İçerik Görseli',
  content_image_warning: 'En fazla 5 görsel yüklenebilir.',
  create_qr_menu: 'QR Menu Oluştur',
  create_qr_code: 'QR Kod Oluştur',
  qr_menu_panel: 'QR Menü Panel',
  url: 'URL',
  target_link: 'Hedef Link',
  link: 'Link',
  branches_count: 'Şube Sayısı',
  branches: 'Şubeler',
  final_total: 'Alışveriş Toplamı',
  refund_amount: 'İade Tutarı',
  refunded_amount: 'İade Edilen Tutar',
  total_refund_amount: 'Toplam İade Tutarı',
  current_refundable_amount: 'Güncel İade Edilebilir Tutar',
  paste_url: 'URLi Yapıştırın',
  expired: 'Süresi Dolmuş',
  day: 'Gün',
  wallets_count: 'Cüzdan Sayısı',
  wallet_shares_count: 'Paylaşılan Cüzdan Sayısı',
  deposits: 'Kartla Yüklemeler',
  make_deposit: 'Yükleme Yap',
  manual_make_deposit: 'Manuel Yükleme Yap',
  cash_deposits: 'Nakit Yüklemeler',
  bank_transfers: 'Kurumsal Cüzdan Yüklemeleri',
  top_ups: 'Toplam Yükleme',
  payments: 'Harcanan Tutar',
  point_amounts: 'Kazanılan Puan',
  point_expenses: 'Harcanan Puan',
  point_balances: 'Kullanılabilir Puan',
  balances: 'Kalan Tutar',
  deposits_count: 'Yükleme İşlem Sayısı',
  payments_count: 'Harcama İşlem Sayısı',
  campaigns_usage_count: 'Kampanya Katılım Sayısı',
  is_available: 'Kullanılabilirlik',
  available: 'Kullanılabilir',
  constant_rate: 'Sabit Oran',
  schedule_report: 'Zamanlanmış Rapor',
  filter_column: 'Filtrelenecek Sutün',
  frequency: 'Gonderim Sıklığı',
  time: 'Saat',
  last_mail_time: 'Son Mail Gönderim Zamanı',
  apply_filter_in_panel: 'Filtreleri Panelde Uygula',
  company_filter: 'Şirket Filtresi',
  apply_company_filter_to_panel: 'Panele Şirket Filtresi Uygula',
  category: 'Kategori',
  select_user_groups: 'Kullanıcı Grubu Seçiniz',
  companies_count: 'Şirket Sayısı',
  remove_filter: 'Filtreyi Kaldır',
  altpay_share_percent: 'Macellan\'ın Karşılayacağı Yüzde',
  share_of_alteranatif: 'Macellan\'ın Payı',
  share_percent: 'Macellan\'in Payı',
  share_percent_warning_text: 'Macellan payında değişiklik yapmak üzeresiniz, bu değişiklik geçmiş kayıtları etkilemeyecek<br> yeni kampanya yararlanmaları için güncel Macellan payı geçerli olacaktır.<br> Bu değişikliği yapmak istediğinize emin misiniz?',
  share_amount: 'Paylaşılan Tutar',
  no_balance: 'Limit Yetersiz',
  remove: 'Kaldır',
  exclude_reports: 'Raporlara Dahil Etme',
  exclude_reports_coupon_description: 'Örneğin Şirket personellerine verilen kuponların raporları etkilemesini istemiyorsaniz bunu açabilirsiniz.',
  percent: 'Yüzde',
  preferences: 'Tercihler',
  summary_report: 'Özet Rapor',
  completed_at: 'Tamamlanma Tarihi',
  promotion: 'Promosyon',
  cash_deposit: 'Nakit Yükleme',
  topup: 'Yükleme Yap',
  topup_with_cash: 'Nakit ile Yükle',
  topup_with_credit_card: 'Kart İle Yükle',
  request: 'İstek',
  response: 'Yanıt',
  user_info: 'Kullanıcı Bilgisi',
  request_id: 'İstek ID',
  device_id: 'Cihaz ID',
  show_user_info_tooltip: 'Bu işlem sonucu kullanıcıya ait kişisel bilgiler görüntülenir. Bu işlem loglanarak kayıt altına alınır.',
  show_user_info: 'Kullanıcı Bilgisi Görüntüle',
  viewer: 'Talep Eden',
  viewed: 'Görüntülenen',
  wallet_share_description: 'WS Açıklama',
  viewer_id: 'Talep Eden ID',
  viewed_id: 'Görüntülenen ID',
  ips: 'Ip adresleri',
  consent_date: 'İzin Tarihi',
  recipient: 'Alıcı',
  receiver: 'Alıcı',
  receiver_full_name: 'Gönderilen Kişi',
  receiver_phone: 'Gönderilenin Telefonu',
  process_status: 'İşlem Durumu',
  sub_request_id: 'Alt İstek ID',
  sended: 'Gönderildi',
  not_send: 'Gönderilemedi',
  enqueue: 'Kuyrukta',
  failure: 'Hatalı',
  callback_url: 'Callback URL',
  ping_url: 'Ping URL',
  salt_key: 'Salt Key',
  store_key: 'Store Key',
  price: 'Fiyat',
  total_price: 'Toplam Fiyat',
  price_type: 'Fiyat Tipi',
  fix_price: 'Sabit',
  dynamic_price: 'Dinamik',
  price_service_url: 'Fiyat Servis URLi',
  test: 'Test',
  tag_qr_command_sent: 'Tag-QR: Komut gönderildi. Dönüş bekleniyor.',
  tag_qr_command_fail: 'Tag-QR: Cihazla bağlantı kurulamıyor.',
  tag_qr_price_service_is_ready: 'Tag-QR: Price Servis sorunsuz çalışıyor.',
  simple_edit: 'Basit Düzenle',
  related: 'İlişkili',
  unverified_email_message_first: 'E-Posta adresinizi doğrulayınız. Aksi halde {day_count} gün sonra sisteme erişiminiz kapatılacaktır.',
  unverified_email_message_second: ' "{link}" sayfasından doğrulama yapabilirsiniz.',
  verify_send_message: 'E-posta adresinize doğrulama maili gönderilmiştir. Lütfen gelen kutunuzu kontrol edin.',
  locale: 'Bölge',
  release_date: 'Yayın Tarihi',
  create_push_notification_news: 'Duyuru Oluştur',
  edit_push_notification_news: 'Duyuru düzenle',
  filters: 'Filtreler',
  balance_filter: 'Bakiye Filtresi',
  user_id_filter: 'Kullanıcı Id Filtresi',
  operator: 'Operator',
  greater_than: '>= (Büyük Eşittir)',
  less_than: '<= (Küçük Eşittir)',
  processed: 'İşlenme Durumu',
  push_notification_news_title: 'Duyuru Başlığı',
  push_notification_news_description: 'Duyuru Açıklaması',
  note: 'Not',
  users_excel_import: 'Kullanıcıları excelden aktar',
  formats: 'Formatlar',
  all_users: 'Tüm Kullanıcılar',
  excel: 'Excel',
  filter: 'Filtre',
  excel_operations: 'Excel İşlemleri',
  example_excel_download: 'Örnek aktarım dosyasını indirmek için',
  download: 'İndir',
  excel_close_window_alert: 'Excel bekleniyor. Çıkmak istediğinizden emin misiniz?',
  optional_perms: 'Opsiyonel Yetkiler',
  source: 'Kaynak',
  registered_at: 'Kayıt Tarihi',
  registered_full_name: 'Kayıt İsimi',
  user_registered: 'Kullanıcı Kayıtlı',
  tickets: 'Biletler',
  event_name: 'Etkinlik Adı',
  event_date: 'Etkinlik Tarihi',
  enter_event: 'Etkinlik giriniz...',
  is_gift: 'Hediye',
  ticket_system: 'Biletleme Sistemi',
  accountancy_entegration_settings: 'Muhasebe Entegrasyon Ayarları',
  select_accountancy_service: 'Muhasabe Servisi Seçin',
  add_accountancy_service: 'Muhasebe Servisi Ekle',
  delete_accountancy_service: 'Muhasebe Servisi Sil',
  key: 'Key',
  user_id: 'Kullanıcı ID',
  city: 'Şehir',
  enter_city: 'Şehir giriniz',
  district: 'İlçe',
  enter_district: 'İlçe giriniz',
  neighbourhood: 'Mahalle',
  neighborhood: 'Mahalle',
  street: 'Sokak',
  count: 'Sayı',
  gender: 'Cinsiyet',
  authorized_company: 'Yetkili Şirket',
  limited_company_select: 'Kısıtlamak İçin Şirket Seçin',
  company_select: 'Şirket Seçiniz.',
  birthday: 'Doğum Tarihi',
  password_change: 'Şifreniz değiştirilmiştir, lütfen yeni şifreniz ile giriş yapınız.',
  push_notification_news_user_count: 'Gönderim yapılacak kişi sayısı: {0}',
  show_user_count: 'Kişi sayısını görün',
  push_notification_news_image: 'Push Notification Modal Resmi',
  visibility: 'Görünürlük',
  push_notification_news_url: 'Push Notification Modal Url',
  deposit_earned_point: 'Yüklemeden Kazanılan Puan',
  bank_message: 'Banka Mesajı',
  card_refunded_amount: 'Karta İade Edilen',
  point_deducted_balance: 'Bakiyeden Düşülen Puan',
  total_refunded_amount: 'Toplam İade Edilen',
  deposit_amount: 'Yükleme Tutarı',
  deposit_refund_amount: 'İade Tutarı',
  net_amount: 'Net Tutar',
  box_office_no_wallet: 'Müşterinin {0} cüzdanı oluşturulmamış.',
  box_office_deposit_confirm: '{0} cüzdanına {1} {2} <b>{3}</b> yüklemesi yapmak istediğinizden emin misiniz?',
  box_office_anonymous_confirm: 'Anonim karta <b>{1}{0}</b> <b>{2}</b> yüklemesi yapmak istediğinizden emin misiniz?',
  box_office_coupon_confirm: '<b>{0}</b> isimli kupon uygulanarak <b>{1}</b> puan cüzdana eklenecektir. Emin misiniz?',
  box_office_payment_refund: 'Harcama İade Et',
  box_office_payment_refunded: 'Harcama İade Edildi',
  create_wallet_fail: 'Cüzdan oluşturulamadı.',
  wallet_created: 'Cüzdan oluşturuldu.',
  earns_points: 'Puan Kazanır',
  allow_point_usage: 'Puan Kullanımı',
  device_type: 'Cihaz Tipi',
  too_many_requests: 'Çok fazla istek gönderdiniz. Lütfen bekleyin.',
  cancelled: 'İptal Edildi',
  amount_change: 'Tutar Değişikliği',
  amount_to_be_covered: 'Karşılanacak Tutar',
  expenditure_type: 'Harcama Türü',
  campaign_or_coupon_name: 'Kampanya Adı/Kupon Adı',
  progress_payment_amount: 'Hakediş Tutarı',
  tax: 'KDV Tutarı',
  piece: 'Adet',
  trading_count: 'Yapılan İşlem Sayısı',
  commission_ratio: 'Komisyon Oranı',
  try_again_upload: 'Tekrar Yükle',
  send_verify_code: 'Doğrulama Kodu Gönder',
  verify_sms_sent: 'Doğruma için kullanıcıya SMS gönderildi.',
  verify_code: 'Doğrulama Kodu',
  register: 'Kayıt Et',
  anonymous: 'Anonim',
  anonymous_user: 'Anonim Kullanıcı',
  member: 'Üye',
  super_user: 'Süper Admin',
  super_viewer: 'Süper Viewer',
  organization_admin: 'Organization Admin',
  head_of_growth: 'Head Of Growth',
  growth_manager: 'Growth Manager',
  ecosystem: 'Ecosystem',
  company_admin: 'Şirket Yöneticisi',
  customer_service: 'Müşteri Temsilcisi',
  branch_admin: 'Şube Yetkilisi',
  box_office_agent: 'Gişe Personeli',
  arge: 'Arge',
  match_device: 'Cihaz Eşleştir',
  moderator: 'Moderator',
  customer_success_lead: 'Customer Success Lead',
  session_terminated: 'Oturum Sonlandırıldı',
  integration_keys: 'Entegrasyon Anahtarları',
  gizmo_store_id: 'Gizmo Store ID',
  phones: 'Telefon Numaraları',
  phone_validation_tooltip: 'Telefon numarası formatı ülke koduyla beraber yazılmalıdır.' +
    ' Eğer birden fazla numara girmek istenirse alt alta veya virgül ile ayırarak yazabilirsiniz.' +
    ' En fazla 10 numara girilebilir daha fazlası için lütfen excel içe aktarımı kullanın.',
  example: 'Örnek',
  next_step: 'Sonraki Adım',
  previous_step: 'Önceki Adım',
  commission_percent: 'Komisyon Yüzdesi',
  pos: {
    name: 'Pos Adı',
    client_id: 'Müşteri No',
    merchant_id: 'Mağaza No',
    store_key: 'Store Key',
    terminal_prov_user_id: 'Prov User',
    terminal_id: 'Terminal No',
    terminal_merchant_id: 'Mağaza No',
    posno: 'Pos No',
    mid: 'Mağaza No',
    tid: 'Terminal No',
    posnet_id: 'Posnet ID',
    merchant_key: 'Mağaza Parola',
    app_key: 'App Key',
    app_secret: 'App Secret',
    dealer_code: 'Bayi Kodu',
    client_code: 'Müşteri Kodu',
    guid: 'GUID',
    merchant_code: 'Mağaza Kodu',
    paycell_commission: 'Paycell Komisyon Oranı',
    service_commission: 'Macellan Komisyon Oranı',
    commission_is_belongs_to_company: 'Komisyon Ücretini Şirkete Yansıt',
    merchant_salt: 'Mağaza Gizli Anahtar',
  },
  date_time_picker: {
    month_names: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    day_names: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
  },
  info: {
    wallets_not_found: 'Aradığınız kriterlere uygun kurumsal cüzdan bulunamamıştır.',
    address_not_found: 'Girilen lokasyona ait adres bulunamadı.',
    release_date_not_found: 'Geçerli yayın tarihi bulunamadı.'
  },
  validation: {
    refunded_amount_must_be_less_than_amount: 'İade edilecek tutar {0}\'dan büyük olmamalıdır.',
    commission_must_be_between_zero_and_hundred: 'Komisyon 0 ile 100 arasında olmalıdır',
    selected_branch_has_qr_menu: '{0} şubesinin aktif qr menüsü bulunmakta',
    percent_must_be_between_zero_and_hundred: 'Yüzde 0 ile 100 arasında olmalıdır.',
    entered_price_must_be_lower_than_wallet_price: 'Girilen tutar en fazla {0} olabilir.',
    entered_point_must_be_lower_than_wallet_point: 'Girilen puan en fazla {0} olabilir.',
  },
  langs: {
    tr: 'Türkçe',
    en: 'İngilizce',
  },
  card_types: {
    credit: 'Kredi Kartı',
    debit: 'Debit Kart',
    prepaid: 'Prepaid Kart',
  },
  yes_no: {
    true: 'Evet',
    false: 'Hayır',
  },
  tooltip: {
    day_of_month: 'Raporun ayın hangi günü gönderileceğini temsil eder.',
    day_of_week: 'Raporun haftanın hangi günü gönderileceğini temsil eder.'
  },
  smartTable: smart_table,
  titles: titles,
  permission: permission,
  perm: {
    multiple_tap_card: {
      create: 'Çoklu Kart Oluşturma'
    },
    terminal: {
      cash_deposit: 'Terminal Nakit Yükleme',
    },
    'show_user_info': 'Müşteri Bilgilerini Göster',
    'show_amounts': 'Tutarları Göster',
    deposit: {
      refund: 'Yüklemenin İadesi',
    },
    cash: {
      balance: {
        transfer: 'Nakit Yükleme Bakiye Aktarımı',
      },
    },
    user: {
      create: {
        audience: 'İzleyici Rolü Oluşturma',
      }
    }
  },
  payment_id: 'Harcama ID',
  card_deactive_dialog_title: 'Kartı Pasife Alma',
  card_deactive_dialog_message: 'Kartı pasife almak istediğinizden emin misiniz?',
  device: 'Cihaz',
  date: 'Tarih',
  login_history: 'Giriş Geçmişi',
  noUserActivity: 'Gösterilecek bir aktivite bulunamadı.',
  show_more: 'Daha Fazla Göster',
  show_less: 'Daha Az Göster',
  permissions: 'İzinler',
  timeout_password: 'Güvenliğiniz açısından uzun zamandır kullandığınız şifrenizi değiştirmenizi öneririz.',
  invoice_address: 'Fatura Adresi',
  enter_invoice_address: 'Fatura Adresi giriniz',
  fax: 'Fax',
  enter_fax: 'Fax giriniz',
  tax_office: 'Vergi Dairesi',
  enter_tax_office: 'Vergi Dairesi giriniz',
  tax_number: 'Vergi Kimlik No',
  enter_tax_number: 'Vergi Kimlik No giriniz',
  contract_end_date: 'Sözleşme Bitiş Tarihi',
  general_information: 'Genel Bilgiler',
  documents: 'Belgeler',
  gallery: 'Galeri',
  company_documents: 'Şirket Belgeleri',
  address_info: 'Adres Bilgileri',
  prevBtnText: 'Geri',
  nextBtnText: 'İleri',
  gdpr_data_sharing: 'Ortak Veri Sorumlusu',
  user_group_add_user_success_message: 'İşlem başarılı. <br> Başarılı: {0} <br> Gruba Önceden Eklenmiş: {1} <br> Toplam: {2}',
  user_group_add_user_error_message: 'Aşağıdaki numaralar eklenemedi. <br> {0}',
  create_password_and_go_panel: 'Şifre Oluştur ve Panele Git',
  send_verify_email: 'Doğrulama Maili Gönder',
  email_unverify_suspended_user: 'Hesabızını 30 gün içinde doğrulamadığınız için hesabınız askıya alınmıştır. Hesabınızı tekrar aktif hale getirmek için mail adresinizi doğrulamanız gerekmektedir. Detaylı bilgi için sistem yöneticisine başvurabilirsiniz.',
  email_verify_count: 'E-Posta adresiniz sistemde doğrulanmamıştır. {day_count} gün içerisinde doğrulanmamış mail adresine sahip kullanıcıların panele erişimi kapatılacaktır. Detaylı bilgi için sistem yöneticisine başvurabilirsiniz.',
  send_magic_link_pass_info: 'Seçtiğiniz yetki türünde şifre belirleme için mail gönderilir.',
  send_magic_link_info: 'Kullanıcıya şifresini oluşturabilmesi için mail gönderilecektir.',
  send_magic_link: 'Sihirli Bağlantı Gönder',
  sent_magic_link: 'Sihirli bağlantı gönderildi.',
  item_not_found: 'Veri bulunamadı',
  my_sent: 'Benim Gönderdiğim',
  sent_to_me: 'Bana Gönderilen',
  iap: 'Uygulama İçi Satın Alma',
  relatable_type: 'İlişki Tipi',
  account_id: 'Hesap ID',
  licence_id: 'Lisans ID',
  store_id: 'Mağaza ID',
  active_kerzz_pos: 'Kerzz Pos Aktif Et',
  kerzz_pos_setting_description: 'Kerzz Pos ayarını aktif ettikten sonra LicenceId, AccountId, StoreId bilgilerini şube detaylarından ekleyebilirsiniz.',
  old_provider: 'Önceki Sağlayıcı',
  new_provider: 'Yeni Sağlayıcı',
  new_menu_url: 'Yeni Menu Url',
  paste_new_menu_url: 'Yeni Menu Url Yapıştırın...',
  two_factor_authentication_code: 'İki Faktörlü Kimlik Doğrulama Kodu',
  comment: 'Yorum',
  refund_summary: 'İade Özeti',
  balance_to_be_deducted: 'Cüzdandan Düşecek Bakiye',
  points_to_be_deducted: 'Geri Alınacak Puan',
  earned_points_from_deposit: 'Yüklemeden Kazanılan Puan',
  point_deducted_from_balance: 'İade Anında Bakiyeden Düşülecek Puan',
  point_spent: 'Harcadığı Puan',
  net_amount_to_be_refunded_to_card: 'Karta İade Edilecek Net Tutar',
  continue_refund_two_factor_message: 'İade işlemine devam edebilmek için lütfen iki faktörlü doğrulama veya kurtarma kodunu giriniz.',
  continue_to_transaction_two_factor_message: 'İşlemine devam edebilmek için lütfen iki faktörlü doğrulama veya kurtarma kodunu giriniz.',
  success_refund: 'İade işlemi başarılı bir şekilde gerçekleşti!',
  success_refund_request: 'İade talebiniz yöneticiye iletildi.',
  do_test: 'Test Yap',
  done_test: 'Yapılan Testler',
  test_virtual_pos: '{company} Şirketi İçin {name} Posunu Test Et',
  virtual_pos_running_test: 'Bu sanal pos şuan da {running_test_user} tarafından test ediliyor. Lütfen {timeout} dakika sonra tekrar deneyiniz.',
  phone_number_for_your_expected_login: 'Giriş yapmanız beklenen telefon numarası: {phone}',
  waiting_your_login: 'Giriş yapmanız bekleniyor...',
  waiting_your_select_gateway: 'Ödeme alt yapısı seçmeniz bekleniyor...',
  waiting_your_non_3d_pay: 'Non 3D ödeme yapmanız bekleniyor...',
  waiting_your_3d_pay: '3D ödeme yapmanız bekleniyor...',
  settlement_will_tested: 'Mutabakat test ediliyor...',
  refund_will_tested: 'İade test edililiyor...',
  login_success: 'Giriş Başarılı',
  login_skipped: 'Giriş Adımı Atlandı',
  selected_gateway: 'Ödeme Alt yapısı Seçildi',
  non_three_d_payment_result: 'Non 3D Ödeme Sonucu',
  three_d_payment_result: '3D Ödeme Sonucu',
  settlement_result: 'Mutabakat Sonucu',
  settlement_failed: 'Mutabakat Başarısız',
  settlement_success: 'Mutabakat Başarılı',
  wrong_deposits: 'Hatalı Yüklemeler',
  refund_result: 'İade Sonucu',
  successful_test: 'Başarılı Test',
  details_test: 'Test Detayları',
  start_test: 'Testi Başlat',
  reload_start_test: 'Testi Yeniden Başlat',
  saved_test: 'Test kayıt edildi.',
  failed_test: 'Test başarısız oldu lütfen tekrar deneyiniz.',
  maximum_non_3d_amount_this_wallet: 'Bu cüzdanda maksimum non 3D tutarı: {0}',
  skip_for_bkm: 'Test edilen pos BKM ile ödeme aldığı için bu adım atlanıyor.',
  skip_for_force_3d: 'Tüm yüklemelerde 3D zorunlu olduğu için bu adım atlanıyor.',
  skip_non_three_d_payment: 'Non 3D Ödeme Atlandı',
  skip_three_d_payment: 'Non 3D Ödeme Atlandı',
  test_timeout_info: 'Testi {0} dakika içerisinde tamamlamanız gerekemektedir. Eğer {0} dakika içerisinde testi tamamlayamazsanız, test başarısız olarak kaydedilecektir.',
  main_menu: 'Ana Menü',
  sub_menu: 'Alt Menü',
  own: '✓',
  devoid: '☓',
  select_accountancy_type: 'Muhasebe türü seçiniz...',
  payment: 'Harcama',
  top_up: 'Yükleme',
  skip: 'Atla',
  three_d_pay_skipped: '3D ödeme adımı atlandı.',
  deposit_refund: 'Yükleme İadesi',
  payment_refund: 'Harcama İadesi',
  deposit_fail: 'Hatalı Yükleme',
  categories_to_show: 'Gösterilecek Kategoriler',
  categories_count: 'Kategori Sayısı',
  region: 'Bölge',
  select_region: 'Bölge Seçiniz',
  usage_date: 'Kullanım Tarihi',
  used: 'Kullanılmış',
  point_detail: 'Puan Detayı',
  user_coupon: 'Müşteri Kuponu',
  expired_amount: 'Süresi Dolan Miktar',
  api: 'Api',
  other: 'Diğer',
  dcb: 'DCB',
  direct_refund_api: 'Direct Refund API',
  deposit: 'Bakiye',
  history: 'Geçmiş',
  help: 'Yardım',
  agreement: 'Sözleşme',
  features_sort: 'Özellik Sıralaması',
  waas_features_sort: 'Waas Özellik Sıralaması',
  default_sort: 'Varsayılana Sırala',
  general: 'Genel',
  onesignal_prompt_text: 'Yönetici paneli kapalı bile olsa bildirim almak ister misiniz?',
  operator_user: 'İşleme Alan',
  operator_action_date: 'İşlem Tarihi',
  box_office_deposit_refund_dialog_message: 'İade işlemini yapmak istediğinize emin misiniz?',
  cash_deposit_refund_approve_dialog_message: 'İadeyi onaylamak istediğinize emin misiniz?',
  cash_deposit_refund_reject_dialog_message: 'İadeyi reddetmek istediğinize emin misiniz?',
  pay_tr_setup_info: 'PayTR Sanal Pos Nasıl Tanımlanır?',
  info_document: 'Bilgilendirme Dökümanı',
  virtual_pos_form_warning_message: 'Bu şirkete ait, aktif sanal pos bulunduğu için, sanal posun durumu pasif olarak kaydedildi.',
  psp: 'Ödeme Hizmeti Sağlayıcısı',
  log_detail: 'Log Detayı',
  skipped: 'Atlandı',
  participation_terms: 'Katılım Koşulları',
  background_image: 'Arka Plan Resmi',
  confirm_reset_password: 'Şifrenizi sıfırlamak için <b>{0}</b> adresine şifre sıfırlama bağlantısı gönderilecektir. <br /> Emin misiniz?',
  send_password_reset_link: 'E-posta adresinize şifre sıfırlama maili gönderilmiştir. Lütfen gelen kutunuzu kontrol edin.',
  apply_coupon: 'Kupon Uygula',
  parent_info: 'Ebeveyn Bilgileri',
  child_info: 'Çocuk Bilgileri',
  has_active_soft_play: 'SoftPlay Aktif',
  package_info: 'Paket Bilgileri',
  code_info: 'Kod Bilgileri',
  birth_date: 'Doğum Tarihi',
  size: 'Boy',
  weight: 'Kilo',
  waiting_entrance: 'Giriş Bekleniyor',
  approve_entrance: 'Girişi Onayla',
  take_a_break: 'Mola Ver',
  return_a_break: 'Moladan Geri Dön',
  exit: 'Çıkış Yap',
  in_zone: 'Alanda',
  force_exit: 'Otomatik Çıkartıldı',
  invalid_code: 'Geçersiz Kod',
  enter_code_for_entrance_or_exit: 'Alana Giriş/Çıkış Kodunu Giriniz',
  enter_child_code: 'Çocuk Kodunu Giriniz',
  add_or_delete_package: 'Paket Ekleme/Çıkarma',
  hour: 'Saat',
  minute: 'Dakika',
  unit_of_time: 'Zaman Birimi',
  child_name: 'Çocuk Adı',
  parent_name: 'Ebeveyn Adı',
  parent_full_name: 'Ebeveyn Adı Soyadı',
  parent_phone: 'Ebeveyn Telefon',
  second_parent_name: 'İkinci Ebeveyn Adı',
  second_parent_phone: 'İkinci Ebeveyn Telefon',
  remaining_time: 'Çıkış Zamanı',
  soft_play: 'Soft Play',
  is_take_in_manually: 'Manuel İçeri Alındı',
  soft_play_agent_name: 'Soft Play Görevlisi',
  action: 'Aksiyon',
  soft_play_code: 'Soft Play Kodu',
  paid_by: 'Ödeme Yapan Kişi',
  manuel_exit_info: '<b>{child_name}</b> isimli çocuğun alandan çıkışı yapılacaktır. <br /> Lütfen ebeveyn ismini kontrol edin, doğru ebeveyne teslim edildiğinden emin olun ve çıkış işlemini onaylayın.',
  control_and_accept: 'Kontrol ettim, onaylıyorum.',
  payment_transfer_transaction_start_date: 'Harcama Başlangıç Tarihi',
  payment_transfer_transaction_end_date: 'Harcama Bitiş Tarihi',
  payment_refund_amount: 'Harcama İade Tutarı',
  cash_deposit_amount: 'Nakit Yükleme Tutarı',
  cash_deposit_refund_amount: 'Nakit İade Tutarı',
  payment_transfer_current_debt_amount: 'Genel Merkez Alacak Tutarı',
  process_start_date: 'İşlem Başlangıç Tarihi',
  process_end_date: 'İşlem Bitiş Tarihi',
  sipay_payment_transfer: 'Sipay Harcama Transferi',
  make_active: 'Aktif Et',
  make_include: 'Dahil Et',
  spending_points: 'Harcama Puanı',
  identifier: 'Cihaz ID',
  location: 'Konum',
  brand: 'Marka',
  show_total_usage: 'Toplam Kullanım: {0}',
  trusted_ips: 'Güvenilir Ipler',
  trusted_ips_tooltip: 'Bu bölüme girilen ipler, bu tokenın sadece belirlediğiniz ipler üzerinden kullanılabilir olmasını sağlar.' +
    'Bu bölümü boş bırakmanız halinde ip kısıtlaması yapılmaz. ' +
    'Eğer birden fazla ip numarası girmek istenirse alt alta veya virgül ile ayırarak yazabilirsiniz.',
  new_corporation_record: 'Yeni Kurum Kaydı*',
  update_corporation_record: 'Kurum Kaydını Güncelle',
  amusement_events_park: 'Eğlence Parkı Etkinlik',
  amusement_events_count: 'Etkinlik Sayısı',
  amusement_events_settings: 'Eğlence Parkı Etkinlik Ayarları',
  minimum_commission_amount: 'Minimum Birim Hizmet Bedeli',
  minimum_commission_amount_description: 'Bir etkinlikte kullanılan her bir kart için alınan birim hizmet bedelidir.',
  visitor_count: 'Ziyaretçi Sayısı',
  point_to_load: 'Yüklenen Puan',
  total_amount: 'İşletmeye Ödenen Tutar',
  discount_rate: 'İndirim Oranı',
  corporation_name: 'Kurum Adı',
  associated_corporation: 'Bağlı Kurum',
  select_corporation: 'Kurum Adı Girmeye Başlayın...',
  amount_to_be_paid: 'İşletmeye Ödenecek Tutar',
  minimum_unit_service_fee: 'Minimum Birim Hizmet Bedeli',
  enter_price: 'Tutar Girin...',
  enter_count: 'Sayı Girin...',
  point_to_load_card: 'Karta Yüklenecek Puan',
  multiple_card_created_with_number: 'Numaralı Kart Oluşturuldu',
  multiple_card_created: 'Kart Oluşturuldu',
  enter_point: 'Puan Girin...',
  deactivate_alert: '<b>Pasife çekmek</b> istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  multiple_card: 'Çoklu Kart',
  create_multiple_card: 'Çoklu Kart Tanımlama',
  definition: 'Tanımlama',
  corporation: 'Kurum',
  point_expiration_type: 'Puan Son Kullanım Tipi',
  fixed_date: 'Sabit Tarih',
  point_days_count: 'Puan Gün Sayısı',
  point_days_count_description: 'Buraya girilen değer ile kazanılan puanın, kampanyaya katılımdan itibaren kaç gün sonrasına kadar harcanacağı ayarlanır.',
  branch_attribute_open_two_factor_message: 'Bu alandaki bilgileri güncelleyebilmek için lütfen Profilim sayfasından iki faktörlü kimlik doğrulamayı aktif ediniz.',
  enter_webhook_url: 'Webhook url giriniz',
  webhook_tooltip: 'Belirli bir olay gerçekleştiğinde, ilgili şirket sistemini HTTP isteği ile bildiren bir mekanizmadır.',
  waas_hide_in_other_apps: 'SuperApp uygulamasında gösterme',
  waas_user_email_required: 'Kullanıcı E-Posta Zorunluluğu',
  package_settings: 'Paket Ayarları',
  consecutive_package_usage_minutes_limit: 'Arka arkaya paket kullanım dakika limiti',
  create_device: 'Cihaz Oluştur',
  edit_device: 'Cihazı Düzenle',
  campaign_target_group: 'Hedef Kitle',
  campaign_target_group_personal_users: 'Üye Kullanıcılar',
  campaign_target_group_user_group: 'Kullanıcı Grubu',
  campaign_target_group_anonymous_user_phone_unverified: 'Telefon Doğrulamayan Anonim Kullanıcılar',
  campaign_target_group_anonymous_user_phone_verified: 'Telefon Doğrulayan Anonim Kullanıcılar',
  sender_full_name: 'Gönderen',
  sender_phone: 'Gönderenin Telefonu',
  sender_description: 'Gönderen Açıklama',
  product_name: 'Ürün İsmi',
  accountancy_product_name: 'Faturada görünecek ürün ismini girmeniz gerekmektedir. Hizmet Bedeli, İşlem Bedeli, Yükleme Bedeli vb.',
  change: 'Değiştir',
  update_phone: 'Telefon Numarası Değiştirme',
  new_phone: 'Yeni Telefon Numarası',
  update_phone_direct_info: 'Telefon numarası doğrudan değişecektir.',
  update_phone_verify_code_info: 'Telefon numarası sahibini doğrulamanız gerekmektedir.',
  send_otp_for_number: '{0} numarasına doğrulama kodu gönder.',
  attention: 'Dikkat !',
  wallet_visibility: 'Cüzdan Gizliliği',
  manual_payment: 'Manuel Harcama Oluştur',
  complete_payment: 'Harcamayı Tamamla',
  continue_user_verify: 'Kullanıcı Doğrulama Adımına Geç',
  user_verify: 'Kullanıcı Doğrulama',
  spending_count: 'Harcama Sayısı',
  spending_price: 'Harcanan Bakiye',
  payments_total: 'Harcamalar Toplam Tutar',
  deposits_total: 'Yüklemeler Toplam Tutar',
  branch_payments_graphic: 'Şube Harcamaları (Grafik)',
  branch_payments_table: 'Şube Harcamaları (Tablo)',
  box_office_payments_graphic: 'Gişe Harcamaları (Grafik)',
  box_office_payments_table: 'Gişe Harcamaları (Tablo)',
  tag_qr_payments_graphic: 'Tag QR Harcamaları (Grafik)',
  tag_qr_payments_table: 'Tag QR Harcamaları (Tablo)',
  deposit_cash_amounts_graphic: 'Nakit Yükleme Tutarları (Grafik)',
  deposit_cash_amounts_table: 'Nakit Yükleme Tutarları (Tablo)',
  branch_detail_payments_table: 'Şube Detay Harcamaları (Tablo)',
  branch_detail_payments_graphic: 'Şube Detay Harcamaları (Grafik)',
  cashier_report_graphic: 'Gişeci Raporu (Grafik)',
  cashier_report_table: 'Gişeci Raporu (Tablo)',
  remaining_points: 'Kalan Puan',
  remaining_total_balance: 'Kalan Toplam Tutar',
  all: 'Tümü',
  payment_count: 'Harcama Sayısı',
  tag_qr: 'Tag QR',
  panel_tag_qr_id: 'Tag QR ID',
  no_branch_found: 'Şube Bulunamadı',
  no_company_found: 'Şirket Bulunamadı',
  no_category_found: 'Kategori Bulunamadı',
  no_organization_found: 'Organizasyon Bulunamadı',
  no_region_found: 'Bölge Bulunamadı',
  wallet_share_id: 'Cüzdan Paylaşımı ID',
  edit_child: 'Çocuğu Düzenle',
  create_child: 'Çocuk Oluştur',
  payment_success: 'Ödeme Başarılı',
  take_in: 'İçeri Al',
  take_in_success: 'Başarıyla İçeri Alındı',
  redirecting: 'Yönlendiriyor',
  optional: 'Opsiyonel',
  soft_play_secondary_guardian_info: 'Çocuğundan sorumlu ikinci kişi bilgilerini giriniz',
  minute_left_to_notify: 'Bildirim Dakikası',
  break_minute: 'Mola Süresi (Dakika)',
  add_document: 'Döküman Ekle',
  deposit_control_url: 'Yükleme Kontrol Servisi',
  deposit_control_url_description: 'İlgili şirketin yükleme kontrol servisi ile kullanıcıların yükleme işlemi öncesi yüklemeyi yapıp yapamayacağı belirlenir.',
  enter_webhook_secret: 'Secret key giriniz',
  webhook_secret_tooltip: 'Webhook isteğinin kaynağını doğrulamak ve yetkisiz erişimi engellemek için kullanılan bir güvenlik anahtarıdır.',
  account_activity: 'Hesap Hareketleri',
  ask_create_campaign_news: 'Kampanya duyurusu oluşturmak ister misiniz?',
  company_id: 'Şirket ID',
  has_active_commission: 'Aktif Komisyonu Var',
  reports_emptystate: 'Raporlara ulaşmak için şirket seçimi yapmanız gerekmektedir',
  box_office_reports_emptystate: 'Gişe raporlarına ulaşmak için şirket seçimi yapmanız gerekmektedir',
  reports: {
    other_info: 'Şube bağımsız harcamaları temsil eder. Giftcard, bakiye yakma vs.',
    emptystate: {
      payments: 'Seçilen tarih aralığında harcama bulunamamaktadır.',
      deposit: 'Seçilen tarih aralığında yükleme bulunamamaktadır.',
      box_office: 'Seçilen tarih aralığında gişe işlemi bulunamamaktadır.',
    },
    titles: {
      payment: 'Harcamalar',
      deposit: 'Yüklemeler',
      box_office: 'Gişe',
    },
    name: 'Gişe Adı',
    amount: 'Yükleme Tutarı',
    branch: 'Şube',
    tag_qr: 'Tag QR',
    final_total: 'Toplam Harcanan',
    payment_count: 'Harcama Sayısı',
    point: 'Harcanan Puan',
    price: 'Harcanan Bakiye',
    mobile: 'Mobil Harcama',
    tap_card: 'Kartla Harcama',
    balances: 'Kalan Bakiye',
    deposit: 'Mobil Yükleme',
    cash_deposit: 'Nakit Yükleme',
    credit_card_deposit: 'Kredi Kartı ile Yükleme',
    mobile_final_total: 'Toplam Mobil Harcanan Tutar',
    mobile_payment_count: 'Mobil Harcama Sayısı',
    mobile_point: 'Mobil Harcanan Puan',
    mobile_price: 'Mobil Harcanan Bakiye',
    tap_card_final_total: 'Toplam Kartla Harcanan Tutar',
    tap_card_payment_count: 'Kartla Harcama Sayısı',
    tap_card_point: 'Kartla Harcanan Puan',
    tap_card_price: 'Kartla Harcanan Bakiye',
    refund_amount: 'İade Tutarı',
    cashier_name: 'Kasiyer Adı',
    method: 'Ödeme Metodu',
    total_cash_deposit_amount: 'Yüklenen Bakiye',
    total_refund_amount: 'İade Edilen Tutar',
    diff_amount: 'Kalan Bakiye',
    total_tap_card_deposit_amount: 'Alınan Kart Depozito Tutarı',
    total_tap_card_deposit_refund_amount: 'İade Edilen Kart Depozito Tutarı',
  },
  mobile_final_total: 'Toplam Mobil Harcanan Tutar',
  mobile_payment_count: 'Mobil Harcama Sayısı',
  mobile_point: 'Mobil Harcanan Puan',
  mobile_price: 'Mobil Harcanan Bakiye',
  tap_card_final_total: 'Toplam Kartla Harcanan Tutar',
  tap_card_payment_count: 'Kartla Harcama Sayısı',
  tap_card_point: 'Kartla Harcanan Puan',
  tap_card_price: 'Kartla Harcanan Bakiye',
  cash_balance_transfer: 'Nakit Yükleme Bakiye Aktarımı',
  balance_transfer: 'Bakiye Aktarımı',
  reboot: 'Reboot',
  reboot_dialog_title: 'Cihazı Reboot Et',
  reboot_dialog_message: 'Cihaz reboot edilsin mi?',
  set_defaults: 'Varsayılanları Ata',
  set_defaults_dialog_title: 'Varsayılan Configleri Ata',
  set_defaults_dialog_message: 'Varsayılan configleri atama işlemi, markaya özel tanımlanan varsayılan configler varsa bile es geçerek varsayılan tanımlı configleri cihaza atar. Buna emin misiniz?',
  sync: 'Senkronize Et',
  sync_dialog_title: 'Cihazı Senkronize Et',
  sync_dialog_message: 'Senkronize sonucundaki değişiklikler cihazın 1 dakika içinde update almasına sebep olur.',
  sync_success: 'Cihaz başarılı bir şekilde senkronize edildi.',
  card_deposit_panel: {
    not_refunded_amount: 'Kalan Depozito Tutarı',
    not_refunded_count: 'İade Alınmayan Kart Sayısı',
    refunded_amount: 'İade Edilen Depozito Tutarı',
    refunded_count: 'İade Alınan Kart Sayısı',
    total_amount: 'Toplam Depozito Tutarı',
    total_count: 'Depozito Alınan Toplam Kart Sayısı',
    choose_company_and_branch: 'Depozito verilerine ulaşmak için şirket ve şube seçimi yapmanız gerekmektedir',
  },
  products: 'Ürünler',
  whatsapp: 'Whatsapp Destek Hattı',
  period: 'Periyot',
  day_based: 'Gün Bazlı',
  remaining_time_softplay: 'Kalan Süre',
  name_surname: 'Ad Soyad',
  finish: 'Bitti',
  extend_time: 'Süreyi Uzat',
  no_matching_child: 'Uygun çocuk bulunamadı.',
  has_installment: 'Taksit Kullanımı',
  installment_count: 'Taksit Sayısı',
  invite_friend_settings: 'Arkadaşını Davet Et Ayarları',
  max_invites: 'Maksimum Davet Etme Sayısı',
  min_deposit: 'Yükleme Alt Limiti',
  invite_from_point: 'Davet Eden Kazanacağı Puan',
  invite_to_point: 'Davetli Kazanacağı Puan',
  status_invite_your_friend_info: 'Arkadaşını davet et özelliğini yönetmek için kullanılır. Bu ayar kapatılırsa arkadaşını davet et özelliği tamamiyle kapatılacaktır. Kazanılan haklar (kupon veya puan) kullanılmaya devam edecektir.',
  money_status_invite_your_friend_info: 'Bu para birimi için arkadaşını davet et özelliğini yönetmek için kullanılır.',
  invite_from_max_invites_info: 'Davet edenin maksimum davet edebileceği ve kupon kodu kazanabileceği kişi sayısını belirtir. Eğer bu sayının üzerine çıkarsa davetli puan kazanır ama davet eden herhangi bir şey kazanmaz.',
  invite_from: 'Davet Eden',
  invite_to: 'Davetli',
  coupon_expiry_day: 'Kupon Son Kullanma Günü',
  point_expiry_day: 'Puan Son Kullanma Günü',
  coupon_expiry_day_info: 'Kupon kazanıldıktan sonra kaç gün içerisinde yüklenebileceğini belirtir.',
  point_expiry_day_info_for_invite_friend: 'Kupon kazanıldıktan ve yükleme yapıldıktan sonra kazanılan puan kaç gün içerisinde tüketilebileceğini belirtir.',
  invite_from_earn_coupon: 'Davet Eden Kupon Kazanımı',
  invite_from_used_coupon: 'Davet Eden Kupon Kullanımı',
  invite_to_earn_coupon: 'Davetli Kupon Kazanımı',
  invite_to_used_coupon: 'Davetli Kupon Kullanımı',
  earn_coupon: 'Kupon Kazanımı',
  used_coupon: 'Kupon Kullanımı',
  invite_from_earn_point: 'Davet Eden Puan Kazanımı',
  invite_to_earn_point: 'Davetli Puan Kazanımı',
  invite_from_point_detail: 'Davet Eden Puan Detayı',
  invite_to_point_detail: 'Davetli Puan Detayı',
  invite_to_phone: 'Davetli Telefon',
  invite_to_status: 'Davetli Durum',
  invited_date: 'Davet Tarihi',
  minimum_masterpass_otp_amount: 'Minimum Masterpass Otp Tutarı',
  minimum_masterpass_otp_amount_description: 'Yüklemelerde bu değer ve daha yüksek bir tutar girildiğinde, ödeme Masterpass OTP ile gerçekleşir.',
  create_branches_area_boundary: 'Şube Sorumluluk Alanı Oluştur',
  edit_branches_area_boundary: 'Şube Sorumluluk Alanı Düzenle',
  select_city: 'İl Seçiniz',
  no_city_found: 'İl Bulunamadı',
  select_district: 'İlçe Seçiniz',
  no_district_found: 'İlçe Bulunamadı',
  select_neighborhood: 'Mahalle Seçiniz',
  no_neighborhood_found: 'Mahalle Bulunamadı',
  waas: 'Waas',
  order: 'Sipariş',
  address_delivery: 'Adrese Teslim',
  onsite_delivery: 'Yerinde Teslim',
  creator_email: 'Oluşturan E-Mail',
  delivery_type: 'Teslimat Tipi',
  order_no: 'Sipariş No',
  product_img: 'Ürün Resmi',
  product_count: 'Ürün Miktarı',
  user_address: 'Teslimat Adresi',
  branch_address: 'Şube Adresi',
  order_details: 'Sipariş Detayı',
  full_address: 'Adres',
  directions: 'Tarif',
  delivery_at: 'Teslimat Tarihi',
  last_order_date: 'Önceki Sipariş Tarihi',
  last_order_diff: 'Önceki Sipariş - Geçen Gün',
  variation_name: 'Varyasyon',
  qty: 'Miktar',
  onsite: 'Yerinde Teslim',
  order_received: 'Sipariş Alındı',
  preparing: 'Hazırlanıyor',
  on_the_way: 'Yola Çıktı',
  at_address: 'Adreste',
  ready_at_site: 'Mağazada Hazır',
  delivered: 'Teslim Edildi',
  undelivered: 'Teslim Edilemedi',
  change_order_status: 'Sipariş Durumunu Değiştir',
  oder_status_update: 'Sipariş durumunu <strong>{0}</strong> olarak değiştirmek istediğinize emin misiniz?',
  order_cancel: 'Sipariş İptali',
  confirm_order_cancel: 'Siparişi iptal etmek istediğiniz emin misiniz?',
  confirm_order_cancel_desc: 'Siparişi iptal ettiğinizde yapılan harcama tamamen iade edilecek ve sipariş iptal edilecektir.',
  leave_at_door: 'Kapıya Bırak',
  do_not_ring_bell: 'Zile Basma',
  delivery_city: 'Teslimat İli',
  delivery_district: 'Teslimat İlçe',
  delivery_neighborhood: 'Teslimat Mahalle',
  delivery_street: 'Teslimat Sokak/Cadde',
  delivery_full_address: 'Teslimat Adresi',
  transaction_count: 'İşlem Adedi',
  eftpos_serial_number: 'ÖKC Sicil Numarası',
  enter_eftpos_serial_number: 'ÖKC Sicil Numarası Giriniz',
  max_serial_number_length: 'Maksimum Değer Sayısına Ulaştınız!',
  serial_numbers: 'Sicil Numara',
  eftpos_final_total: 'Genel Toplam',
  total_point: 'Toplam Puan',
  total_payment_refund_count: 'Toplam Harcama İadesi Sayısı',
  total_payment_refund_amount: 'Toplam Harcama İadesi Tutarı',
  courier: 'Kurye',
  star: 'Yıldız',
  related_record: 'İlgili Kayıt',
  reviewable_type: 'İlişki Tipi',
  branch_description: 'Şube Açıklaması',
  payment_refunded_point: 'İade Edilen Puan',
  refund_point: 'İade Edilen Puan',
  create_news: 'Duyuru Oluştur',
  news_title: 'Duyuru Başlığı',
  news_image: 'Duyuru Görseli',
  company_news_branches_count: 'İlgili Şube Sayısı',
  show_max_hide_amount: 'Gösterilebilecek Maksimum Tutar: {0}',
  audience_role_set_amount: 'İzleyici Rolü Tutar Belirleme',
  hide_transaction_not_found: 'Seçilen tarih aralığında işlem bulunamadı. Lütfen tarih aralığını yeniden belirleyin.',
  hide_transaction_confirm_message: 'Yükleme İşlem Tutarı: <strong>{0}</strong> <br>' +
    'Harcama İşlem Tutarı: <strong>{1}</strong> <br>' +
    'Nakit Yükleme İşlem Tutarı: <strong>{2}</strong> <br>' +
    'tutarindaki işlemler kullanıcıya gösterilecektir.',
  audience: 'İzleyici',
  discounted_top_up: 'İndirimli Yükleme Yap',
  tag_qr_name: 'Tag QR',
  tag_qr_connected: 'TagQR Var',
  uptime: 'Çalışma Süresi',
  last_message_date: 'Son Mesaj',
  connection_time: 'Bağlanma Zamanı',
  model: 'Model',
  cash_deposit_settings: 'Nakit Yükleme Ayarları',
  payment_settings: 'Harcama Ayarları',
  min_amount: 'Minimum Tutar',
  max_amount: 'Maksimum Tutar',
  new_value: 'Yeni Değer',
  old_value: 'Eski Değer',
  connected: 'Bağlandı',
  disconnected: 'Bağlantı Yok',
  payment_point: 'Harcanan Puan',

}
