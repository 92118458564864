<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>{{ excel.title }} {{ $t('excel_export') }}</p>
        <b-progress
          v-if="! excel.complete"
          type="is-info"
          :value="excel.percent"
          show-value
          format="percent"
        />

        <b-button
          v-if="excel.complete" size="is-small" type="is-success" expanded
          :href="excel.link" download tag="a"
        >
          {{ $t('download') }}
        </b-button>

        <b-button v-if="excel.fail" size="is-small" type="is-danger" expanded>{{ $t('error') }}</b-button>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'AsideExcelItem',
  props: {
    excel: {
      type: Object,
      default: null,
    },
  }
}
</script>
