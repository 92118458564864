import { i18n } from '@/helpers/i18n'
import router from '@/router'
import Perm from '@/helpers/perm'

const getRouter = (name) => {
  const route = router.resolve({ name: name })?.route
  return route ? route : '/404'
}

export default [
  {
    menu: [
      {
        to: getRouter('dashboard'),
        label: i18n.t('titles.dashboard'),
        icon: 'view-dashboard-variant',
      },
      {
        to: getRouter('reports'),
        label: i18n.t('titles.reports'),
        icon: 'chart-bar',
      },
      {
        to: getRouter('box_office_report'),
        label: i18n.t('titles.box_office_report'),
        icon: 'chart-timeline',
      },
    ],
  },
  {
    title: i18n.t('titles.deposits'),
    menu: [
      {
        to: getRouter('deposits'),
        label: i18n.t('titles.deposits'),
        icon: 'download-multiple',
      },
      {
        to: getRouter('card_deposits_dashboard'),
        label: i18n.t('titles.card_deposits_dashboard'),
        icon: 'card-multiple',
      },
      {
        to: getRouter('cash_deposits'),
        label: i18n.t('titles.cash_deposits'),
        icon: 'account-cash',
      },
      {
        to: getRouter('cash_deposit_refunds'),
        label: i18n.t('titles.cash_deposit_refunds'),
        icon: 'cash-refund',
      },
      {
        to: getRouter('box_office'),
        label: i18n.t('titles.box_office'),
        icon: 'account-credit-card',
      },
      {
        to: getRouter('bank_transfers'),
        label: i18n.t('titles.bank_transfers'),
        icon: 'cash-multiple',
      },
      {
        to: getRouter('predefined_deposits'),
        label: i18n.t('titles.predefined_deposits'),
        icon: 'wallet-plus-outline',
      },
      {
        to: getRouter('deposit_refunds'),
        label: i18n.t('titles.refunds'),
        icon: 'credit-card-refund-outline',
      },
      {
        to: getRouter('deposit_fails'),
        label: i18n.t('titles.deposit_fails'),
        icon: 'message-alert',
      },
    ],
  },
  {
    title: i18n.t('titles.payments'),
    menu: [
      {
        to: getRouter('payments'),
        label: i18n.t('titles.payments'),
        icon: 'upload-multiple',
      },
      {
        to: getRouter('payment_refund_requests'),
        label: i18n.t('titles.refund_requests'),
        icon: 'cash-refund',
      },
      {
        to: getRouter('tag_qr'),
        label: i18n.t('titles.tag_qr'),
        icon: 'qrcode-scan',
      },
      {
        to: getRouter('orders'),
        label: i18n.t('titles.orders'),
        icon: 'basket',
      },
      {
        to: getRouter('soft_play_qrs'),
        label: i18n.t('titles.soft_play_qrs'),
        icon: 'slide',
      },
    ],
  },
  {
    title: i18n.t('titles.corporations'),
    menu: [
      {
        to: getRouter('corporations'),
        label: i18n.t('titles.corporations'),
        icon: 'office-building',
      },
    ],
  },
  {
    title: i18n.t('titles.amusement_events'),
    menu: [
      {
        to: getRouter('amusement_events'),
        label: i18n.t('titles.amusement_events'),
        icon: 'calendar-star',
      },
      {
        to: getRouter('upcoming_amusement_events'),
        label: i18n.t('titles.upcoming_amusement_events'),
        icon: 'calendar-star',
      },
    ],
  },
  {
    title: i18n.t('titles.wallet_transactions'),
    menu: [
      {
        to: getRouter('wallets'),
        label: i18n.t('titles.wallets'),
        icon: 'wallet',
      },
      {
        to: getRouter('gift_cards_list'),
        label: i18n.t('titles.gift_cards'),
        icon: 'wallet-giftcard',
      },
      {
        to: getRouter('wallet_shares_list'),
        label: i18n.t('titles.wallet_sharing'),
        icon: 'account-arrow-right',
      },
      {
        to: getRouter('balance_history'),
        label: i18n.t('titles.balance_history'),
        icon: 'history',
      },
      {
        to: getRouter('point_history'),
        label: i18n.t('titles.point_history'),
        icon: 'clipboard-text-clock',
      },
    ],
  },
  {
    title: i18n.t('titles.moderator'),
    menu: [
      {
        to: getRouter('moderator_wallets'),
        label: i18n.t('titles.wallets'),
        icon: 'wallet',
      },
      {
        to: getRouter('moderator_documents'),
        label: i18n.t('titles.moderator_documents'),
        icon: 'file-document-multiple',
      },
      {
        to: getRouter('moderator_balance_history'),
        label: i18n.t('titles.balance_history'),
        icon: 'history',
      },
    ],
  },
  {
    title: i18n.t('titles.user_management'),
    menu: [
      {
        to: getRouter('personal_users'),
        label: i18n.t('titles.personal_users'),
        icon: 'account-multiple',
      },
      {
        to: getRouter('user_groups'),
        label: i18n.t('titles.personal_user_groups'),
        icon: 'account-group',
      },
      {
        to: getRouter('potential_users'),
        label: i18n.t('titles.potential_users'),
        icon: 'account-clock',
      },
      {
        to: getRouter('invite_friend'),
        label: i18n.t('titles.invite_friend'),
        icon: 'account-switch',
      },
      {
        to: getRouter('static_qr'),
        label: i18n.t('titles.static_qr'),
        icon: 'qrcode-scan',
      },
      {
        to: getRouter('tickets'),
        label: i18n.t('titles.tickets'),
        icon: 'ticket',
      },
      {
        to: getRouter('moderator_applications'),
        label: i18n.t('titles.moderator_applications'),
        icon: 'account-multiple-check',
      },
      {
        to: getRouter('users'),
        icon: 'account-supervisor',
        label: i18n.t('titles.users'),
      },
      {
        to: getRouter('permission_list'),
        icon: 'account-check',
        label: i18n.t('titles.permission_matrix'),
      },
    ],
  },
  {
    title: i18n.t('titles.marketing'),
    menu: [
      {
        to: getRouter('coupons'),
        label: i18n.t('titles.coupons'),
        icon: 'sale',
      },
      {
        to: getRouter('campaigns'),
        label: i18n.t('titles.campaigns'),
        icon: 'bullhorn',
      },
      {
        to: getRouter('campaign_news'),
        label: i18n.t('titles.campaign_news'),
        icon: 'newspaper-variant-multiple',
      },
      {
        to: getRouter('company_news'),
        label: i18n.t('titles.company_news'),
        icon: 'newspaper-variant',
      },
      {
        to: getRouter('push_notification_news'),
        label: i18n.t('titles.push_notification_news'),
        icon: 'bell',
      },
    ],
  },
  {
    title: i18n.t('titles.company_descriptions'),
    menu: [
      {
        to: getRouter('organizations'),
        label: i18n.t('titles.organizations'),
        icon: 'sitemap',
      },
      {
        to: getRouter('companies'),
        label: i18n.t('titles.companies'),
        icon: 'domain',
      },
      {
        to: getRouter('virtual_pos'),
        label: i18n.t('titles.virtual_pos'),
        icon: 'contactless-payment',
        updateMark: false,
      },
      {
        to: getRouter('branches'),
        label: i18n.t('titles.branches'),
        icon: 'source-branch',
      },
      {
        to: getRouter('agreements'),
        label: i18n.t('titles.agreements'),
        icon: 'text-box-multiple',
      },
    ],
  },
  {
    title: i18n.t('titles.blog'),
    menu: [
      {
        to: getRouter('blog_categories'),
        label: i18n.t('titles.blog_category_list'),
        icon: 'shape',
      },
      {
        to: getRouter('blog_post_list'),
        label: i18n.t('titles.blog_post_list'),
        icon: 'post',
      },
    ],
  },
  {
    title: i18n.t('titles.catalog'),
    menu: [
      {
        to: getRouter('qr_menu'),
        label: i18n.t('titles.qr_menu_classic'),
        icon: 'qrcode',
      },
      {
        to: {
          path: import.meta.env.VITE_QR_MENU_PANEL_URL ?? '',
          meta: {
            permission: Perm.QR_MENU_PANEL_LOGIN,
          },
        },
        href: import.meta.env.VITE_QR_MENU_PANEL_URL ?? '',
        label: i18n.t('titles.qr_menu_panel'),
        icon: 'message',
        target: '_blank',
      },
    ],
  },
  {
    title: i18n.t('titles.reports'),
    menu: [
      {
        to: getRouter('schedule_reports'),
        label: i18n.t('titles.schedule_reports'),
        icon: 'send-clock',
      },
      {
        to: getRouter('marketing_report'),
        label: i18n.t('titles.marketing_report'),
        icon: 'finance',
      },
      {
        to: getRouter('commission_report'),
        label: i18n.t('titles.commission_report'),
        icon: 'chart-pie',
      },
      {
        to: getRouter('soft_play_report'),
        label: i18n.t('titles.soft_play_report'),
        icon: 'chart-pie',
      },
      {
        to: getRouter('payment_transfers'),
        label: i18n.t('titles.payment_transfers'),
        icon: 'bank-transfer-out',
      },
      {
        to: getRouter('eftpos-settlement-report'),
        label: i18n.t('titles.eftpos_settlement_report'),
        icon: 'finance',
      },
    ],
  },
  {
    title: i18n.t('titles.device_ops'),
    menu: [
      {
        to: getRouter('device-ops-dashboard'),
        label: i18n.t('titles.devices_info'),
        icon: 'view-dashboard-variant',
      },
      {
        to: getRouter('device-ops-devices'),
        label: i18n.t('titles.devices'),
        icon: 'devices',
      },
    ],
  },
  {
    title: i18n.t('titles.integration'),
    menu: [
      {
        to: getRouter('tokens'),
        icon: 'key-chain',
        label: i18n.t('titles.tokens'),
      },
      {
        to: getRouter('eftpos'),
        icon: 'key-wireless',
        label: i18n.t('titles.eftpos'),
      },
    ],
  },
  {
    title: i18n.t('titles.settings'),
    menu: [
      {
        to: getRouter('privacy_logs'),
        label: i18n.t('titles.privacy_logs'),
        icon: 'shield-account',
      },
      {
        to: getRouter('sms_provider_settings'),
        label: i18n.t('titles.sms_provider'),
        icon: 'message',
      },
    ],
  },
  {
    menu: [
      {
        to: getRouter('soft_play'),
        label: i18n.t('titles.zone_management'),
        icon: 'boomerang',
      },
      {
        to: getRouter('soft_play_list'),
        label: i18n.t('titles.soft_play_list'),
        icon: 'baby-face-outline',
      },
    ],
  },
]
