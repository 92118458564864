export default {
  'panel.login': {
    main: 'Panel',
    sub_menu: 'Panele Giriş',
    name: 'Panele Giriş',
  },
  'personal.user.list': {
    main: 'Personel User List',
    sub_menu: '-',
    name: '-',
  },
  'personal.user.details': {
    main: 'Personel User Details',
    sub_menu: '-',
    name: '-',
  },
  'personal.user.log.list': {
    main: 'Personel User Log List',
    sub_menu: '-',
    name: '-',
  },
  'personal.user.document.list': {
    main: 'Personel User Doc List',
    sub_menu: '-',
    name: '-',
  },
  'personal.user.status_edit': {
    main: 'Personel User Status Edit',
    sub_menu: '-',
    name: '-',
  },
  'potential.user.list': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Potansiyel Müşteriler',
    name: 'Görüntüleme',
  },
  'potential.user.create': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Potansiyel Müşteriler',
    name: 'Oluşturma',
  },
  'user.review.list': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcı Değerlendirmeleri',
    name: 'Görüntüleme',
  },
  'invite_friend.list': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Arkadaşını Davet Et',
    name: 'Görüntüleme',
  },
  'invite_friend.details': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Arkadaşını Davet Et',
    name: 'Detaylar',
  },
  'invite_friend.settings': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Arkadaşını Davet Et',
    name: 'Ayarlar',
  },
  'company.sort': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Sırasını Değiştir',
  },
  'company.modules': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Modüller',
  },
  'company.agreement.create': {
    main: 'Company Agreement Create',
    sub_menu: '-',
    name: '-',
  },
  'pos.list': {
    main: 'Firma Tanımları',
    sub_menu: 'Sanal Poslar',
    name: 'Sayfayı Görüntüleme',
  },
  'branch.list': {
    main: 'Firma Tanımları',
    sub_menu: 'Şubeler',
    name: 'Görüntüleme',
  },
  'branch.create': {
    main: 'Firma Tanımları',
    sub_menu: 'Şubeler',
    name: 'Oluşturma',
  },
  'branch.edit': {
    main: 'Firma Tanımları',
    sub_menu: 'Şubeler',
    name: 'Düzenleme',
  },
  'branch.area_boundary.list': {
    main: 'Şube Sorumluluk Alanı',
    sub_menu: 'Şubeler',
    name: 'Görüntüleme',
  },
  'branch.area_boundary.create': {
    main: 'Şube Sorumluluk Alanı',
    sub_menu: 'Şubeler',
    name: 'Oluşturma',
  },
  'branch.area_boundary.edit': {
    main: 'Şube Sorumluluk Alanı',
    sub_menu: 'Şubeler',
    name: 'Düzenleme',
  },
  'deposit.list': {
    main: 'Yüklemeler',
    sub_menu: 'Yüklemeler',
    name: 'Görüntüleme',
  },
  'deposit.edit': {
    main: 'Yüklemeler',
    sub_menu: 'Yüklemeler',
    name: 'Düzenleme',
  },
  'deposit.details': {
    main: 'Yüklemeler',
    sub_menu: 'Yüklemeler',
    name: 'Yükleme Detayı (Görüntüle)',
  },
  'deposit.refund': {
    main: 'Yüklemeler',
    sub_menu: 'İadeler',
    name: 'Yükleme İadesi',
  },
  'deposit.details.access_private_data': {
    main: 'Yüklemeler',
    sub_menu: 'Yüklemeler',
    name: 'Yükleme Detayına Erişim',
  },
  'deposit.fail.list': {
    main: 'Yüklemeler',
    sub_menu: 'Yüklemeler',
    name: 'Başarısız Yüklemeleri Görüntüleme',
  },
  'deposit.fail.details': {
    main: 'Yüklemeler',
    sub_menu: 'Yüklemeler',
    name: 'Başarısız Yükleme Detayı',
  },
  'deposit.panel.refund': {
    main: 'Yüklemeler',
    sub_menu: 'İadeler',
    name: '',
  },
  'deposit.refund.list': {
    main: 'Yüklemeler',
    sub_menu: 'İadeler',
    name: 'Görüntüleme',
  },
  'deposit.refund.details': {
    main: 'Yüklemeler',
    sub_menu: 'İadeler',
    name: '-',
  },
  'tap_card_deposit.report': {
    main: 'Yüklemeler',
    sub_menu: '-',
    name: -'Depozito Paneli',
  },
  'predefined_deposit.list': {
    main: 'Yüklemeler',
    sub_menu: 'Ön Tanımlı Yükleme Listesi',
    name: 'Ön Tanımlı Yükleme Listesi Görüntüleme',
  },
  'payment.list': {
    main: 'Harcamalar',
    sub_menu: 'Harcamalar',
    name: 'Görüntüleme',
  },
  'payment.details': {
    main: 'Harcamalar',
    sub_menu: 'Harcamalar',
    name: 'Detaylar',
  },
  'payment.refund.request.list': {
    main: 'Payment Refund Request List',
    sub_menu: '-',
    name: '-',
  },
  'payment.refund.request.details': {
    main: 'Payment Refund Request Details',
    sub_menu: '-',
    name: '-',
  },
  'payment.refund.panel_make': {
    main: 'Payment Refund Panel Make',
    sub_menu: '-',
    name: '-',
  },
  'payment.transfer.list': {
    main: 'Raporlar',
    sub_menu: 'Harcama Transferleri',
    name: 'Görüntüleme',
  },
  'order.list': {
    main: 'Siparişler',
    sub_menu: 'Siparişler',
    name: 'Görüntüleme',
  },
  'order.details': {
    main: 'Siparişler',
    sub_menu: 'Siparişler',
    name: 'Detaylar',
  },
  'order.status_update': {
    main: 'Siparişler',
    sub_menu: 'Siparişler',
    name: 'Durum Güncelleme',
  },
  'order.cancel': {
    main: 'Siparişler',
    sub_menu: 'Siparişler',
    name: 'İptal Etme',
  },
  'order.force_cancel': {
    main: 'Siparişler',
    sub_menu: 'Siparişler',
    name: 'Zorla İptal Etme',
  },
  'tag_qr_.details': {
    main: 'Harcamalar',
    sub_menu: 'Tag QR',
    name: 'Oluşturma',
  },
  'org.list': {
    main: 'Firma Tanımları',
    sub_menu: 'Organizasyon',
    name: 'Görüntüleme',
  },
  'org.create': {
    main: 'Firma Tanımları',
    sub_menu: 'Organizasyon',
    name: 'Oluşturma',
  },
  'org.edit': {
    main: 'Firma Tanımları',
    sub_menu: 'Organizasyon',
    name: 'Düzenleme',
  },
  'user.list': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcılar',
    name: 'Görüntüleme',
  },
  'user.create': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcılar',
    name: 'Oluşturma',
  },
  'user.edit': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcılar',
    name: 'Düzenleme',
  },
  'personal.user.phone.update': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcılar',
    name: 'Telefon Güncelleme',
  },
  'personal.user.phone.update.no.otp': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcılar',
    name: 'OTP Kodu Olmadan Telefon Güncelleme',
  },
  'user.group.list': {
    main: 'User Group List',
    sub_menu: '-',
    name: '-',
  },
  'user.group.create': {
    main: 'User Group Create',
    sub_menu: '-',
    name: '-',
  },
  'user.group.edit': {
    main: 'User Group Edit',
    sub_menu: '-',
    name: '-',
  },
  'org.user.create': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Organizasyonlar',
    name: 'Kullanıcı Oluştur',
  },
  'static_qr.create': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'STATIC QR',
    name: 'Oluşturma',
  },
  'static_qr.list': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'STATIC QR',
    name: 'Görüntüleme',
  },
  'static_qr.edit': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'STATIC QR',
    name: 'Düzenleme',
  },
  'tag_qr.create': {
    main: 'Harcamalar',
    sub_menu: 'TAG QR',
    name: 'Oluşturma',
  },
  'tag_qr.list': {
    main: 'Harcamalar',
    sub_menu: 'TAG QR',
    name: 'Görüntüleme',
  },
  'tag_qr.edit': {
    main: 'Harcamalar',
    sub_menu: 'TAG QR',
    name: 'Düzenleme',
  },
  'tag_qr.edit.simple': {
    main: 'Harcamalar',
    sub_menu: 'TAG QR',
    name: 'Basit Düzenleme',
  },
  'company.category.create': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Kategorisi Oluşturma',
  },
  'company.category.list': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Kategorilerini Görüntüleme',
  },
  'company.category.edit': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Kategorilerini Düzenleme',
  },
  'company.category.order': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Kategorilerini Sıralama',
  },
  'company.create': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Oluşturma',
  },
  'company.list': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Görüntüleme',
  },
  'company.edit': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Düzenleme',
  },
  'company.settings.edit': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirket',
    name: 'Şirket Düzenleme',
  },
  'company.agreement.list': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Şirket Dökümanları Görüntüleme',
  },
  'company.agreement.edit': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirket',
    name: 'Şirket Dökümanları Düzenleme',
  },
  'corporation.list': {
    main: 'Kurum',
    sub_menu: '-',
    name: 'Liste Görüntüleme'
  },
  'corporation.create': {
    main: 'Kurum',
    sub_menu: '-',
    name: 'Oluşturma'
  },
  'corporation.update': {
    main: 'Kurum',
    sub_menu: '-',
    name: 'Düzenleme'
  },
  'amusement_event.list': {
    main: 'Etkinlik',
    sub_menu: '-',
    name: 'Liste Görüntüleme'
  },
  'amusement_event.create': {
    main: 'Etkinlik',
    sub_menu: '-',
    name: 'Oluşturma'
  },
  'amusement_event.update': {
    main: 'Etkinlik',
    sub_menu: '-',
    name: 'Düzenleme'
  },
  'amusement_event.delete': {
    main: 'Etkinlik',
    sub_menu: '-',
    name: 'Silme'
  },
  'cash.deposit.details': {
    main: 'Yüklemeler',
    sub_menu: 'Nakit Yüklemeler',
    name: 'Nakit Yüklemeler Detayı',
  },
  'cash.deposit.list': {
    main: 'Yüklemeler',
    sub_menu: 'Nakit Yüklemeler',
    name: 'Sayfayı Görüntüleme',
  },
  'cash.deposit.refund.list': {
    main: 'Yüklemeler',
    sub_menu: 'Nakit Yükleme İadeleri',
    name: 'Sayfayı Görüntüleme',
  },
  'cash.deposit.refund.details': {
    main: 'Yüklemeler',
    sub_menu: 'Nakit Yükleme İadeleri',
    name: 'İade Detayı (Görüntüle)',
  },
  'cash.deposit.refund.request': {
    main: 'Yüklemeler',
    sub_menu: '-',
    name: 'Nakit Yükleme İade Talebi',
  },
  'cash.deposit.refund': {
    main: 'Yüklemeler',
    sub_menu: 'Nakit Yükleme İadeleri',
    name: 'İade Onayla/Reddet',
  },
  'cash.balance.transfer': {
    main: 'Box Office',
    sub_menu: ' - ',
    name: 'Bakiye Aktarımı',
  },
  'pos.create': {
    main: 'Firma Tanımları',
    sub_menu: 'Sanal Poslar',
    name: 'Oluşturma',
  },
  'pos.edit': {
    main: 'Firma Tanımları',
    sub_menu: 'Sanal Poslar',
    name: 'Düzenleme',
  },
  'point.history.list': {
    main: 'Cüzdan Hareketleri',
    sub_menu: 'Puan Geçmişi',
    name: 'Sayfayı Görüntüleme',
  },
  'token.list': {
    main: 'Entegrasyon',
    sub_menu: 'Token',
    name: 'Görüntüleme',
  },
  'token.create': {
    main: 'Entegrasyon',
    sub_menu: 'Token',
    name: 'Oluşturma',
  },
  'token.edit': {
    main: 'Entegrasyon',
    sub_menu: 'Token',
    name: 'Düzenleme',
  },
  'push_notification_news.create': {
    main: 'Marketing',
    sub_menu: 'Push Notifications',
    name: 'Oluşturma',
  },
  'push_notification_news.list': {
    main: 'Marketing',
    sub_menu: 'Push Notifications',
    name: 'Görüntüleme',
  },
  'push_notification_news.update': {
    main: 'Marketing',
    sub_menu: 'Push Notifications',
    name: 'Güncelleme',
  },
  'qr.menu.create': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Oluşturma',
  },
  'qr.menu.edit': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Düzenleme',
  },
  'qr.menu.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Görüntüleme',
  },
  'blog_category.list': {
    main: 'Blog',
    sub_menu: 'Kategori Listesi',
    name: 'Görüntüleme',
  },
  'blog_category.create': {
    main: 'Blog',
    sub_menu: 'Kategori Listesi',
    name: 'Kategori Oluşturma',
  },
  'blog_category.update': {
    main: 'Blog',
    sub_menu: 'Kategori Listesi',
    name: 'Kategori Düzenleme',
  },
  'blog_category.delete': {
    main: 'Blog',
    sub_menu: 'Kategori Listesi',
    name: 'Kategori Silme',
  },
  'blog_category.order': {
    main: 'Blog',
    sub_menu: 'Kategori Listesi',
    name: 'Kategori Sıralama',
  },
  'blog_post.list': {
    main: 'Blog',
    sub_menu: 'Post Listesi',
    name: 'Görüntüleme',
  },
  'blog_post.create': {
    main: 'Blog',
    sub_menu: 'Post Listesi',
    name: 'Post Oluşturma',
  },
  'blog_post.update': {
    main: 'Blog',
    sub_menu: 'Post Listesi',
    name: 'Post Düzenleme',
  },
  'blog_post.delete': {
    main: 'Blog',
    sub_menu: 'Post Listesi',
    name: 'Post Silme',
  },
  'blog_post.order': {
    main: 'Blog',
    sub_menu: 'Post Listesi',
    name: 'Post Sıralama',
  },
  'balance_history.list': {
    main: 'Balance History List',
    sub_menu: '-',
    name: '-',
  },
  'token.revoke': {
    main: 'Entegrasyon',
    sub_menu: 'Tokenlar',
    name: 'İptal Etme',
  },
  'eftpos.list': {
    main: 'Eft Pos List',
    sub_menu: '-',
    name: '-',
  },
  'eftpos.create': {
    main: 'Eft Pos Create',
    sub_menu: '-',
    name: '-',
  },
  'eftpos.edit': {
    main: 'Eft Pos Edit',
    sub_menu: '-',
    name: '-',
  },
  'coupon.list': {
    main: 'Marketing',
    sub_menu: 'Kuponlar',
    name: 'Görüntüleme',
  },
  'coupon.create': {
    main: 'Marketing',
    sub_menu: 'Kuponlar',
    name: 'Oluşturma',
  },
  'coupon.edit': {
    main: 'Marketing',
    sub_menu: 'Kuponlar',
    name: 'Düzenleme',
  },
  'coupon.create_many_companies': {
    main: 'Marketing',
    sub_menu: 'Kuponlar',
    name: 'Birden fazla şirket için kupon oluşturma',
  },
  'user_coupon.list': {
    main: 'Marketing',
    sub_menu: 'Kullanıcı Kuponları',
    name: 'Görüntüleme',
  },
  'user_coupon.details': {
    main: 'Marketing',
    sub_menu: 'Kullanıcı Kuponları',
    name: 'Detaylar',
  },
  'coupon.exclude_reports': {
    main: 'Coupon Exclude Reports',
    sub_menu: '-',
    name: '-',
  },
  'coupon.share_percent_enter': {
    main: 'Marketing',
    sub_menu: 'Kuponlar',
    name: 'Macellan\'in Karşılayacağı Yüzdenin Gösterimi',
  },
  'coupon.share_percent_show': {
    main: 'Marketing',
    sub_menu: 'Kuponlar',
    name: 'Macellan\'in Karşılayacağı Yüzdenin Gösterimi',
  },
  'campaign.list': {
    main: 'Marketing',
    sub_menu: 'Kampanyalar',
    name: 'Oluşturma',
  },
  'campaign.create': {
    main: 'Marketing',
    sub_menu: 'Kampanyalar',
    name: '-',
  },
  'campaign.edit': {
    main: 'Marketing',
    sub_menu: 'Kampanyalar',
    name: 'Düzenleme',
  },
  'moderator.wallet.list': {
    main: 'Moderator Waller List',
    sub_menu: '-',
    name: '-',
  },
  'moderator.subscribe.wallet': {
    main: 'Moderator Subscribe Wallet',
    sub_menu: '-',
    name: '-',
  },
  'moderator.wallet.share': {
    main: 'Moderator Wallet Share',
    sub_menu: '-',
    name: '-',
  },
  'dashboard.stats': {
    main: 'Kontrol Paneli',
    sub_menu: '-',
    name: 'Kontrol Panelinin Görüntülenmesi',
  },
  'moderator.documents': {
    main: 'Moderator Documents',
    sub_menu: '-',
    name: '-',
  },
  'moderator.application.list': {
    main: 'Moderator Application List',
    sub_menu: '-',
    name: '-',
  },
  'moderator.application.review': {
    main: 'Moderator Application Review',
    sub_menu: '-',
    name: '-',
  },
  'moderator.balance.history': {
    main: 'Moderator Balance History',
    sub_menu: '-',
    name: '-',
  },
  'ticket.list': {
    main: 'Ticket List',
    sub_menu: '-',
    name: '-',
  },
  'push_notification_news.details': {
    main: 'Push Notifications News Details',
    sub_menu: '-',
    name: '-',
  },
  'push_notification_news.count': {
    main: 'Push Notifications News Count',
    sub_menu: '-',
    name: '-',
  },
  'share_percent_enter': {
    main: 'Share Percent Enter',
    sub_menu: '-',
    name: '-',
  },
  'share_percent_show': {
    main: 'Share Percent Show',
    sub_menu: '-',
    name: '-',
  },
  'campaign.news.list': {
    main: 'Marketing',
    sub_menu: 'Kampanya Duyuruları',
    name: 'Görüntüleme',
  },
  'campaign.news.create': {
    main: 'Marketing',
    sub_menu: 'Kampanya Duyuruları',
    name: 'Oluşturma',
  },
  'campaign.news.edit': {
    main: 'Marketing',
    sub_menu: 'Kampanya Duyuruları',
    name: 'Düzenleme',
  },
  'campaign.news.sort': {
    main: 'Marketing',
    sub_menu: 'Kampanya Duyuruları',
    name: 'Sıralama',
  },
  'company.news.list': {
    main: 'Marketing',
    sub_menu: 'Şirket Duyuruları',
    name: 'Görüntüleme',
  },
  'company.news.create': {
    main: 'Marketing',
    sub_menu: 'Şirket Duyuruları',
    name: 'Oluşturma',
  },
  'company.news.update': {
    main: 'Marketing',
    sub_menu: 'Şirket Duyuruları',
    name: 'Düzenleme',
  },
  'bank_transfer.list': {
    main: 'Bank Transfer List',
    sub_menu: '-',
    name: '-',
  },
  'bank_transfer.create': {
    main: 'Bank Transfer Create',
    sub_menu: '-',
    name: '-',
  },
  'bank_transfer.cancel': {
    main: 'Bank Transfer Cancel',
    sub_menu: '-',
    name: '-',
  },
  'access_user_info': {
    main: 'Access User Info',
    sub_menu: '-',
    name: '-',
  },
  'user_privacy_logs': {
    main: 'Ayarlar',
    sub_menu: 'Gizlilik Logları',
    name: 'Sayfayı Görüntüleme',
  },
  'bank_transfer.approve': {
    main: 'Bank Transfer Approve',
    sub_menu: '-',
    name: '-',
  },
  'bank_transfer.reject': {
    main: 'Bank Transfer Reject',
    sub_menu: '-',
    name: '-',
  },
  'bank_transfer.details': {
    main: 'Bank Transfer Details',
    sub_menu: '-',
    name: '-',
  },
  'schedule_report.list': {
    main: 'Raporlar',
    sub_menu: 'Zamanlanmış Raporlar',
    name: 'Görüntüleme',
  },
  'schedule_report.create': {
    main: 'Raporlar',
    sub_menu: 'Zamanlanmış Raporlar',
    name: 'Oluşturma',
  },
  'schedule_report.update': {
    main: 'Raporlar',
    sub_menu: 'Zamanlanmış Raporlar',
    name: 'Güncelleme',
  },
  'schedule_report.details': {
    main: 'Raporlar',
    sub_menu: 'Zamanlanmış Raporlar',
    name: 'Detaylar',
  },
  'schedule_report.delete': {
    main: 'Raporlar',
    sub_menu: 'Zamanlanmış Raporlar',
    name: 'Silme',
  },
  'commission.list': {
    main: 'Marketing',
    sub_menu: 'Kampanyalar',
    name: 'Komisyon Görüntüleme',
  },
  'commission.create': {
    main: 'Marketing',
    sub_menu: 'Kampanyalar',
    name: 'Komisyon Oluşturma',
  },
  'commission.edit': {
    main: 'Marketing',
    sub_menu: 'Kampanyalar',
    name: 'Komisyon Düzenleme',
  },
  'commission_report': {
    main: 'Raporlar',
    sub_menu: 'Komisyon Raporu',
    name: 'Komisyon Raporu',
  },
  'soft_play_report': {
    main: 'Raporlar',
    sub_menu: 'Soft Play Raporu',
    name: 'Soft Play Raporu',
  },
  'wallet.list': {
    main: 'Wallet List',
    sub_menu: '-',
    name: '-',
  },
  'show_user_info': {
    main: 'Show User Info',
    sub_menu: '-',
    name: '-',
  },
  'iys.log': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcı Yönetimi',
    name: 'İYS Logları',
  },
  'iys.log.details': {
    main: 'Kullanıcı Yönetimi',
    sub_menu: 'Kullanıcı Yönetimi',
    name: 'İYS Log Detayları',
  },
  'marketing_report': {
    main: 'Raporlar',
    sub_menu: 'Marketing Raporu',
    name: 'Görüntüleme',
  },
  'box_office': {
    main: 'Box Office',
    sub_menu: '-',
    name: '-',
  },
  'multiple_tap_card.create': {
    main: 'Box Office',
    sub_menu: 'Yaklaşan Etkinlikler',
    name: 'Çoklu Kart Tanımlama'
  },
  'excel_export': {
    main: 'Excel Export',
    sub_menu: '-',
    name: '-',
  },
  'company.document.list': {
    main: 'Firma Tanımları',
    sub_menu: 'Şirketler',
    name: 'Belgeler',
  },
  'is_magic_link': {
    main: 'Magic Link',
    sub_menu: '-',
    name: '-',
  },
  'disable_two_fa': {
    main: 'Magic Link',
    sub_menu: '-',
    name: '-',
  },
  'sms_provider_setting.list': {
    main: 'SMS Provider Setting',
    sub_menu: '-',
    name: '-',
  },
  'sms_provider_setting.update': {
    main: 'SMS Provider Setting',
    sub_menu: '-',
    name: '-',
  },
  'tag_qr.create_many': {
    main: 'Tag QR Create',
    sub_menu: '-',
    name: '-',
  },
  'qr_menu.category.create': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Kategori Oluşturma',
  },
  'qr_menu.category.update': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Kategori Güncelle',
  },
  'qr_menu.category.delete': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Kategori Sil',
  },
  'qr_menu.category.detail': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Kategori Detay',
  },
  'qr_menu.category.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Kategori Görüntüleme',
  },
  'qr_menu.product.create': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Oluşturma',
  },
  'qr_menu.product.update': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Güncelleme',
  },
  'qr_menu.product.delete': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Silme',
  },
  'qr_menu.product.detail': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Detay',
  },
  'qr_menu.product.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Görüntüleme',
  },
  'qr_menu.product_variation.update': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Varyasyonu Güncelleme',
  },
  'qr_menu.product_variation.create': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Varyasyonu Oluşturma',
  },
  'qr_menu.product_variation.delete': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Varyasyonu Silme',
  },
  'qr_menu.product_variation.detail': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Varyasyon Detay',
  },
  'qr_menu.product_variation.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Ürün Varyasyonu Görüntüleme',
  },
  'qr_menu.allergy.create': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Alerji Oluşturma',
  },
  'qr_menu.allergy.update': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Alerji Güncelleme',
  },
  'qr_menu.allergy.delete': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Alerji Silme',
  },
  'qr_menu.allergy.detail': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Alerji Detay',
  },
  'qr_menu.allergy.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Alerji Görüntüleme',
  },
  'qr_menu.menu.create': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Menü Oluşturma',
  },
  'qr_menu.menu.clone': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Menü Klonlama',
  },
  'qr_menu.menu.update': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Menü Güncelleme',
  },
  'qr_menu.menu.delete': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Menü Silme',
  },
  'qr_menu.menu.detail': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Menü Detay',
  },
  'qr_menu.menu.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Menü Görüntüleme',
  },
  'qr_menu.qr_code.create': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'QR Kod Oluşturma',
  },
  'qr_menu.qr_code.update': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'QR Kod Güncelleme',
  },
  'qr_menu.qr_code.delete': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'QR Kod Silme',
  },
  'qr_menu.qr_code.detail': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'QR Kod Detay',
  },
  'qr_menu.qr_code.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'QR Kod Görüntüleme',
  },
  'qr_menu.qr_code.all': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'QR Kod Tümünü Görüntüleme',
  },
  'qr_menu.company_language.add': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Şirket Dili Ekleme',
  },
  'qr_menu.company_language.all': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Tüm Şirket Dilleri',
  },
  'qr_menu.company_language.get': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Şirket Dili',
  },
  'qr_menu.select_company': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Şirket Seçme',
  },
  'qr_menu.delete_company': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Şirket Silme',
  },
  'qr_menu.company_showcase.list': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Şirket Vitrin Görüntüleme',
  },
  'qr_menu.company_showcase.add': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Şirket Vitrin Oluşturma',
  },
  'qr_menu.company_showcase.delete_all': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Şirket Vitrin Silme',
  },
  'qr_menu.activity_log': {
    main: 'Katalog',
    sub_menu: 'QR Menü',
    name: 'Aktivite Kayıtları',
  },
  'permission.list': {
    main: 'Yetki Matrisi',
    sub_menu: '-',
    name: '-',
  },
  'qr.menu.panel.login': {
    main: 'QR Menü Panel Login',
    sub_menu: '-',
    name: '-',
  },
  'pos.test': {
    main: 'Firma Tanımları',
    sub_menu: 'Sanal Poslar',
    name: 'Test',
  },
  'device-ops.panel.login': {
    main: 'Cihaz İşlemleri Login',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.dashboard.stats': {
    main: 'Cihaz İşlemleri Dashboard',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.list': {
    main: 'Cihaz İşlemleri Cihaz Listesi',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.details': {
    main: 'Cihaz İşlemleri Cihaz Detayları',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.more.details': {
    main: 'Cihaz İşlemleri Cihaz İnce Detaylar',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.create': {
    main: 'Cihaz İşlemleri Cihaz Oluşturma',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.update': {
    main: 'Cihaz İşlemleri Cihaz Güncelleme',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.set-defaults': {
    main: 'Cihaz İşlemleri Cihaza Varsayılanları Atama',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.config': {
    main: 'Cihaz İşlemleri Cihaz Config',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.device.config.update': {
    main: 'Cihaz İşlemleri Cihaz Config Güncelleme',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.config.minova': {
    main: 'Cihaz İşlemleri Config Minova',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.config.minova.reboot': {
    main: 'Cihaz İşlemleri Config Minova Reboot',
    sub_menu: '-',
    name: '-',
  },
  'device-ops.config.teltonika': {
    main: 'Cihaz İşlemleri Config Teltonika',
    sub_menu: '-',
    name: '-',
  },
  'soft_play.qr.create': {
    main: 'Harcamalar',
    sub_menu: 'Soft Play QR',
    name: 'Soft Play QR Oluşturma',
  },
  'soft_play.qr.update': {
    main: 'Harcamalar',
    sub_menu: 'Soft Play QR',
    name: 'Soft Play QR Güncelleme',
  },
  'soft_play.qr.list': {
    main: 'Harcamalar',
    sub_menu: 'Soft Play QR',
    name: 'Soft Play QR Listeleme',
  },
  'soft_play.qr.details': {
    main: 'Harcamalar',
    sub_menu: 'Soft Play QR',
    name: 'Soft Play QR Detay Görüntüleme',
  },
  'soft_play.info': {
    main: '-',
    sub_menu: 'Alan Yönetimi',
    name: 'Soft Play Kod Sorgulama',
  },
  'soft_play.take.in': {
    main: '-',
    sub_menu: 'Alan Yönetimi',
    name: 'Soft Play Alana Çocuk Alma',
  },
  'soft_play.exit': {
    main: '-',
    sub_menu: 'Alan Yönetimi',
    name: 'Soft Play Alandan Çocuk Çıkarma',
  },
  'soft_play.break': {
    main: '-',
    sub_menu: 'Alan Yönetimi',
    name: 'Soft Play Çocuk Mola Verme',
  },
  'soft_play.list': {
    main: '-',
    sub_menu: 'Alandaki Çocuklar',
    name: 'Soft Play Alandaki Çocukları Listeleme',
  },
  'company_report': {
    main: 'Şirket Raporları Paneli',
    sub_menu: '-',
    name: 'Raporları Görüntüleme'
  },

}
