import Vue from 'vue'
import store from '@/store'
import {
  first,
  isArray,
  isObject,
  isUndefined,
  last,
  map,
  split,
} from 'lodash'
import Cleave from 'cleave.js'
import router from '@/router'

Vue.mixin({
  directives: {
    cleave: {
      bind(el, binding) {
        const input = el.querySelector('input')
        input._vCleave = new Cleave(input, binding.value)
      },
      unbind(el) {
        const input = el.querySelector('input')
        input._vCleave.destroy()
      },
    },
  },
  data() {
    return {
      masks: {
        money: {
          numeral: true,
          numeralDecimalMark: ',',
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
        },
        numericOnly: {
          numeral: true,
          numericOnly: true,
          delimiter: '',
          numeralDecimalMark: '',
          numeralDecimalScale: '',
        },
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
          delimiter: ' ',
        },
        verifyCode: {
          delimiter: ' ',
          blocks: [3, 3],
        },
      },
    }
  },
  computed: {
    isCreate() {
      return this.id === null
    },
    isEdit() {
      return ! this.isCreate
    },
    appEnv() {
      return import.meta.env.VITE_APP_ENV
    },
  },
  methods: {
    showSpinner() {
      document.documentElement.classList.add('has-spinner-active')
      const $this = this
      setTimeout(function () {
        $this.hideSpinner()
      }, 10000)
    },
    hideSpinner() {
      document.documentElement.classList.remove('has-spinner-active')
    },
    checkPermission(permission) {
      if (! permission) return true
      return store.getters.authPermissions.includes(permission)
    },
    authUserRedirect() {
      let pageName = 'dashboard'

      if (this.$store.getters.isModerator) {
        pageName = 'moderator_wallets'
      }

      if (this.$store.getters.authUser.role_slug === 'box_office_agent') {
        pageName = 'box_office'
      }

      if (this.$store.getters.authUser.role_slug === 'soft_play_agent') {
        pageName = 'soft_play'
      }

      if (this.$store.getters.authUser.role_slug === 'courier') {
        pageName = 'orders'
      }

      router.push({ name: pageName })
    },
    smartRefresh() {
      document.getElementById('refresh-button')?.blur()
      document.getElementById('refresh-button-modal')?.blur()

      if (this.$refs.smartPage) {
        this.$refs.smartPage.$refs.smartTable.loadData()
      }

      if (this.$refs.smartTable) {
        this.$refs.smartTable.loadData()
      }
    },
    stringToCoordinate(string) {
      const locations = split(string, ',')

      return {
        lat: parseFloat(first(locations)),
        lng: parseFloat(last(locations)),
      }
    },
    submitForm() {
      if (! this.$refs.form) {
        return false
      }

      if (
        this.$refs.phone &&
        ! isUndefined(this.$refs.phone.$attrs.required) &&
        (! this.$refs.phone.value || this.$refs.phone.value.trim() === '+90')
      ) {
        this.$buefy.toast.open({
          message: this.$t('check_phone_number'),
          type: 'is-warning',
          queue: false,
        })

        return false
      }

      if (this.$refs.form.checkValidity()) {
        this.submit()
      } else {
        this.$refs.form.reportValidity()

        this.$buefy.toast.open({
          message: this.$t('check_form_inputs'),
          type: 'is-warning',
          queue: false,
        })
      }
    },
    hexToRGB(hex, alpha) {
      let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

      if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`
      } else {
        return `rgb(${r}, ${g}, ${b})`
      }
    },
    notify(message, type, duration) {
      this.$buefy.toast.open({
        message: message,
        type: type,
        queue: false,
        duration: duration ?? 4000,
      })
    },
    notifySuccess(message) {
      this.notify(message ?? this.$t('progress_success'), 'is-success')
    },
    notifyError(message, duration) {
      this.notify(message ?? this.$t('progress_error'), 'is-danger', duration)
    },
    notifyWarning(message) {
      this.notify(message ?? this.$t('progress_error'), 'is-warning')
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text)
        this.notifySuccess(this.$t('copied'))
      } catch ($e) {
        this.notifyWarning()
      }
    },
    trMoneyToInt(value) {
      return value && parseFloat(String(value).replace(/\./g, '').replace(/,/g, '.'))
    },
    exportExcel() {
      document.getElementById('excel-export-button').blur()
      this.$refs.smartTable.excelExport()
    },
    getCurrencyIcon(currency) {
      const currencies = this.$store.state.currencies
      const currencyItem = currencies.find((item) => item.value === currency)

      return currencyItem?.symbol
    },
    getLocale() {
      return window.localStorage.getItem('lang') ?? 'tr'
    },
    calcPercent(amount, percent) {
      return ((this.trMoneyToInt(amount) / 100) * percent).toFixed(2)
    },
    equalize(str) {
      str = str.trim().toLocaleLowerCase('tr-TR')

      const l = { ç: 'c', ı: 'i', ğ: 'g', ö: 'o', ş: 's', ü: 'u' }
      str = str.replace(/([çığöşü])/g, (i) => l[i])

      return str
    },
    buildParams(prefix, val, top) {
      if (isUndefined(top)) top = true

      if (isArray(val)) {
        return map(val, (value, key) => {
          return this.buildParams(top ? key : prefix + '[]', value, false)
        })
          .filter((v) => v)
          .join('&')
      } else if (isObject(val)) {
        return map(val, (value, key) => {
          return this.buildParams(
            top ? key : prefix + '[' + key + ']',
            value,
            false
          )
        })
          .filter((v) => v)
          .join('&')
      } else {
        return encodeURIComponent(prefix) + '=' + encodeURIComponent(val)
      }
    },
    toQuery(obj) {
      return this.buildParams('', obj)
    },
    toLittleEndian(num) {
      return parseInt(num)
        .toString(16)
        .padStart(8, '0')
        .match(/.{1,2}/g)
        .reverse()
        .join('')
        .toUpperCase()
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .replaceAll('Ü', 'u')
        .replaceAll('Ş', 's')
        .replaceAll('I', 'i')
        .replaceAll('İ', 'i')
        .replaceAll('Ö', 'o')
        .replaceAll('Ç', 'c')
        .replaceAll('ğ', 'g')
        .replaceAll('ü', 'u')
        .replaceAll('ş', 's')
        .replaceAll('ı', 'i')
        .replaceAll('ö', 'o')
        .replaceAll('ç', 'c')
    },
    download(url) {
      const link = document.createElement('a')
      link.href = url
      link.download = url.substr(url.lastIndexOf('/') + 1)
      link.click()
    },
  },
})
