<template>
  <aside class="aside is-placed-right">
    <div ref="container" class="aside-container" @mouseenter="psUpdate">
      <aside-updates @data-updated="psInit"/>
      <div class="aside-nav">
        <a class="aside-close" @click="close">
          <b-icon icon="close" custom-size="default"/>
        </a>
      </div>
      <div v-infinite-scroll="loadNotifications">
        <article 
          v-for="(notification, index) in notifications" 
          :key="index"
          class="media"
          :class="{ 'un-read-notification': ! notification.read_at }"
          @click="clickNotification(notification.data)"
        >
          <div class="media-content">
            <div class="content">
              <p>{{ notification.data.message[getLocale()] }}</p>
            </div>
            <nav class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <span class="tag is-small is-info">
                    <span class="icon is-small">
                      <i class="mdi mdi-information"/>
                    </span>
                  </span>
                </div>
                <div class="level-item">
                  <small>{{ notification.created_at | fromNow }}</small>
                </div>
              </div>
            </nav>
          </div>
        </article>
        <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false"/>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar'
import AsideUpdates from '@/components/AsideUpdates.vue'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'AsideRight',
  components: { AsideUpdates },
  directives: { infiniteScroll },
  data() {
    return {
      isLoading: false,
      notifications: [],
      hasMore: true,
      nextCursor: null,
    }
  },
  computed: {
    ...mapState([
      'isAsideRightVisible',
      'notificationCount',
    ])
  },
  watch: {
    notificationCount(count) {
      if (count === 0) return

      this.getNotifications()
    }
  },
  methods: {
    psInit() {
      this.ps = new PerfectScrollbar(this.$refs.container)
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update()
      }
    },
    close() {
      this.$store.commit('asideRightToggle', false)
    },
    loadNotifications() {
      this.getNotifications(false)
    },
    getNotifications(refresh = true) {
      if (! this.hasMore && ! refresh) return
      
      this.isLoading = true

      if (refresh) {
        this.hasMore = true
        this.nextCursor = null
      }

      const query = {
        next_cursor: this.nextCursor,
      }
      
      this.$http.get(`panel/user/notifications?${this.toQuery(query)}`)
        .then(({ data }) => {
          if (refresh) {
            this.notifications = data.data
          } else {
            this.notifications = [...this.notifications, ...data.data]
          }

          this.hasMore = data.meta.paginate.has_more
          this.nextCursor = data.meta.paginate.next_cursor
        })
        .finally(() => {
          this.isLoading = false
          this.$store.commit('setNotificationCount', 0)
        })
    },
    clickNotification(data) {
      switch (data.type) {
        case 'order_create':
        case 'order_cancel':
          window.open(this.$router.resolve({ name: 'orderDetails' , params: { orderId: data.order_id } }).href, '_blank')
          break
        
        case 'order_reminding':
          this.$router.push({ name: 'orders' })

          break
      
        default:
          break
      }

      this.close()
    }
  }
}
</script>

<style scoped>
.aside-nav{
  padding: 10px;
}

aside.aside .aside-container {
  scrollbar-color: #b5b5b5 #dbdbdb;
  scrollbar-width: thin;
}
aside.aside .aside-container, aside.aside .menu-container {
    flex: 1;
    position: relative;
    overflow-y: auto;
}
.un-read-notification{
  background-color: #eef5fc;
}

.media:hover{
  background-color: #e0ebf6;
}

aside.aside .media:first-child{
  padding-top: 1rem;
}

.media + .media {
  border-top: initial;
  margin-top: initial;
  padding-top: 1rem;
}

.media {
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  padding-bottom: 1rem;
}
</style>
