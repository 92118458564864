<template>
  <section>
    <card-component :title="title ?? $t('update_password_to_be_continued')" icon="lock">
      <form @submit.prevent="submitForm">
        <PasswordForm
          ref="passwordForm"
          has-current-password
          contains-uppercase
          contains-lowercase
          contains-number
          show-validation
          @getValues="getValues"
        />
        <hr>
        <b-field class="is-flex is-justify-content-flex-end">
          <button :disabled="! form.valid_password" class="button is-purple" :class="{'is-loading': isLoading}">
            {{ $t('change_password') }}
          </button>
        </b-field>
      </form>
    </card-component>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'
import PasswordForm from '@/components/Password/PasswordForm.vue'

export default {
  name: 'SetNewPasswordModal',
  components: { PasswordForm, CardComponent },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        current_password: null,
        password: null,
        password_confirmation: null,
        valid_password: false,
      }
    }
  },
  mounted() {
    this.hideSpinner()
  },
  methods: {
    submitForm() {
      this.isLoading = true
      this.$http.put('user/update/password', this.form)
        .then(() => {
          this.notifySuccess()
          this.$parent.close()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getValues(data) {
      return this.form = data
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  width: 420px;
}

.card::v-deep .icon {
  padding-right: 10px;
}
</style>
