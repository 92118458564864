<template>
  <section>
    <b-autocomplete
      v-model="shadow"
      :placeholder="$tc('select_branch')"
      :keep-first="false"
      :open-on-focus="true"
      :data="filtered"
      field="name"
      :clearable="true"
      :required="required"
      icon="source-branch"
      :disabled="disabled"
      :loading="loading"
      @select="select"
    >
      <template #empty>{{ $t('no_branch_found') }}</template>
    </b-autocomplete>
  </section>
</template>

<script>
export default {
  name: 'SelectBranch',
  components: {},
  props: {
    id: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      loading: false,
      shadow: '',
      branches: [],
    }
  },
  computed: {
    filtered() {
      return this.branches.filter((branch) => {
        return this.equalize(branch?.name).includes(this.equalize(this.shadow))
      })
    },
  },
  watch: {
    shadow(n) {
      if (! n) {
        return this.$emit('getBranch', null)
      }
    },
    companyId(n) {
      if (! n) {
        return
      }

      this.getBranches()
      this.shadow = ''
    },
    id(n) {
      if (n && this.companyId) {
        this.findBranchById()
      }
    }
  },
  mounted() {
    if (this.companyId) {
      this.getBranches()
    }

    if (this.id) {
      this.findBranchById()
    }
  },
  methods: {
    getBranches() {
      this.loading = true

      this.$http.get(`panel/company/branch/list-by-company/${ this.companyId }`)
        .then(({ data: { data } }) => {
          this.branches = data
        })
        .finally(() => {
          this.loading = false
        })
    },
    select(branch) {
      return this.$emit('getBranch', branch)
    },
    findBranchById() {
      this.loading = true

      this.$http.get(`panel/company/branch/${ this.id }/details`)
        .then(({ data: { data } }) => {
          this.shadow = data.name
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
}
</script>
