export default {
  active: 'Aktif',
  passive: 'Pasif',
  start: 'Başlatıldı',
  redirect: '3D Auth',
  '3d_init': '3D Auth',
  '3d_closed': '3D Closed',
  otp: 'OTP',
  success: 'Başarılı',
  fail: 'Başarısız',
  error: 'Hata',
  fraud: 'Fraud',
  complete: 'Tamamlandı',
  not_complete: 'Tamamlanmadı',
  pre_complete: 'Tamamlanıyor',
  service_error: 'Servis Hatası',
  update_required: 'Eski Sürüm',
  reject: 'Reddedildi',
  approval: 'Onayda',
  approved: 'Onaylandı',
  uploaded: 'Yüklendi',
  rejected: 'Reddedildi',
  cancelled: 'İptal Edildi',
  pending: 'Bekliyor',
  unapproved: 'Onaysız',
  processing: 'İşleniyor',
  never_signal: 'Sinyal Alınamadı',
  disconnected: 'Bağlantı Yok',
  connected: 'Bağlandı',
  removed: 'Kaldırıldı',
  create: 'Oluşturuldu',
  no_balance: 'Limit Yetersiz',
  callback_fail: 'Dönüş Başarısız',
  device_offline: 'Cihaz Çevrimdışı',
  callback_waiting: 'Dönüş Bekleniyor',
  callback_no: 'Dönüş Yapılmadı',
  timeout: 'Zaman Aşımı',
  expire: 'Süresi Doldu',
  credit_card: 'Kredi Kartı',
  eftpos: 'ÖKC',
  kerzz_pos: 'Kerzz POS',
  terminal: 'Terminal',
  box_office: 'Box Office',
  gizmo: 'Gizmo',
  static_qr: 'Static QR',
  tag_qr: 'Tag QR',
  tap_card: 'Tap Kart',
  yes: 'Evet',
  no: 'Hayır',
  bkm: 'BKM',
  masterpass: 'Masterpass',
  masterpass_dp: 'Masterpass DP',
  cash: 'Nakit',
  paycell: 'Paycell',
  turkcell_dcb: 'Turkcell DCB',
  promotion: 'Promosyon',
  open: 'Onay Bekliyor',
  close: 'Reddedildi',
  refund: 'İade Edildi',
  bank_fail: 'Banka Hatası',
  integration: 'Entegrasyon',
  manual: 'Manuel',
  excel_import: 'Excel Import',
  return_product: 'Ürün İadesi',
  objection_expense: 'Ürün Pahalı',
  amount_failure: 'Tutar Hatalı',
  ble_failed: 'BLE Cihaz Hatası',
  other: 'Diğer',
  customer_not_at_address: 'Müşteri Adreste Değil',
  no_elevator: 'Asansör Yok',
  capture: 'Bakiye Harcama',
  'top-up': 'Bakiye Yükleme',
  deposit_refund: 'Yükleme İadesi',
  cash_deposit_refund: 'Nakit Yükleme İadesi',
  payment_refund: 'Harcama İadesi',
  corporate: 'Kurumsal',
  personal: 'Bireysel',
  anonymous: 'Anonim',
  waiting: 'Bekliyor',
  signature_circulars: 'İmza Sirküsü',
  tax_plate: 'Vergi Levhası',
  identities: 'Yetkili Kimlik Fotokopisi',
  trade_registry: 'Ticaret Sicil',
  wallet_contract: 'Kurumsal Cüzdan Sözleşmesi',
  company_logo: 'Şirket Logosu',
  other_documents: 'Diğer Belgeler',
  bank_transfer: 'Yükleme Talimatı',
  deposit_gift: 'Yüklemeye Puan',
  payment_count_gift: 'Harcama Sayısına Puan',
  cross_marketing_deposit_gift: 'Çapraz Satış Yüklemeye Puan',
  coupon: 'Kupon',
  campaign: 'Kampanya',
  receivable: 'Alacak',
  load: 'Kazanma',
  usage: 'Harcama',
  revoke: 'İptal',
  daily: 'Günlük',
  weekly: 'Haftalık',
  monthly: 'Aylık',
  sended: 'Gönderildi',
  not_send: 'Gönderilemedi',
  enqueue: 'Kuyrukta',
  failure: 'Hatalı',
  all: 'Hepsi',
  excel: 'Excel',
  filter: 'Filtre',
  tr: 'TR',
  en: 'EN',
  dynamic: 'Dinamik',
  fixed: 'Sabit',
  constant_rate: 'Sabit Oran',
  not_registered: 'Kayıtsız',
  male: 'Erkek',
  female: 'Kadın',
  prefer_not_respond: 'Belirtmek İstemiyorum',
  progress_payment_amount: 'Hakediş Tutarı',
  null: '',
  registered: 'Kayıtlı',
  'Super User': 'Süper Admin',
  'Super Viewer': 'Süper Viewer',
  'Head Of Growth': 'Head Of Growth',
  'Growth Manager': 'Growth Manager',
  'Company Admin': 'Şirket Yöneticisi',
  'Customer Service': 'Müşteri Temsilcisi',
  'Branch Admin': 'Şube Yöneticisi',
  'Ecosystem': 'Ecosystem',
  'Cashier': 'Kasiyer',
  'Box Office Agent': 'Gişe Personeli',
  'Arge': 'Arge',
  'Waas Integration': 'Waas Entegrasyon',
  'Customer Success Lead': 'Customer Success Lead',
  'Soft Play Agent': 'Soft Play Gişe Personeli',
  'Audience': 'İzleyici',
  'panel-login': 'Giriş Başarılı',
  'panel-login-fail': 'Giriş Hatalı',
  contracts: 'Sözleşme',
  company_idetities: 'Kimlik Belgesi',
  company_partnership_documents: 'Şirket Ortağı Varsa Ortakların Belgeleri ve Kimlikleri',
  company_representative_documents: 'Şirketi Temsil Etmeye Yetkili Kişilerin Nüfus Cüzdanı Suretleri',
  iap: 'IAP',
  gift_card: 'Hediye Kartı',
  processed: 'İşlenmiş',
  TagQr: 'Tag QR',
  StaticQr: 'Static QR',
  TapCard: 'Tap Card',
  GiftCard: 'Hediye Kartı',
  wrong_deposit: 'Hatalı Yükleme',
  company_problem: 'İş Yerinden Kaynaklı Sorun',
  otp_sms_provider: 'OTP Sms',
  sms_provider: 'Genel Sms',
  iletimerkezi: 'İleti Merkezi',
  netgsm: 'Net Gsm',
  twilio: 'Twilio',
  request_code: 'Giriş Kodu İstendi',
  login: 'Giriş Yapıldı',
  non_three_d_pay: 'Non 3D Ödeme',
  three_d_pay: '3D Ödeme',
  settlement: 'Mutabakat',
  finish: 'Bitti',
  TR: 'Türkiye',
  AZ: 'Azerbaycan',
  user_coupon: 'Müşteri Kuponu',
  invite_friend: 'Arkadaşını Davet Et',
  partial_refund: 'Parçalı Geri Ödeme',
  User: 'Kullanıcı',
  invite_from: 'Arkadaşını Davet Et - Davet Eden',
  invite_to: 'Arkadaşını Davet Et - Davetli',
  sent: 'Gönderilmiş',
  finished: 'Bitmiş',
  cancel: 'İptal',
  expired: 'Süresi Dolmuş',
  amount_change: 'Tutar Değişikliği',
  gateway: 'Ödeme Alt Yapısı',
  waiting_entrance: 'Giriş Bekliyor',
  in_zone: 'Alanda',
  break: 'Molada',
  force_exit: 'Otomatik Çıkartıldı',
  entrance: 'Girdi',
  return_from_break: 'Moladan Döndü',
  extended: 'Süre Uzatıldı',
  exit: 'Çıktı',
  action: 'Eylem',
  debtor: 'Gnl. Mrk. Alacaklı',
  no_transaction: 'Harcama bulunamadı',
  company_insufficient_balance: 'Yetersiz Şirket Bakiyesi',
  amusement_event: 'Eğlence Parkı Etkinlik',
  soft_play: 'Soft Play',
  soft_play_report: 'Soft Play Raporu',
  SoftPlayQr: 'SoftPlay QR',
  waas: 'Waas',
  balance_burn: 'Bakiye Yakma',
  http_https: 'HTTP HTTPS',
  bluetooth: 'BLUETOOTH',
  teltonika: 'Teltonika',
  uptime_bot: 'Uptime Bot',
  minova: 'Minova',
  ciontek: 'Ciontek',
  macellan: 'Macellan',
  stock: 'Stok',
  faulty: 'Arızalı',
  phone_update_document: 'Telefon Güncelleme Belgesi',
  share: 'Paylaşım',
  manual_payment_document: 'Manuel Harcama Dökümanı',
  'Company Integration Admin': 'Şirket Entegrasyon Yetkilisi',
  address: 'Adrese Teslim',
  onsite: 'Yerinde Teslim',
  order_received: 'Sipariş Alındı',
  preparing: 'Hazırlanıyor',
  on_the_way: 'Yola Çıktı',
  at_address: 'Adreste',
  ready_at_site: 'Mağazada Hazır',
  delivered: 'Teslim Edildi',
  undelivered: 'Teslim Edilemedi',
  order: 'Sipariş',
  Order: 'Sipariş',
  Courier: 'Kurye',
  payment: 'Harcama',
  Payment: 'Harcama',

}
