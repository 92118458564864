<template>
  <div>
    <aside-updates-item v-for="item in items" :key="item.id" :status="item.status" :icon="item.icon" :ago="item.ago">
      <p>{{ item.text }}</p>
    </aside-updates-item>

    <aside-excel-item
      v-for="(excel, index) in excelExports"
      :key="`excel-${index}`"
      :excel="excel"
    />
  </div>
</template>

<script>
import AsideUpdatesItem from '@/components/AsideUpdatesItem.vue'
import AsideExcelItem from '@/components/AsideExcelItem.vue'
import { mapState } from 'vuex'

export default {
  name: 'AsideUpdates',
  components: { AsideUpdatesItem, AsideExcelItem },
  data() {
    return {
      items: []
    }
  },
  computed: {
    ...mapState([
      'excelExports',
    ]),
  },
}
</script>
