<template>
  <div>
    <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
      <div class="is-hidden-desktop is-hidden-tablet head-line-container">
        <span class="main-head-line">{{ pageTitle }}</span>
      </div>

      <div class="navbar-brand">
        <a :title="toggleTooltip" class="navbar-item is-desktop-icon-only is-hidden-touch" @click.prevent="menuToggle">
          <b-icon :icon="menuToggleIcon"/>
        </a>

        <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
          <b-icon :icon="menuToggleMobileIcon"/>
        </a>
      </div>

      <div class="navbar-brand is-right">
        <router-link
          v-if="authUser.role_slug === 'soft_play_agent' && softPlayTrackingPanelLink"
          class="navbar-item has-divider is-hidden-desktop"
          :to="softPlayTrackingPanelLink"
          target="_blank"
        >
          <b-icon class="is-marginless" icon="monitor-account" size="is-circle" outlined/>
        </router-link>

        <a
          v-if="checkPermission($perm.USER_CREATE_AUDIENCE) && checkPermission($perm.HIDE_TRANSACTION)"
          class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
          @click="isHideTransactionModalActive = true"
        >
          <b-icon class="is-marginless" icon="binoculars" size="is-circle" outlined/>
        </a>

        <a
          class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
          :class="{ 'disabled-link': ! canSelectCompany }"
          @click="openSelectCompanyModal"
        >
          <span>
            <img v-if="selectedOrOwnCompany" :src="selectedOrOwnCompany?.logo_url">
            <b-icon v-else icon="domain" size="is-circle" outlined/>
          </span>
        </a>

        <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
          <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
        </a>
      </div>

      <div class="navbar-menu fadeIn animated faster" :class="{ 'is-active':isMenuNavBarActive }">
        <div class="navbar-end">
          <router-link
            v-if="authUser.role_slug === 'soft_play_agent' && softPlayTrackingPanelLink"
            class="navbar-item has-divider is-hidden-touch"
            :to="softPlayTrackingPanelLink"
            target="_blank"
          >
            <b-icon class="is-marginless" icon="monitor-account" size="is-circle" outlined/>
          </router-link>

          <a
            v-if="checkPermission($perm.USER_CREATE_AUDIENCE) && checkPermission($perm.HIDE_TRANSACTION)"
            class="navbar-item has-divider is-hidden-touch"
            @click="isHideTransactionModalActive = true"
          >
            <b-tooltip :label="$tc('audience_role_set_amount')" type="is-dark" position="is-left">
              <b-icon class="is-marginless" icon="binoculars" size="is-circle" outlined/>
            </b-tooltip>
          </a>

          <a
            class="navbar-item has-divider is-hidden-touch"
            :class="{ 'disabled-link': ! canSelectCompany }"
            @click="openSelectCompanyModal"
          >
            <span v-if="selectedOrOwnCompany"><img :src="selectedOrOwnCompany?.logo_url"></span>
            <b-icon v-else class="is-marginless" icon="domain" size="is-circle" outlined/>
          </a>

          <nav-bar-menu class="has-divider">
            <div class="is-user-name">
              <span>{{ userFullName }}</span>
            </div>

            <div class="navbar-dropdown">
              <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
                <b-icon icon="account" custom-size="default"/>
                <span>{{ $t('titles.my_profile') }}</span>
              </router-link>

              <hr class="navbar-divider">

              <a class="navbar-item is-hidden-touch" @click="logout">
                <b-icon icon="logout" custom-size="default"/>
                <span>{{ $t('titles.logout') }}</span>
              </a>
            </div>
          </nav-bar-menu>

          <a
            class="navbar-item has-divider is-desktop-icon-only" :class="{'is-active':isAsideRightVisible}"
            title="Updates" @click.prevent="updatesToggle"
          >
            <b-icon icon="bell" custom-size="default" :class="{ 'has-update-mark': hasUpdates }"/>
            <div v-if="notificationCount" class="notification-count">{{ notificationCount }}</div>
            <span>{{ $t('notifications') }}</span>
          </a>

          <a class="navbar-item is-desktop-icon-only" :title="$tc('titles.logout')" @click="logout">
            <b-icon icon="logout" custom-size="default"/>
            <span>{{ $t('titles.logout') }}</span>
          </a>
        </div>
      </div>
    </nav>

    <b-notification
      v-if="(isLoggedIn && ! authUser.is_verify_email) && ! verifyEmailModal"
      type="is-danger"
      has-icon
      icon-size="is-small"
      :closable="false"
      class="unverified_email_notify"
    >
      <i18n tag="span" path="unverified_email_message_first">
        <template #day_count>
          <strong>{{ authUser.info.email_verify_timeout_days }}</strong>
        </template>
      </i18n>

      <i18n tag="span" path="unverified_email_message_second">
        <template #link>
          <strong><router-link to="profile">{{ $tc('titles.my_profile') }}</router-link></strong>
        </template>
      </i18n>
    </b-notification>

    <b-modal
      :active.sync="isSelectCompanyModalActive"
      has-modal-card trap-focus aria-role="dialog" aria-modal
      :can-cancel="['escape', 'x']"
    >
      <company-select/>
    </b-modal>

    <b-modal
      :active.sync="isHideTransactionModalActive"
      has-modal-card trap-focus aria-role="dialog" aria-modal
      :can-cancel="['escape', 'x']"
    >
      <hide-transaction-form/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu.vue'
import eventBus from '@/helpers/eventBus'
import CompanySelect from '@/components/CompanySelect.vue'
import HideTransactionForm from '@/components/HideTransactionForm.vue'

export default {
  name: 'NavBar',
  components: {
    HideTransactionForm,
    CompanySelect,
    NavBarMenu
  },
  data() {
    return {
      isMenuNavBarActive: false,
      pageTitle: null,
      isSelectCompanyModalActive: false,
      isHideTransactionModalActive: false,
    }
  },
  computed: {
    ...mapState([
      'isNavBarVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'isAsideRightVisible',
      'hasUpdates',
      'verifyEmailModal',
      'notificationCount',
    ]),
    ...mapGetters([
      'userFullName',
      'selectedOrOwnCompany',
      'isLoggedIn',
      'authUser',
    ]),
    menuNavBarToggleIcon() {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    menuToggleIcon() {
      return this.isAsideExpanded ? 'backburger' : 'forwardburger'
    },
    toggleTooltip() {
      return this.isAsideExpanded ? 'Collapse' : 'Expand'
    },
    softPlayTrackingPanelLink() {
      const companyId = this.authUser?.company_ids?.[0]
      const branchId = this.authUser?.branch_ids?.[0]

      if (! companyId || ! branchId) {
        return null
      }

      return {
        name: 'soft_play_dashboard',
        query: {
          company_id: companyId,
          branch_id: branchId,
        },
      }
    },
    canSelectCompany() {
      return this.authUser?.selected_company || this.authUser?.company_ids?.length !== 1
    }
  },
  mounted() {
    eventBus.$on('title-update', (e) => {
      this.pageTitle = e
    })
  },
  methods: {
    menuToggle() {
      this.$store.commit('asideStateToggle')
    },
    menuToggleMobile() {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = (! this.isMenuNavBarActive)
    },
    updatesToggle() {
      this.$store.commit('asideRightToggle')
      this.isMenuNavBarActive = (! this.isMenuNavBarActive)
    },
    logout() {
      this.$store.dispatch('revokeToken')
    },
    openSelectCompanyModal() {
      if (this.canSelectCompany) {
        this.isSelectCompanyModalActive = true
      }
    }
  }
}
</script>

<style lang="scss">
.head-line-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.unverified_email_notify {
  padding: 8px 8px 8px 16px;
  margin: 8px 8px 0 8px;
  font-size: 14px;
  line-height: 1.5;
}

.notification-count {
    position: absolute;
    top: 11px;
    right: 11px;
    background: hsl(348, 86%, 61%);
    color: white;
    border-radius: 50%;
    font-size: 8px;
    font-weight: bold;
    min-width: 18px;
    height: 18px;
    line-height: 16px;
    text-align: center;
    border: 1px solid #ffffff;
    animation: pulse-animation 1.5s infinite;
}


@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px hsl(348, 86%, 61%);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 165, 0, 0);
    }
}

.disabled-link {
  cursor: default !important;
}
</style>
