import Echo from 'laravel-echo'
import store from '@/store'
import { NotificationProgrammatic as Notification, ToastProgrammatic as Toast, SnackbarProgrammatic as Snackbar } from 'buefy'
import { i18n } from '@/helpers/i18n'
import Pusher from 'pusher-js'

export let socketEcho = null
let socketTimeout = null
let connectionAttempts = 0

export function socketConnect() {
  disconnectSocket()

  if (! store.getters.isLoggedIn || ! store.getters.authUser?.id) {
    return
  }

  connectionAttempts++

  if (connectionAttempts > 5) {
    return
  }

  console.log('connectionAttempts =>', connectionAttempts)

  const socketInfo = store.getters.socketInfo

  try {
    socketEcho = new Echo({
      Pusher: Pusher,
      broadcaster: 'pusher',
      key: socketInfo.key,
      wsHost: socketInfo.host,
      wsPort: socketInfo.port,
      wssPort: socketInfo.port,
      cluster: 'eu',
      forceTLS: false,
      encrypted: true,
      enableStats: false,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: socketInfo.auth_endpoint,
      bearerToken: store.getters.accessToken,
    })

    socketEcho.connector.pusher.connection.bind('state_change', (states) => {
      console.log('Socket connection state changed => ', states.current)

      if (states.current === 'unavailable' || states.current === 'failed') {
        socketConnect()
      }
    })

    socketEcho.connector.pusher.connection.bind('error', (err) => {
      console.log('socket error => ', err)
    })

    socketEcho.connector.pusher.connection.bind('connected', () => {
      connectionAttempts = 0
    })

    socketEcho.connector.pusher.connection.bind('disconnected', () => {
      socketEcho.leaveAllChannels()
    })

    // socketEcho.channel('update')
    //   .listen('pusher:pong', () => {
    //     console.log('Socket PONG!')
    //   })


    listenSocketEvents()
  } catch (e) {
    console.log('Socket connection error =>', e)

    socketTimeout = setTimeout(() => {
      clearTimeout(socketTimeout)
      socketConnect()
    }, 2000)
  }
}

export function status() {
  // socketEcho.connector.pusher.connection.connection.ping()
  return socketEcho.connector.pusher.connection.state === 'connected'
}

function listenSocketEvents() {
  if (store.getters.isSocketEventListening) {
    return
  }

  socketEcho.private(`users.${store.getters.authUser.id}`)
    .notification((notification) => { 
      const message = notification.message[i18n.locale]

      Snackbar.open({
        message:message,
        type: 'is-info',
        actionText: i18n.t('okay')
      })

      store.commit('setNotificationCount', store.state.notificationCount + 1)
    })
    .listen('.user.panel_logout', (data) => {
      Notification.open({
        message: data.type === 'password_change' ? i18n.t('password_change') : i18n.t('logout_event_message'),
        position: 'is-top-right',
        indefinite: true,
        type: 'is-warning',
        hasIcon: true,
        queue: false,
        class: 'logout-notification',
      })

      store.commit('logout', { toLogin: true, withMessage: false })
    })
    .listen('.excel.export', (data) => {
      window.onbeforeunload = null

      store.commit('excelExportResult', data)

      if (data.status === false) {
        return
      }

      if (! store.getters.isExcelWaiting) {
        return false
      }

      const activeExports = store.state.excelExports.find(
        excelExport => excelExport.complete === false
      )

      if (! activeExports || activeExports.length < 1) {
        store.commit('setExcelWaiting', false)
      }

      const link = document.createElement('a')
      link.href = data.file_url
      link.download = data.file_name
      link.click()
    })
    .listen('.excel.export.percent', (data) => {
      store.commit('updateExcelExportPercent', {
        request_id: data.request_id,
        percent: data.percent,
      })
    })
    .listen('.test.tag_qr_callback', (data) => {
      Toast.open({
        duration: 3000,
        message: `Tag QR: ${data.message}`,
        type: data.status ? 'is-success' : 'is-warning',
        queue: false,
        hasIcon: true,
      })
    })
    .subscribed(() => {
      console.log('Socket is connected to private channel')
    })
    .error((data) => {
      console.log('Could not connect to private channel => ', data)
    })

  store.commit('setSocketEventRegistered', true)
}

export function disconnectSocket() {
  if (socketEcho === null) {
    return
  }

  connectionAttempts = 0

  socketEcho.disconnect()
  socketEcho = null

  store.commit('setSocketEventRegistered', false)
}
