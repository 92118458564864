import store from '@/store'

export async function checkPermission(permission, next) {
  if (! permission) return true

  const permissions = store.getters.authPermissions

  if (permissions.length === 0) {
    await store.dispatch('getUserInfo')
      .then(() => {
        checkPermission(permission, next)
      })
  } else {
    if (permission instanceof Array) {
      const allPermissionsExist = permission.every(perm => permissions.includes(perm))

      if (allPermissionsExist) {
        next()
      } else {
        next('/permission-denied')
      }
    }
    else if (permissions.includes(permission)) {
      next()
    } else {
      next('/permission-denied')
    }
  }
}

export function initApp() {
  if (! window.localStorage.getItem('lang')) {
    window.localStorage.setItem('lang', 'tr')
  }
}

export function getCurrentPath() {
  let currentPath = window.location.pathname
  let currentQuery = window.location.search

  return currentPath + currentQuery
}
