import store from '@/store'
import { ToastProgrammatic as Toast } from 'buefy'
import { i18n } from '@/helpers/i18n'
import { flattenDeep, uniq, values } from 'lodash'
import JsCookie from 'js-cookie'
import dayjs from 'dayjs'
import { getCurrentPath } from '@/helpers/functions.js'
import { socketConnect } from '@/helpers/socket.js'

export async function handleRequest(requestConfig) {
  let accessToken = JsCookie.get('access_token')
  const tokenExpiresAt = dayjs.unix(JsCookie.get('token_expire_at'))

  if (
    tokenExpiresAt.isValid() &&
    JsCookie.get('refresh_token') &&
    dayjs(tokenExpiresAt).subtract(1, 'minute').isBefore(dayjs())
  ) {
    try {
      accessToken = await store.dispatch('refreshToken')
      socketConnect()
    } catch (refreshToken) {
      store.commit('setLoginReturnUrl', getCurrentPath())
      store.commit('logout', {
        toLogin: true,
        withMessage: true,
      })
    }
  }

  if (accessToken) {
    requestConfig.headers.Authorization = `Bearer ${accessToken}`
    requestConfig.headers['X-Localization'] = window.localStorage.getItem('lang') || 'tr'
  }

  return requestConfig
}

export function handleResponse(response) {
  return response
}

export function handleErrorResponse(error) {
  if (error.response) {
    const { status, data } = error.response
    let errors = parseError(error.response)
    const message = data?.message ?? i18n.t('progress_unauthorized')

    switch (status) {
      case 401:
        store.commit('setLoginReturnUrl', getCurrentPath())
        store.commit('logout', { toLogin: true })
        break
      case 405:
        Toast.open({
          message: i18n.t('not_allowed'),
          type: 'is-warning',
          duration: 5000,
        })
        break
      case 403:
        Toast.open({
          message: message,
          type: 'is-warning',
          duration: 5000,
        })
        break
      case 500:
        Toast.open({
          message: i18n.t('progress_internal_error'),
          type: 'is-danger',
          duration: 5000,
        })
        break
      case 422:
        Toast.open({
          message: `<b>${data.message}</b><br>${errors}`,
          type: 'is-warning',
          duration: 5000,
        })
        break
      case 429:
        Toast.open({
          message: data.message,
          type: 'is-warning',
          duration: 5000,
        })
        break
      case 413:
        Toast.open({
          message: i18n.t('request_entity_too_large'),
          type: 'is-warning',
          duration: 5000,
        })
        break
      default:
        break
    }

    return Promise.reject(error.response)
  } else {
    Toast.open({
      message: i18n.t('check_internet_connection'),
      type: 'is-danger',
      duration: 5000,
      queue: false,
    })

    return null
  }
}

function parseError(e) {
  if (! e.data.errors) {
    return ''
  }

  if (Object.keys(e.data.errors).length > 0) {
    return uniq(flattenDeep(values(e.data.errors))).join('<br>')
  }

  return ''
}

