<template>
  <div>
    <div v-if="hasCurrentPassword" class="mb-2">
      <b-field :label="$t('current_password')">
        <b-input v-model="currentPassword" type="password" required password-reveal/>
      </b-field>

      <b-button 
        v-if="isLoggedIn" 
        size="is-small"
        type="is-info"
        outlined
        icon-left="lock-reset"
        @click="forgetPassword"
      >
        {{ $t('forgot_password') }}
      </b-button>
    </div>

    <b-field :label="$t('password')">
      <b-input 
        v-model="password"
        autocomplete="off" 
        type="password" 
        required 
        password-reveal 
        @input="checkValidation"
      />
    </b-field>

    <b-field :label="$t('password_confirmation')">
      <b-input 
        v-model="passwordConfirmation"
        autocomplete="off" 
        type="password" 
        required 
        password-reveal
      />
    </b-field>

    <ul v-show="showValidationList">
      <li
        class="text-warning-color"
        :class="{'text-success-color': contains_length}"
      >
        <b-icon
          size="is-small"
          :icon="contains_length ? 'check-bold' : 'window-close'"
          :type="contains_length ? 'is-success' : 'is-danger'"
        />
        {{ $t('contains_length', [length]) }}
      </li>

      <li
        v-if="containsNumber"
        class="text-warning-color"
        :class="{'text-success-color': contains_number}"
      >
        <b-icon
          size="is-small"
          :icon="contains_number ? 'check-bold' : 'window-close'"
          :type="contains_number ? 'is-success' : 'is-danger'"
        />
        {{ $t('contains_number') }}
      </li>

      <li
        v-if="containsUppercase"
        class="text-warning-color"
        :class="{'text-success-color': contains_uppercase}"
      >
        <b-icon
          size="is-small"
          :icon="contains_uppercase ? 'check-bold' : 'window-close'"
          :type="contains_uppercase ? 'is-success' : 'is-danger'"
        />
        {{ $t('contains_uppercase') }}
      </li>

      <li
        v-if="containsLowercase"
        class="text-warning-color"
        :class="{'text-success-color': contains_lowercase}"
      >
        <b-icon
          size="is-small"
          :icon="contains_lowercase ? 'check-bold' : 'window-close'"
          :type="contains_lowercase ? 'is-success' : 'is-danger'"
        />
        {{ $t('contains_lowercase') }}
      </li>

      <li
        v-if="passwordConfirmation"
        class="text-warning-color"
        :class="{'text-success-color': matchPasswords}"
      >
        <b-icon
          size="is-small"
          :icon="matchPasswords ? 'check-bold' : 'window-close'"
          :type="matchPasswords ? 'is-success' : 'is-danger'"
        />
        {{ $t('confirm_doesnt_match') }}
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce, isEmpty } from 'lodash'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PasswordForm',
  props: {
    hasCurrentPassword: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 8,
    },
    containsNumber: {
      type: Boolean,
      default: false,
    },
    containsUppercase: {
      type: Boolean,
      default: false,
    },
    containsLowercase: {
      type: Boolean,
      default: false,
    },
    showValidation: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentPassword: null,
      password: null,
      passwordConfirmation: null,
      matchPasswords: false,
      valid_password: false,
      contains_length: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      showValidationList: null,
    }
  },
  computed:{
    ...mapGetters([
      'authUser',
      'isLoggedIn',
    ]),
    ...mapState([
      'isModeratorPanel',
    ]),
  },
  watch: {
    valid_password() {
      this.setValues()
    },
    currentPassword() {
      this.result()
      this.setValues()
    },
    password() {
      this.result()
    },
    passwordConfirmation() {
      this.result()
    }
  },
  mounted() {
    this.showValidationList = this.showValidation
  },
  methods: {
    checkValidation() {
      this.contains_length = this.password?.length >= this.length
      this.contains_number = /\d/.test(this.password)
      this.contains_uppercase = /[A-Z]/.test(this.password)
      this.contains_lowercase = /[a-z]/.test(this.password)
    },
    result: debounce(function () {
      if (this.password && this.passwordConfirmation) {
        this.matchPasswords = this.password === this.passwordConfirmation
        let baseCondition = this.contains_length && this.matchPasswords

        if (this.hasCurrentPassword) {
          baseCondition = baseCondition && ! isEmpty(this.currentPassword)
        }

        if (this.containsUppercase && this.containsNumber && this.containsLowercase) {
          return this.valid_password = baseCondition && this.contains_uppercase && this.contains_lowercase && this.contains_number
        }

        if (this.containsNumber && ! this.containsUppercase && ! this.containsLowercase) {
          return this.valid_password = baseCondition && this.contains_number
        }

        if (this.containsUppercase && ! this.containsNumber && ! this.containsLowercase) {
          return this.valid_password = baseCondition && this.contains_uppercase
        }

        if (this.containsLowercase && ! this.containsUppercase && ! this.containsNumber) {
          return this.valid_password = baseCondition && this.contains_lowercase
        }

        return this.valid_password = baseCondition
      }
    }, 300),
    setValues() {
      const values = {
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        current_password: this.currentPassword,
        valid_password: this.valid_password,
      }
      this.$emit('getValues', values)
    },
    clearForm() {
      this.currentPassword = null
      this.password = null
      this.passwordConfirmation = null

      this.matchPasswords = false
      this.valid_password = false
      this.contains_length = false
      this.contains_number = false
      this.contains_uppercase = false
      this.contains_lowercase = false
    },
    forgetPassword() {
      this.$buefy.dialog.confirm({
        message: this.$t('confirm_reset_password', [this.authUser.email]),
        confirmText: this.$t('confirm'),
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => {
          this.$http.post('panel/oauth/recovery', {
            email: this.authUser.email,
            is_moderator: this.isModeratorPanel,
          })
            .then(() => {
              this.notifySuccess(this.$t('send_password_reset_link'))
              this.$parent.$parent.close()
            })
            .catch((error) => {
              if (error.status === 406) {
                this.notifyWarning(error.data.message)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-warning-color {
  color: #df546a;
}

.text-success-color {
  color: #6ec592;
}
</style>
