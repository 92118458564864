<template>
  <section>
    <b-autocomplete
      ref="companyAutoComplete"
      :placeholder="$tc(placeholder)"
      :keep-first="true"
      :open-on-focus="true"
      :data="companies"
      field="name"
      :clearable="! disabled"
      :required="required"
      :disabled="disabled"
      icon="domain"
      :check-infinite-scroll="true"
      :loading="loading"
      @input="searchCompany"
      @infinite-scroll="searchCompanyOnScroll"
      @select="select"
      @typing="searchCompany"
    >
      <template #empty>{{ $t('no_company_found') }}</template>
    </b-autocomplete>
  </section>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'SelectCompany',
  props: {
    id: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'select_company',
    },
  },
  data() {
    return {
      loading: false,
      companies: [],
      page: 1,
      totalPages: null,
      searchTerm: null,
    }
  },
  watch: {
    id(oldValue, newValue) {
      if (oldValue && ! newValue) {
        this.$nextTick(() => {
          this.setCompany()
        })
      }
    },
  },
  mounted() {
    if (this.id) {
      this.setCompany()

      return
    }

    this.getCompanies()
  },
  methods: {
    getCompanies(refresh = false) {
      let query = {
        page: this.page,
      }

      if (this.searchTerm) {
        query.filter = {
          name: this.searchTerm.toLocaleLowerCase('tr-TR')
        }
      }

      this.$http.get(`panel/company/list_basic?${this.toQuery(query)}`)
        .then(({ data: { data, meta } }) => {
          if (refresh) {
            this.companies = data
          } else {
            this.companies = [...this.companies, ...data]
          }

          this.totalPages = meta?.paginate.total_pages
        })
        .finally(() => {
          this.loading = false
        })
    },
    setCompany() {
      if (! this.id) {
        return
      }

      const company = this.companies.find((item) => item.id === this.id)

      if (company) {
        this.$refs.companyAutoComplete.setSelected(company)

        return
      }

      this.loading = true

      let query = {
        filter: {
          id: this.id,
        }
      }

      this.$http.get(`panel/company/list_basic?${this.toQuery(query)}`)
        .then(({ data: { data } }) => {
          this.companies = data
          this.$refs.companyAutoComplete.setSelected(data.find((item) => item.id === this.id))
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchCompany: debounce(function (q) {
      this.loading = true
      this.page = 1
      this.searchTerm = q

      this.getCompanies(true)
    }, 250),
    searchCompanyOnScroll: debounce(function () {
      if (this.page < this.totalPages) {
        this.loading = true
        this.page++

        this.getCompanies()
      }
    }, 250),
    select(company) {
      return this.$emit('getCompany', company)
    },
  },
}
</script>
