<template>
  <div class="card two-factor-form">
    <header class="card-header">
      <p class="card-header-title">
        <span class="icon mr-3"><i class="mdi mdi-two-factor-authentication mdi-24px"/></span>
        <span>{{ $t('two_factor_form_title') }}</span>
      </p>
    </header>

    <section class="modal-card-body" :class="{'pt-0': !status && !recoveryCode}">
      <!-- Recovery Codes-->
      <div v-if="recoveryCode">
        <h2 class="subtitle is-4">{{ $t('two_factor_recovery') }}</h2>

        <b-notification class="py-3 px-4" type="is-info is-light" :closable="false">
          {{ $t('two_factor_recovery_message') }}
        </b-notification>

        <pre class="is-flex is-flex-direction-column py-3 px-4">{{ recoveryCode.join('\n') }}</pre>
      </div>

      <!-- DeActivate -->
      <div v-else-if="status">
        <b-notification type="is-warning is-light" :closable="false">
          {{ $t('two_factor_deactivate_message') }}
        </b-notification>

        <b-field>
          <b-input
            ref="input"
            v-model="code" name="code" type="text"
            autocapitalize="none"
            autocomplete="off" icon="key"
            :placeholder="$t('two_factor_input_label')" size="is-medium" autofocus
            required @keydown.native.enter="deactivate"
          />
        </b-field>
      </div>

      <!-- Enable -->
      <div v-else>
        <div class="qr-code is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
          <qr-code-generate v-if="qrCodeUrl" :value="qrCodeUrl" :with-logo="false" />

          <b-button
            v-if="secretKey"
            icon-left="content-copy" type="is-light"
            size="is-small"
            class="mb-3"
            @click="copyText(secretKey)"
          >
            {{ $t('copy') }} <code>{{ secretKey }}</code>
          </b-button>
        </div>

        <i18n class="mb-3" path="two_factor_activate_message" tag="p">
          <template #ga>
            <a href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DiOS&oco=0" target="_blank">
              Google
            </a>
          </template>

          <template #ma>
            <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app#primaryR3" target="_blank">
              Microsoft
            </a>
          </template>

          <template #ta>
            <a href="https://authy.com/download/" target="_blank">Twilio</a>
          </template>
        </i18n>
        <hr>

        <b-field>
          <b-input
            ref="input"
            v-model="code" name="code" type="text"
            autocapitalize="none" autocomplete="off"
            icon="key" :placeholder="$t('two_factor_input_label')"
            size="is-medium" autofocus
            required @keydown.native.enter="activate"
          />
        </b-field>
      </div>
    </section>

    <footer class="modal-card-foot is-justify-content-flex-end">
      <b-button v-if="recoveryCode" type="is-success" @click="$emit('close')">{{ $t('modal_close') }}</b-button>
      <b-button v-else-if="status" type="is-link" @click="deactivate">{{ $t('two_factor_deactivate') }}</b-button>
      <b-button v-else type="is-link" @click="activate">{{ $t('two_factor_activate') }}</b-button>
    </footer>
  </div>
</template>

<script>
import QrCodeGenerate from '@/components/QrCodeGenerate.vue'

export default {
  name: 'TwoFactorForm',
  components: { QrCodeGenerate },
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: null,
      qrCodeUrl: null,
      recoveryCode: null,
      secretKey: null
    }
  },
  mounted() {
    if (! this.status) {
      this.enable()
    }

    this.$refs.input.focus()
  },
  methods: {
    enable() {
      this.$http
        .post('panel/user/two-factor/enable')
        .then(({ data: { data } }) => {
          this.qrCodeUrl = data.qr_code_url
          this.secretKey = data.secret_key
        })
    },
    activate() {
      this.$http
        .post('panel/user/two-factor/activate', {
          code: this.code
        })
        .then(({ data: { data } }) => {
          this.$emit('input', true)
          this.recoveryCode = data.recovery_code
        })
    },
    deactivate() {
      this.$http
        .delete('panel/user/two-factor/activate', { data: { code: this.code } })
        .then(() => {
          this.$emit('input', false)
          this.$emit('close')
        })
    },
  }
}
</script>

<style lang="scss">
.two-factor {
  .animation-content.modal-content {
    max-width: 540px !important;
  }
}

.two-factor-form {
  .qr-code {
    height: 380px;
    padding: 10px;
    position: relative;

    img {
      position: absolute;
      top: 25px;
      max-height: 300px;
    }

    button {
      position: absolute;
      bottom: 0;
    }
  }

  .modal-card-body {
    width: auto !important;
  }
}
</style>
