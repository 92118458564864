<template>
  <img v-show="svgContent !== null" :src="svgContent" alt="QR Code">
</template>

<script>
export default {
  name: 'QrCodeGenerate',
  props: {
    value: {
      type: String,
      required: true,
    },
    withLogo: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      svgContent: null,
    }
  },
  watch: {
    withLogo() {
      this.getImage()
    },
  },
  mounted() {
    this.getImage()
  },
  methods: {
    getSvgContent() {
      return this.svgContent
    },
    getImage() {
      this.$emit('update:loading', true)

      this.$http.get(`panel/services/qr/generate?value=${this.value}&with_logo=${this.withLogo ? 1 : 0}`, {
        responseType: 'arraybuffer'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'image/svg+xml' })
          this.svgContent = URL.createObjectURL(blob)
        })
        .catch((error) => {
          if (error.status === 406) {
            this.notifyWarning(error.data.message)
          }
        })
        .finally(() => {
          this.$emit('update:loading', false)
        })
    },
  },
}
</script>
