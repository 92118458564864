<template>
  <section>
    <b-modal
      :active="suspendAccount"
      has-modal-card trap-focus aria-role="dialog" aria-modal
      :can-cancel="false"
      custom-class="modal-background-blur"
    >
      <b-notification
        type="is-danger"
        has-icon
        role="alert"
        :closable="false"
        :indefinite="true"
        class="modal-notification"
      >
        <p>{{ $t('email_unverify_suspended_user') }}</p>
        <br>
        <b-button
          type="is-danger is-light"
          :class="{ 'is-loading': isLoading }"
          :expanded="true"
          @click="sendVerifyMail(false)"
        >
          {{ $t('send_verify_email') }}
        </b-button>
      </b-notification>
    </b-modal>
    <b-modal
      :active="emailVerifyNotice"
      has-modal-card trap-focus aria-role="dialog" aria-modal
      :can-cancel="false"
      custom-class="modal-background-blur"
    >
      <b-notification
        type="is-danger"
        has-icon
        role="alert"
        :indefinite="true"
        class="modal-notification"
        @close="verifyEmailClose"
      >
        <i18n tag="p" path="email_verify_count">
          <template #day_count>
            <strong>{{ isLoggedIn && authUser.info.email_verify_timeout_days }}</strong>
          </template>
        </i18n>
        <br>
        <b-button
          type="is-danger is-light"
          :class="{ 'is-loading': isLoading }"
          :expanded="true"
          @click="sendVerifyMail(true)"
        >
          {{ $t('send_verify_email') }}
        </b-button>
      </b-notification>
    </b-modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmailVerificationNotification',
  props: {},
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'authUser',
      'isLoggedIn',
      'verifyEmailModal',
    ]),
    suspendAccount() {
      return this.isLoggedIn &&
      this.authUser.info.email_verify_timeout_days !== false &&
      this.authUser.info.email_verify_timeout_days <= 0 &&
      this.checkRoleForEmailVerify()
    },
    emailVerifyNotice() {
      return this.verifyEmailModal &&
      (this.isLoggedIn && this.authUser.info.email_verify_timeout_days > 0 &&
      this.checkRoleForEmailVerify())
    }
  },
  methods: {
    verifyEmailClose() {
      this.$store.commit('setVerifyEmailModal', false)
    },
    sendVerifyMail(setClose = false) {
      this.isLoading = true
      this.$http.get('user/resend-email-verify')
        .then(() => {
          if (setClose) {
            this.verifyEmailClose()
          }
          this.notifySuccess(this.$t('verify_send_message'))
        })
        .catch((error) => {
          if (error.status === 406) {
            this.notifyWarning(error.data.message)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    checkRoleForEmailVerify() {
      let roles = ['cashier', 'box_office_agent', 'soft_play_agent', 'courier']
      return ! roles.includes(this.authUser.role_slug)
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-notification {
  max-width: 500px;
}
</style>
