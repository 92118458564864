import axios from 'axios'
import { handleRequest, handleResponse, handleErrorResponse } from '@/helpers/http_utils.js'
import JsCookie from 'js-cookie'
import { socketConnect } from '@/helpers/socket.js'

export const deviceOpsHttp = axios.create({
  baseURL: import.meta.env.VITE_DEVICE_OPS_API_URL,
  headers: JsCookie.get('access_token'),
})

deviceOpsHttp.interceptors.request.use(async (config) => {
  const modifiedConfig = await handleRequest(config)
  return modifiedConfig
})

deviceOpsHttp.interceptors.response.use(
  (response) => {
    return handleResponse(response)
  },
  (error) => {
    socketConnect()
    return handleErrorResponse(error)
  }
)

